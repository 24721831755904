import React, { useEffect } from "react";
import "./organization.scss";
import { Helmet } from "react-helmet";

import Header from "../../components/header";
import {
  Switch,
  useRouteMatch,
  useParams,
  useHistory,
  Route,
  Redirect,
} from "react-router-dom";
import LoginPage from "../login";
import ForgotPasswordPage from "../login/forgotPassword";
import LoginErrorPage from "../login/loginError";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectOrg } from "../../models/organization/organizationSlice";
import { selectMyUser } from "../../models/users/myUserSlice";
import { PrivateRoute } from "../../components/PrivateRoute";
import { UnauthorizedRoute } from "../../components/UnauthorizedRoute";
import {
  selectApplicationStatus,
  initializeApp,
} from "../../models/applicationStatusSlice";
import { ApplicationRouter } from "../applications";
import Error404 from "../404";
import ResetPasswordPage from "../login/resetPassword";
import { AnalyticsRouter } from "../analytics";
import useGTM from "../../hooks/use-gtm";
import LogoutRedirectPage from "../login/logoutRedirect";
export interface OrgParams {
  id: string;
}

const OrganizationPage: React.FC = () => {
  let { id } = useParams<OrgParams>();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isInitialized } = useSelector(selectApplicationStatus);
  const myUser = useSelector(selectMyUser);
  const org = useSelector(selectOrg);
  const footerLogoURL = org.meta?.clientFooterLogo;
  const privacyPolicyURL = org.meta?.clientPrivacyPolicy;
  const googleTagManager = useGTM();

  useEffect(() => {
    dispatch(initializeApp(id));
  }, []);

  useEffect(() => {
    // Initialize google tag manager for the flow if the org has a GTM ID.
    const gtmId = org.meta?.gtmId;
    if (gtmId) {
      googleTagManager.initialize(gtmId);
    }
  }, [org]);

  history.listen(() => {
    (window as any).Appcues.page();
  });

  // Check that user is part of the org being displayed.
  // DISABLED JAN 3 2023 - This approach is too simple. We need an approach for clients like nuvision, that have multiple orgs accessing the portal.
  // if (myUser && org && myUser.orgId !== org.id)
  //   return <Unauthorized userId={myUser.userId} />;

  return isInitialized ? (
    <div>
      {/* Metadata for Page */}
      <Helmet>
        <title>{`${org.name} - Admin Area`}</title>
      </Helmet>
      {myUser?.invalidMobileUser && (
        <div className="container mt-5">
          <div className="alert alert-info">
            This area is not available on a mobile device.
          </div>
        </div>
      )}
      {!myUser?.invalidMobileUser && (
        <div>
          {/* Content */}
          <div style={{ minHeight: "100vh" }}>
            {/* If there is a valid organization name, render the appropriate page or 404 */}
            {org.name ? (
              <>
                {/* Header */}
                <Header bannerImg={org.imgUrl || ""} user={myUser} />
                <Switch>
                  {/* Routes that only a non-logged-in user should be able to access */}
                  <UnauthorizedRoute path={`${path}/login`}>
                    <LoginPage />
                  </UnauthorizedRoute>

                  <UnauthorizedRoute path={`${path}/forgot-password`}>
                    <ForgotPasswordPage />
                  </UnauthorizedRoute>
                  <UnauthorizedRoute path={`${path}/login-error`}>
                    <LoginErrorPage />
                  </UnauthorizedRoute>
                  {/* If logged in, render routes by appropriate router */}
                  <PrivateRoute path={`${path}/applications`}>
                    <ApplicationRouter />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/analytics`}>
                    <AnalyticsRouter />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/reset-password`}>
                    <ResetPasswordPage />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/logout`}>
                    <LogoutRedirectPage />
                  </PrivateRoute>
                  {/* Redirect to applications if organization/:id is entered by itself */}
                  <PrivateRoute exact path={`${path}`}>
                    {id ? (
                      <Redirect
                        to={{
                          pathname: `${id}/applications`,
                        }}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: `404`,
                        }}
                      />
                    )}
                  </PrivateRoute>
                  {/* This is fallback route, but should never be hit because of it being managed in App.tsx */}
                  <Route>
                    <Redirect
                      to={{
                        pathname: `404`,
                      }}
                    />
                    <Error404 />
                  </Route>
                </Switch>
              </>
            ) : (
              <Route>
                <Redirect
                  to={{
                    pathname: `404`,
                  }}
                />
                <Error404 />
              </Route>
            )}
          </div>
          {/* Footer */}
          <div className="row px-5 py-2 justify-content-between">
            <div className="d-flex" style={{ fontSize: "10px" }}>
              <div className="mr-4" style={{ color: "#a8a8a8" }}>
                © {new Date().getFullYear()} Cotribute. All rights reserved.
              </div>
              <a
                className="mr-4"
                href={"https://www.cotribute.com/company/privacy-policy"}
                target="_blank"
                style={{ color: "#a8a8a8" }}
              >
                Privacy Policy
              </a>
              <a
                className="mr-4"
                href={"https://www.cotribute.com/company/terms-of-use"}
                target="_blank"
                style={{ color: "#a8a8a8" }}
              >
                Terms of Use
              </a>
            </div>

            <div className="d-flex items-center justify-content-between align-items-center">
              <div
                style={{
                  fontSize: 9,
                  letterSpacing: 1,
                  marginTop: 2,
                  color: "#a8a8a8",
                }}
              >
                EMPOWERED BY
              </div>
              <a href="https://cotribute.com" target="_blank">
                <img
                  style={{ cursor: "pointer", height: "15px" }}
                  src="https://res.cloudinary.com/cotribute/image/upload/v1652206856/cotribute-logos-2022/Cotribute_Dark_Trimmed.png"
                  alt="Cotribute logo"
                />
              </a>
            </div>
            {/* TODO - Enable Additional Logos to be Displayed */}
            {/* {cotributeFooter?.additionalLogos &&
                  cotributeFooter?.additionalLogos.map((additionalLogo) =>
                    additionalLogo.url ? (
                      <a href={additionalLogo.url}>
                        <img
                          src={additionalLogo.src}
                          style={{
                            maxHeight:
                              cotributeFooter?.additionalLogosMaxHeight ||
                              "24px",
                          }}
                        />
                      </a>
                    ) : (
                      <img
                        src={additionalLogo.src}
                        style={{
                          maxHeight:
                            cotributeFooter?.additionalLogosMaxHeight || "24px",
                        }}
                      />
                    )
                  )} */}
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default connect()(OrganizationPage);
