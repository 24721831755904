import React, { FC } from "react";
import { Task } from "../../../models/applications/applicationInterfaces";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectMyUser } from "../../../models/users/myUserSlice";
interface Props {
  onClose: () => any;
  task: Task;
  onSubmit: (data: any) => Promise<any>;
}

export const TaskEditModal: FC<Props> = ({ onClose, task, onSubmit }) => {
  const { register, formState, handleSubmit, errors, watch } = useForm<any>({
    mode: "onChange",
    defaultValues: task.questions.reduce((r, q) => {
      r[q.prompt.replace(/\s/g, "_")] = q.response;
      return r;
    }, {} as any),
  });

  function formIsValid() {
    const values = watch();
    const requiredFields = task.questions.filter(i => i.required).map(i => i.prompt.replace(/\s/g, "_"))
    const missingFields = requiredFields.some(r => !values[r])
    return !missingFields;
  }
  
  return (
    <Modal show={true} onHide={() => onClose()}>
      <form
        onSubmit={handleSubmit((data) => {
          const result: any = {};
          Object.keys(data).forEach((k) => {
            result[k.replace(/\_/g, " ")] = data[k];
          });
          return onSubmit(result);
        })}
      >
        <Modal.Header closeButton>
          <Modal.Title>{task.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
          
          </div>
          
          {task.questions.map((item) => {
            return (
              <div className="form-group" key={item.prompt}>
                
                <label>
                  {item.prompt}{" "}
                  {item.required && <span className="text-danger">*</span>}
                </label>
                <textarea
                  className="form-control"
                  style={{ resize: "none" }}
                  cols={8}
                  name={item.prompt.replace(/\s/g, "_")}
                  ref={register({ required: item.required, maxLength: 1000 })}
                ></textarea>
                { errors[item.prompt.replace(/\s/g, "_")]?.type === "maxLength" && <span className="text-danger">Input exceeds max length of 1000 characters</span>}
              </div>
            );
          })}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <input
            type="submit"
            className="btn btn-primary"
            value="Save"
            disabled={formState.isSubmitting || !formIsValid()}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
