import React, { useEffect, useRef } from "react";

export const HtmlRenderer = ({ htmlString }: { htmlString: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Parse the HTML string and extract the title
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, "text/html");
  const title = htmlDoc.title;

  useEffect(() => {
    const iframe = iframeRef.current;
    const iframeLoadListener = () => {
      if (
        iframe &&
        iframe.contentWindow &&
        iframe.contentWindow.document.body
      ) {
        iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
      }
    };

    if (iframe) {
      iframe.addEventListener("load", iframeLoadListener);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", iframeLoadListener);
      }
    };
  }, [htmlString]);

  return (
    <iframe
      ref={iframeRef}
      srcDoc={htmlString}
      title={title}
      width="100%"
      frameBorder="0"
    />
  );
};
