import React, { FC } from "react";

interface ApplicantSelectorProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
  applicantName: string;
  coApplicantName?: string;
}

const ApplicantSelector: FC<ApplicantSelectorProps> = ({
  activeTab,
  onTabClick,
  applicantName,
  coApplicantName,
}) => (
  <div className="mb-1" style={{ borderBottom: "1px solid #e3e3e3" }}>
    {/* Primary Applicant Button */}
    <button
      className="mr-3"
      style={{
        borderBottom: activeTab === "applicant" ? "2px solid #010101" : "none",
        background: "transparent",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        outline: "none",
      }}
      onClick={() => onTabClick("applicant")}
    >
      <div className="text-center">
        <div className="h5 mb-0" style={{ color: "#010101" }}>
          {applicantName}
        </div>
        <div style={{ color: "#a2b0bc", fontSize: "10pt" }}>Applicant</div>
      </div>
    </button>{" "}
    {/* Joint Applicant Button - Only show if coApplicantName exists */}
    {coApplicantName && (
      <button
        style={{
          borderBottom:
            activeTab === "coapplicant" ? "2px solid #010101" : "none",
          background: "transparent",
          border: "none",
          outline: "none",
        }}
        onClick={() => onTabClick("coapplicant")}
      >
        <div className="h5 mb-0" style={{ color: "#010101" }}>
          {coApplicantName}
        </div>
        <div style={{ color: "#a2b0bc", fontSize: "10pt" }}>
          Joint Applicant
        </div>
      </button>
    )}
  </div>
);

export default ApplicantSelector;
