import React, { FC, useMemo, useState } from "react";
import { ExclamationOctagonFill } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { selectApplicationDetail } from "../../models/applications/applicationDetailSlice";
import {
  FinancialApplicationWatchlistOrder,
  FinancialApplicationWatchlistReport,
} from "../../models/applications/applicationInterfaces";
import { WatchlistDisplay } from "./watchlistDisplay";

export interface WatchlistScreeningProps {
  watchlistData?: FinancialApplicationWatchlistOrder[];
}

export const WATCHLIST_SEARCH_CATEGORIES = ["ofac", "pep", "negative-media"];

export const WatchlistScreening: FC<WatchlistScreeningProps> = ({
  watchlistData,
}) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(
    watchlistData?.slice(0, 1)[0]?.id
  );
  const application = useSelector(selectApplicationDetail);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );

  // Load the report for the active tab.
  const report = useMemo(() => {
    return watchlistData?.find((order) => order.id === activeTab);
  }, [watchlistData, activeTab]);

  // Filter out the search categories from the report.
  const categories = useMemo(
    () =>
      Object.keys(report || {}).filter((key) =>
        WATCHLIST_SEARCH_CATEGORIES.includes(key)
      ),
    [report]
  );

  const Header = (
    <h1
      className="d-flex text-tertiary h2 mb-4 align-items-center"
      style={{ fontWeight: 750 }}
    >
      <ExclamationOctagonFill className="mr-2" />
      Watchlist Screening Report
    </h1>
  );

  // ------------------------------------------------ RENDER ------------------------------------------------
  if (!permissions?.includes("view-application-watchlist-report")) {
    return (
      <>
        {Header}
        <p>
          You don't have permission to view this Watchlist Screening Report.
        </p>
      </>
    );
  }

  if (!watchlistData || watchlistData.length === 0) {
    return (
      <>
        {Header}
        <p>No watchlist data is available for this application.</p>
      </>
    );
  }

  return (
    <>
      {/* Header */}
      {Header}
      {/* Render the tabs */}
      {watchlistData?.map((order) => {
        return (
          <button
            className="bg-transparent mx-2"
            style={{
              borderBottom:
                activeTab === order.id ? "2px solid #010101" : "none",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
            onClick={() => setActiveTab(order.id)}
          >
            <div className="h5 mb-0" style={{ color: "#010101" }}>
              {order.subject}
            </div>
          </button>
        );
      })}
      {/* Render the watchlist data for the active tab */}
      <div
        className="p-4 mb-3"
        style={{
          background: "#f6f6f6",
          borderRadius: "8px",
        }}
      >
        {categories.map((category) => {
          if (!report) return null;
          const reportCategory = report[
            category as keyof typeof report
          ] as FinancialApplicationWatchlistReport;
          return (
            <WatchlistDisplay
              key={reportCategory.id}
              type={reportCategory.type}
              hits={reportCategory.hits}
            />
          );
        })}
      </div>
    </>
  );
};
