// ***
// * UNAUTHORIZED ROUTE - Used for login-related routes. These routes should never display for users that are actually logged in, so this
// * redirects users who try to access a login route while being logged in.
// ***

import React, { useState, useEffect } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectApplicationStatus } from "../models/applicationStatusSlice";
export const UnauthorizedRoute: React.FC<RouteProps> = ({
  children,
  ...rest
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const appStatus = useSelector(selectApplicationStatus);
  useEffect(() => {
    setIsAuthenticated(appStatus.isAuthenticated);
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        let org = "";
        const matches = location.pathname.match(/organization\/(.*)\//);
        if (matches) org = matches[1];

        // If user is still unauthenticated, render the component (page) that this is wrapped around.
        return !isAuthenticated ? (
          children
        ) : (
          // If the user is actually authenticated, redirect to applications route.
          <Redirect
            to={{
              pathname: `applications`,
            }}
          />
        );
      }}
    />
  );
};
