import React from "react";

export interface ResponseStepFieldProps {
  title: string;
  value: any;
}

const ResponseStepField = ({ title, value }: ResponseStepFieldProps) => {
  return (
    <div key={title} className="mt-2 response-step-field">
      <p className="meta text-uppercase mb-0">{title}</p>
      <p className="mb-0">{value}</p>
    </div>
  );
};

export default ResponseStepField;
