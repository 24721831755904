import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useRouteMatch } from "react-router-dom";
import { selectMyUser } from "../../models/users/myUserSlice";
import { Role } from "../../models/users/userInterfaces";
import { canAccess } from "../../utils/user";
import { selectOrg } from "../../models/organization/organizationSlice";
import { mixpanel } from "../../utils/mixpanel";
import {
  GraphUp,
  InboxFill,
  CaretRightFill,
  Gear,
} from "react-bootstrap-icons";

import "./navigation.scss";

interface NavItem {
  id: string;
  title: string;
  path?: string;
  roles: Role[];
  externalUrl?: string;
}

const Navigation: FC = () => {
  const { pathname } = useLocation();
  const myUser = useSelector(selectMyUser);
  const organization = useSelector(selectOrg);
  let { url } = useRouteMatch();

  const renderIcon = (path: string) => {
    switch (path) {
      case "settings":
        return <Gear />;
      case "analytics":
        return <GraphUp />;
      case "applications":
        return <InboxFill />;
      default:
        return <CaretRightFill />;
    }
  };

  const settingsBaseUrl =
    organization.meta?.settingsBaseUrl ||
    process.env.REACT_APP_SETTINGS_BASE_URL;

  // Store default navItems
  const navItems: NavItem[] = useMemo(() => {
    const items: NavItem[] = [];
    if (settingsBaseUrl) {
      // * If the user has an email, pass it to the settings page
      //   Encode it so that characters like "+" become "%2B"
      const emailQueryString = myUser?.email
        ? `?login_id=${encodeURIComponent(myUser.email)}`
        : "";
      items.push({
        id: "settings",
        title: "Settings",
        externalUrl: `${settingsBaseUrl}/organization/${organization.slug}/login${emailQueryString}`,
        roles: ["portalAdmin"],
      });
    }

    items.push({
      id: "applications",
      title: "Applications",
      path: "applications",
      roles: ["portalAdmin", "analyst", "portalUser"],
    });
    if (organization?.meta?.sigmaUrl) {
      items.push({
        id: "analytics",
        title: "Analytics",
        path: "analytics",
        roles: ["portalAdmin", "analyst"],
      });
    }
    return items;
  }, [organization]);

  const filteredNavItems = navItems
    .filter((item) => {
      if (!myUser?.roles) return false;
      return canAccess(myUser?.roles, item.roles);
    })
    .reverse();

  const trackClick = (nav: NavItem) => {
    mixpanel.track("Button Click", {
      "Button Title": nav.title,
      "Button Path": nav.path,
      "User Id": myUser?.userId,
      "Organization ID": organization.id,
      "Organization Name": organization.name,
      "Host Name": window.location.hostname,
      "Page URL": window.location.href,
    });
  };

  return (
    <div className="nav-header">
      {filteredNavItems.map((nav) => {
        const linkContent = (
          <div
            className={`nav-header-tab ${
              pathname.includes(nav.id) ? "active" : ""
            }`}
          >
            <div className="mr-2 d-inline">{renderIcon(nav.id)}</div>
            <span style={{ color: "#010101" }}>{nav.title}</span>
          </div>
        );

        return nav.externalUrl ? (
          <a key={nav.id} href={nav.externalUrl} rel="noopener noreferrer">
            {linkContent}
          </a>
        ) : (
          <Link to={`${url}/${nav.path}`} onClick={() => trackClick(nav)}>
            {linkContent}
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
