import React, { FC, useEffect, useState } from "react";
import {
  Address,
  BusinessEvaluationResponse,
  List,
  Name,
  Person,
  reviewTask,
} from "../../models/applications/applicationInterfaces";
import { formatDate, getFullStateName } from "../../utils/helpers";
interface MiddeskProps {
  businessGuardData?: BusinessEvaluationResponse;
}
export const MiddeskDisplay: FC<MiddeskProps> = ({ businessGuardData }) => {
  interface CollapsedState {
    [key: number]: boolean;
  }
  // Get proper status message
  type statusMessage = {
    [status: string]: string;
  };
  // Handle state for each collapsible div
  const [basicCollapsed, setBasicCollapsed] = useState(false);
  const toggleBasicCollapse = () => {
    setBasicCollapsed(!basicCollapsed);
  };
  const [insightsCollapsed, setInsightsCollapsed] = useState(true);
  const toggleInsightsCollapse = () => {
    setInsightsCollapsed(!insightsCollapsed);
  };
  const [businessNamesCollapsed, setBusinessNamesCollapsed] = useState(true);
  const toggleBusinessNamesCollapse = () => {
    setBusinessNamesCollapsed(!businessNamesCollapsed);
  };
  const [addressCollapsed, setAddressCollapsed] = useState(true);
  const toggleAddressCollapse = () => {
    setAddressCollapsed(!addressCollapsed);
  };
  const [peopleCollapsed, setPeopleCollapsed] = useState(true);
  const togglePeopleCollapse = () => {
    setPeopleCollapsed(!peopleCollapsed);
  };
  const [watchlistCollapsed, setWatchlistCollapsed] = useState(true);
  const toggleWatchlistCollapse = () => {
    setWatchlistCollapsed(!watchlistCollapsed);
  };
  const [sosCollapsed, setSosCollapsed] = useState(true);
  const toggleSosCollapse = () => {
    setSosCollapsed(!sosCollapsed);
  };
  const [stateCollapsed, setStateCollapsed] = useState<CollapsedState>({});
  useEffect(() => {
    if (businessGuardData?.registrations) {
      const initialState: CollapsedState = {};
      businessGuardData.registrations.forEach(
        (_registration: any, index: number) => {
          initialState[index] = true; // Set all divs as collapsed initially
        }
      );
      setStateCollapsed(initialState);
    }
  }, [businessGuardData?.registrations]);
  const toggleStateCollapse = (index: number) => {
    setStateCollapsed((prevState: any) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Count how many registrations per status
  let activeCount = 0;
  let inactiveCount = 0;
  let unknownCount = 0;

  businessGuardData?.registrations?.forEach(
    (registration: Record<string, any>) => {
      switch (registration.status) {
        case "active":
          activeCount++;
          break;
        case "inactive":
          inactiveCount++;
          break;
        case "unknown":
          unknownCount++;
          break;
        default:
          break;
      }
    }
  );

  function getStatusDisplay(status: string) {
    const statusMessage: statusMessage = {
      in_review: "Needs Review",
      approved: "Approved",
      rejected: "Rejected",
    };
    return statusMessage[status] || "Status Unknown";
  }
  // Function to check if the current label is the same as the previous label
  let previousLabel: string = "";
  function sameAsPrevious(currentLabel: string) {
    const sameLabel = currentLabel === previousLabel;
    previousLabel = currentLabel;

    return sameLabel;
  }

  return (
    <div
      className="p-4 mb-3"
      style={{
        background: "#f6f6f6",
        height: "calc(100% - 65px)",
        borderRadius: "8px",
      }}>
      {/* Header */}
      <h1 className="h2 mb-4" style={{ color: "#010101" }}>
        {businessGuardData?.legalBusinessName || "BusinessGuard Report"}
      </h1>

      {/* Business Status Card */}
      <div
        className="d-flex h5 justify-content-between border bg-white p-3 mb-4"
        style={{
          color: "#010101",
          borderColor: "#ededed",
          borderRadius: "8px",
        }}>
        <div className="d-flex flex-column">
          <div className="pb-1">Business Status</div>
          <div className="" style={{ fontSize: "10pt", color: "#a2b0bc" }}>
            Last Updated:{" "}
            {businessGuardData?.updatedAt
              ? formatDate(businessGuardData.updatedAt)
              : "_"}
          </div>
        </div>
        <div
          className="pr-2 pl-2 pt-1 pb-1 m-2 align-self-center"
          style={{
            fontSize: "10pt",
            backgroundColor: "#f4ccad",
            color: "#e2832c",
            borderRadius: "10% / 50%",
          }}>
          {businessGuardData?.status &&
            getStatusDisplay(businessGuardData.status)}
        </div>
      </div>

      {/* Basics Card */}
      <div>
        <div
          className={`text-white bg-tertiary p-3 ${
            basicCollapsed ? `mb-4` : `mb-0`
          }`}
          style={{
            borderRadius: basicCollapsed ? "8px" : "8px 8px 0 0",
          }}
          onClick={toggleBasicCollapse}>
          <div
            className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
            style={{}}>
            <div>Basics</div>
            <div
              style={{
                transform: basicCollapsed ? "scaleY(-1)" : "none",
              }}
              className={` ${basicCollapsed ? "collapsed" : ""}`}>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
                transform="matrix(1, 0, 0, -1, 0, 0)">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g id="Arrow / Caret_Down_MD">
                    {" "}
                    <path
                      id="Vector"
                      d="M16 10L12 14L8 10"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>

        {!basicCollapsed && (
          <div
            className="bg-white mb-4 border p-4"
            style={{
              borderColor: "#ededed",
              borderRadius: "0 0 8px 8px",
            }}>
            <div className=" pb-1">
              <b>Submitted Information</b>
            </div>
            <hr
              className="mt-0 mb-0"
              style={{ borderTop: "1px solid #e3e3e3" }}
            />
            <div className="d-flex justify-content-between pt-2 pb-2 align-items-center">
              <div className="d-flex">
                <div className="h6 pr-3 mb-0" style={{ color: "#a2b0bc" }}>
                  Business Name
                </div>
                <div className=" h6 mb-0" style={{ color: "#010101" }}>
                  {businessGuardData?.names &&
                    businessGuardData.names.filter(
                      (name: any) => name.submitted === true
                    )[0]?.name}
                </div>
              </div>
              <div
                className="pr-2 pl-2  align-self-center"
                style={{
                  fontSize: "10pt",
                  backgroundColor: "#c0e9e3",
                  color: "#00b4a1",
                  borderRadius: "15% / 50%",
                }}>
                {businessGuardData?.names &&
                businessGuardData.names.every(
                  (name: Name) => name.sources?.length === 0
                )
                  ? "Not Verified"
                  : "Verified"}
              </div>
            </div>
            <hr
              className=" mt-0 mb-0"
              style={{ borderTop: "1px solid #e3e3e3" }}
            />
            <div className="d-flex justify-content-between  pt-2 pb-2  align-items-center">
              <div className="d-flex">
                <div className="h6 pr-3 mb-0" style={{ color: "#a2b0bc" }}>
                  Office Address
                </div>
                <div className=" h6 mb-0" style={{ color: "#010101" }}>
                  {businessGuardData?.addresses &&
                    businessGuardData.addresses.filter(
                      (address: any) => address.submitted === true
                    )[0]?.fullAddress}
                </div>
              </div>
              <div
                className="pr-2 pl-2  align-self-center"
                style={{
                  fontSize: "10pt",
                  backgroundColor: "#c0e9e3",
                  color: "#00b4a1",
                  borderRadius: "15% / 50%",
                }}>
                {businessGuardData?.addresses &&
                businessGuardData.addresses.every(
                  (address: Address) => address.sources?.length === 0
                )
                  ? "Not Verified"
                  : "Verified"}
              </div>
            </div>
            <hr
              className=" mt-0 mb-0"
              style={{ borderTop: "1px solid #e3e3e3" }}
            />

            <div className="d-flex justify-content-between  pt-2 pb-4  align-items-center">
              <div className="d-flex">
                <div className="h6 pr-3 mb-0" style={{ color: "#a2b0bc" }}>
                  Tax ID
                </div>
                <div className=" h6 mb-0" style={{ color: "#010101" }}>
                  {businessGuardData?.tin || "_"}
                </div>
              </div>
              <div
                className="pr-2 pl-2  align-self-center"
                style={{
                  fontSize: "10pt",
                  backgroundColor: "#c0e9e3",
                  color: "#00b4a1",
                  borderRadius: "15% / 50%",
                }}>
                {businessGuardData?.verifiedTin ? "Found" : "Not Found"}
              </div>
            </div>

            <div className=" pt-3 pb-1">
              <b>Business Information</b>
            </div>
            <hr
              className=" mt-0 mb-0"
              style={{ borderTop: "1px solid #e3e3e3" }}
            />

            <div className="container">
              <div className="row pt-3">
                <div
                  className="col-lg "
                  style={{ fontSize: "8pt", color: "#a2b0bc" }}>
                  FORMATION STATE
                  <div className="h5" style={{ color: "#010101" }}>
                    {businessGuardData?.formationState &&
                      getFullStateName(businessGuardData.formationState)}
                  </div>
                </div>
                <div
                  className="col-lg "
                  style={{ fontSize: "8pt", color: "#a2b0bc" }}>
                  FORMATION DATE
                  <div className="h5" style={{ color: "#010101" }}>
                    {businessGuardData?.formationDate}
                  </div>
                </div>
                <div
                  className="col-lg "
                  style={{ fontSize: "8pt", color: "#a2b0bc" }}>
                  ENTITY TYPE
                  <div className="h5" style={{ color: "#010101" }}>
                    {businessGuardData?.entityType}
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg "
                  style={{ fontSize: "8pt", color: "#a2b0bc" }}>
                  TAX ID
                  <div className="h5" style={{ color: "#010101" }}>
                    {businessGuardData?.tin || "_"}
                  </div>
                </div>
                <div
                  className="col-lg "
                  style={{ fontSize: "8pt", color: "#a2b0bc" }}>
                  BUSINESS NAME
                  <div className="h5" style={{ color: "#010101" }}>
                    {businessGuardData?.legalBusinessName || "_"}
                  </div>
                </div>
                <div
                  className="col-lg "
                  style={{ fontSize: "8pt", color: "#a2b0bc" }}>
                  <div className="h5" style={{ color: "#010101" }}></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Insights Card */}
      <div>
        <div
          className={`text-white bg-tertiary p-3 ${
            insightsCollapsed ? `mb-4` : `mb-0`
          }`}
          style={{
            borderRadius: insightsCollapsed ? "8px" : "8px 8px 0 0",
          }}
          onClick={toggleInsightsCollapse}>
          <div
            className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
            style={{}}>
            <div>Insights</div>
            <div
              style={{
                transform: insightsCollapsed ? "scaleY(-1)" : "none",
              }}
              className={` ${insightsCollapsed ? "collapsed" : ""}`}>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
                transform="matrix(1, 0, 0, -1, 0, 0)">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g id="Arrow / Caret_Down_MD">
                    {" "}
                    <path
                      id="Vector"
                      d="M16 10L12 14L8 10"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
        {!insightsCollapsed && (
          <div
            className="bg-white mb-4 border p-4"
            style={{
              borderColor: "#ededed",
              borderRadius: "0 0 8px 8px",
            }}>
            {businessGuardData?.reviewTasks &&
              businessGuardData.reviewTasks.map(
                (task: reviewTask, index: number) => (
                  <div>
                    {task.category && sameAsPrevious(task.category) ? (
                      <>
                        <hr
                          className=" mt-1 mb-1 "
                          style={{ borderTop: "1px solid #e3e3e3" }}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="" style={{ color: "#010101" }}>
                            {task.message}
                          </div>
                          <div
                            className=" text-uppercase"
                            style={{
                              fontSize: "10pt",
                              color: "#a2b0bc",
                            }}>
                            {task.status}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          key={index}
                          className={`d-flex justify-content-between ${
                            index === 0 ? `pt-0` : `pt-4`
                          } align-items-center`}>
                          <div style={{ color: "#010101" }}>
                            <b>{task.label}</b>
                          </div>
                          <div
                            className="pr-2 pl-2  align-self-center"
                            style={{
                              fontSize: "10pt",
                              backgroundColor: "#c0e9e3",
                              color: "#00b4a1",
                              borderRadius: "15% / 50%",
                            }}>
                            {task.subLabel}
                          </div>
                        </div>
                        <hr
                          className=" mt-1 mb-1 "
                          style={{ borderTop: "1px solid #e3e3e3" }}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <div style={{ color: "#010101" }}>{task.message}</div>
                          <div
                            className=" text-uppercase"
                            style={{
                              fontSize: "10pt",
                              color: "#a2b0bc",
                            }}>
                            {task.status}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )
              )}
          </div>
        )}
      </div>

      {/* Business Names Card */}
      <div>
        <div
          className={`text-white bg-tertiary p-3 ${
            businessNamesCollapsed ? `mb-4` : `mb-0`
          }`}
          style={{
            borderRadius: businessNamesCollapsed ? "8px" : "8px 8px 0 0",
          }}
          onClick={toggleBusinessNamesCollapse}>
          <div
            className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
            style={{}}>
            <div>Business Names</div>
            <div
              style={{
                transform: businessNamesCollapsed ? "scaleY(-1)" : "none",
              }}
              className={` ${businessNamesCollapsed ? "collapsed" : ""}`}>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
                transform="matrix(1, 0, 0, -1, 0, 0)">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g id="Arrow / Caret_Down_MD">
                    {" "}
                    <path
                      id="Vector"
                      d="M16 10L12 14L8 10"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
        {!businessNamesCollapsed && (
          <div
            className="bg-white mb-4 border p-4"
            style={{
              borderColor: "#ededed",
              borderRadius: "0 0 8px 8px",
            }}>
            {businessGuardData?.names &&
              businessGuardData.names.map((name: Name, index: number) => (
                <div key={index}>
                  <div
                    className={`d-flex justify-content-between pt-${
                      index === 0 ? "0" : "4"
                    }`}>
                    <div className="flex-grow-1">
                      <div
                        className="h5 mb-0 pb-1"
                        style={{ color: "#010101" }}>
                        {name.name}
                      </div>
                      {name.submitted === true && (
                        <div className="">
                          <div
                            className="pl-2 pr-2"
                            style={{
                              fontSize: "10pt",
                              backgroundColor: "#c0e9e3",
                              color: "#00b4a1",
                              borderRadius: "15% / 50%",
                              display: "inline-block",
                            }}>
                            Submitted
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="align-self-center pr-5 text-center">
                      <div
                        style={{
                          fontSize: "10pt",
                          color: "#a2b0bc",
                        }}>
                        Verified with:
                      </div>
                      <div
                        style={{
                          color: "#010101",
                          fontSize: "10pt",
                        }}>
                        {name.sources?.length ?? 0} - SOS Filing
                        {name.sources && name.sources.length > 1 ? "s" : ""}
                      </div>
                    </div>
                  </div>
                  {businessGuardData.names &&
                    index !== businessGuardData.names.length - 1 && (
                      <hr
                        className="mt-4 mb-0"
                        style={{ borderTop: "1px solid #e3e3e3" }}
                      />
                    )}
                </div>
              ))}
          </div>
        )}
      </div>

      {/* Office Address Card */}
      <div>
        <div
          className={`text-white bg-tertiary p-3 ${
            addressCollapsed ? `mb-4` : `mb-0`
          }`}
          style={{
            borderRadius: addressCollapsed ? "8px" : "8px 8px 0 0",
          }}
          onClick={toggleAddressCollapse}>
          <div
            className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
            style={{}}>
            <div>Office Address</div>
            <div
              style={{
                transform: addressCollapsed ? "scaleY(-1)" : "none",
              }}
              className={` ${addressCollapsed ? "collapsed" : ""}`}>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
                transform="matrix(1, 0, 0, -1, 0, 0)">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g id="Arrow / Caret_Down_MD">
                    {" "}
                    <path
                      id="Vector"
                      d="M16 10L12 14L8 10"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
        {!addressCollapsed && (
          <div
            className="bg-white mb-4 border p-4"
            style={{
              borderColor: "#ededed",
              borderRadius: "0 0 8px 8px",
            }}>
            {businessGuardData?.addresses &&
              businessGuardData.addresses.map(
                (address: Address, index: number) => (
                  <div key={index}>
                    <div
                      className={`d-flex justify-content-between pt-${
                        index === 0 ? "0" : "4"
                      }`}>
                      <div className="flex-grow-1">
                        <div
                          className="h5 mb-0 pb-1"
                          style={{ color: "#010101" }}>
                          {address.fullAddress}
                        </div>
                        <div className="d-flex flex-wrap">
                          {address.submitted === true && (
                            <div
                              className="pl-2 pr-2 mb-2"
                              style={{
                                fontSize: "10pt",
                                backgroundColor: "#c0e9e3",
                                color: "#00b4a1",
                                borderRadius: "15% / 50%",
                                display: "inline-block",
                              }}>
                              Submitted
                            </div>
                          )}
                          {address.deliverable === true && (
                            <React.Fragment>
                              <div
                                className="pl-2 pr-2 mb-2 ml-1"
                                style={{
                                  fontSize: "10pt",
                                  backgroundColor: "#c0e9e3",
                                  color: "#00b4a1",
                                  borderRadius: "8% / 50%",
                                  display: "inline-block",
                                }}>
                                USPS - Deliverable
                              </div>
                              <div
                                className="pl-2 pr-2 mb-2 ml-1"
                                style={{
                                  fontSize: "10pt",
                                  backgroundColor: "#c0e9e3",
                                  color: "#00b4a1",
                                  borderRadius: "20% / 50%",
                                  display: "inline-block",
                                }}>
                                USPS
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className="align-self-center pr-5 text-center">
                        <div
                          style={{
                            fontSize: "10pt",
                            color: "#a2b0bc",
                          }}>
                          Verified with:
                        </div>
                        <div
                          style={{
                            color: "#010101",
                            fontSize: "10pt",
                          }}>
                          {address.sources?.length ?? 0} &middot; SOS Filing
                          {address.sources && address.sources.length > 1
                            ? "s"
                            : ""}
                        </div>
                      </div>
                    </div>
                    {businessGuardData.names &&
                      index !== businessGuardData.names.length - 1 && (
                        <hr
                          className="mt-4 mb-0"
                          style={{ borderTop: "1px solid #e3e3e3" }}
                        />
                      )}
                  </div>
                )
              )}
          </div>
        )}
      </div>

      {/* People Card */}
      {(businessGuardData?.people?.length ?? 0) > 0 && (
        <div>
          <div
            className={`text-white bg-tertiary p-3 ${
              peopleCollapsed ? `mb-4` : `mb-0`
            }`}
            style={{
              borderRadius: peopleCollapsed ? "8px" : "8px 8px 0 0",
            }}
            onClick={togglePeopleCollapse}>
            <div
              className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
              style={{}}>
              <div>People</div>
              <div
                style={{
                  transform: peopleCollapsed ? "scaleY(-1)" : "none",
                }}
                className={` ${peopleCollapsed ? "collapsed" : ""}`}>
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#ffffff"
                  transform="matrix(1, 0, 0, -1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g id="Arrow / Caret_Down_MD">
                      {" "}
                      <path
                        id="Vector"
                        d="M16 10L12 14L8 10"
                        stroke="#ffffff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </div>
            </div>
          </div>
          {!peopleCollapsed && (
            <div
              className="bg-white mb-4 border p-4"
              style={{
                borderColor: "#ededed",
                borderRadius: "0 0 8px 8px",
              }}>
              {businessGuardData?.people &&
                businessGuardData.people.map(
                  (person: Person, index: number) => (
                    <div key={index}>
                      <div
                        className={`d-flex justify-content-between pt-${
                          index === 0 ? "0" : "4"
                        }`}>
                        <div className="flex-grow-1">
                          <div
                            className="h5 mb-0 pb-1"
                            style={{ color: "#010101" }}>
                            {person.name}
                          </div>
                          {person.submitted === true && (
                            <div>
                              <div
                                className="pl-2 pr-2"
                                style={{
                                  fontSize: "10pt",
                                  backgroundColor: "#c0e9e3",
                                  color: "#00b4a1",
                                  borderRadius: "15% / 50%",
                                  display: "inline-block",
                                }}>
                                Submitted
                              </div>
                              <div
                                className="pl-2 pr-2 ml-1"
                                style={{
                                  fontSize: "10pt",
                                  backgroundColor: "#c0e9e3",
                                  color: "#00b4a1",
                                  borderRadius: "12% / 50%",
                                  display: "inline-block",
                                }}>
                                {person.sources?.length ?? 0} - SOS Filing
                                {person.sources && person.sources.length > 1
                                  ? "s"
                                  : ""}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="align-self-center pr-5 text-center">
                          <div
                            style={{
                              fontSize: "10pt",
                              color: "#a2b0bc",
                            }}>
                            Position:
                          </div>
                          <div
                            style={{
                              color: "#010101",
                              fontSize: "10pt",
                            }}>
                            {person.titles &&
                              person.titles
                                .map((personTitle: any) => personTitle.title)
                                .join(" \u00B7 ")}
                          </div>
                        </div>
                      </div>
                      {businessGuardData.names &&
                        index !== businessGuardData.names.length - 1 && (
                          <hr
                            className="mt-4 mb-0"
                            style={{ borderTop: "1px solid #e3e3e3" }}
                          />
                        )}
                    </div>
                  )
                )}
            </div>
          )}
        </div>
      )}
      {/* Watchlist Card */}

      <div>
        <div
          className={`text-white bg-tertiary p-3 ${
            watchlistCollapsed ? `mb-4` : `mb-0`
          }`}
          style={{
            borderRadius: watchlistCollapsed ? "8px" : "8px 8px 0 0",
          }}
          onClick={toggleWatchlistCollapse}>
          <div
            className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
            style={{}}>
            <div>Watchlists</div>
            <div
              style={{
                transform: watchlistCollapsed ? "scaleY(-1)" : "none",
              }}
              className={` ${watchlistCollapsed ? "collapsed" : ""}`}>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
                transform="matrix(1, 0, 0, -1, 0, 0)">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g id="Arrow / Caret_Down_MD">
                    {" "}
                    <path
                      id="Vector"
                      d="M16 10L12 14L8 10"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
        {!watchlistCollapsed && (
          <div
            className="bg-white mb-4 border p-4"
            style={{
              borderColor: "#ededed",
              borderRadius: "0 0 8px 8px",
            }}>
            <div className="h4 " style={{ color: "#010101" }}>
              14 Watchlists Searched -{" "}
              {businessGuardData?.watchlistHitCount === 0
                ? "No Hits Found"
                : "Hits Found"}
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg pl-0">
                  <div
                    className="  font-weight-bold"
                    style={{ fontSize: "14pt", color: "#010101" }}>
                    Office of Foreign Assets Control (OFAC)
                  </div>
                  <hr
                    className="mt-1 mb-1"
                    style={{ borderTop: "1px solid #e3e3e3" }}
                  />

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Specially Designated Nationals (SDN)
                    </div>
                    <div
                      className="text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData?.watchlistLists.filter(
                        (list: List) =>
                          list.title === "Specially Designated Nationals"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Foreign Sanctions Evaders
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData?.watchlistLists.filter(
                        (list: List) =>
                          list.title === "Foreign Sanctions Evaders"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Palestinian Legislative Council
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) =>
                          list.title === "Palestinian Legislative Council"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Sectoral Sanctions Identifications
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) =>
                          list.title === "Sectoral Sanctions Identifications"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Capta List
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) => list.title === "Capta List"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Non-SDN Menu-Based Sanctions
                    </div>
                    <div
                      className="text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) =>
                          list.title === "Non-SDN Menu-Based Sanctions"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-1">
                    <div className="" style={{ color: "#010101" }}>
                      Non-SDN Iranian Sanctions
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) =>
                          list.title === "Non-SDN Iranian Sanctions"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className=""
                      style={{
                        color: "#010101",
                        width: "75%",
                        lineHeight: "1",
                      }}>
                      Non-SDN Chinese Military-Industrial Complex Companies List
                    </div>
                    <div
                      className="text-uppercase"
                      style={{
                        fontSize: "10pt",
                        color: "#a2b0bc",
                      }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) =>
                          list.title ===
                          "Non-SDN Chinese Military-Industrial Complex Companies List"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                  <div
                    className=" font-weight-bold mt-3"
                    style={{ fontSize: "14pt", color: "#010101" }}>
                    Directorate of Defense Trade Controls
                  </div>
                  <hr
                    className="mt-1 mb-1"
                    style={{ borderTop: "1px solid #e3e3e3" }}
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      AECA/ITAR Debarred
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) => list.title === "AECA/ITAR Debarred"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                </div>

                <div className="col-lg">
                  <div
                    className="  font-weight-bold"
                    style={{ fontSize: "14pt", color: "#010101" }}>
                    Bureau of Industry and Security
                  </div>
                  <hr
                    className="mt-1 mb-1"
                    style={{ borderTop: "1px solid #e3e3e3" }}
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Denied Persons List
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) => list.title === "Denied Persons List"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Entity List
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) => list.title === "Entity List"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Unverified List
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) => list.title === "Unverified List"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Military End User
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) => list.title === "Military End User"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>

                  <div
                    className="font-weight-bold pt-4"
                    style={{ fontSize: "14pt", color: "#010101" }}>
                    Bureau of International Security and NonProliferation
                  </div>
                  <hr
                    className="mt-1 mb-1"
                    style={{ borderTop: "1px solid #e3e3e3" }}
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="" style={{ color: "#010101" }}>
                      Nonproliferation Sanctions
                    </div>
                    <div
                      className=" text-uppercase"
                      style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                      {businessGuardData?.watchlistLists &&
                      businessGuardData.watchlistLists.filter(
                        (list: List) =>
                          list.title === "Nonproliferation Sanctions"
                      )[0].results?.length === 0
                        ? "no hits"
                        : "hits found"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" h4 pt-3" style={{ color: "#010101" }}>
              Names Searched
            </div>
            {businessGuardData?.names &&
              businessGuardData.names.map((name: Name, index: number) => (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div
                      className=""
                      style={{ fontSize: "14pt", color: "#010101" }}>
                      {name.name}
                    </div>
                    <div
                      className="pl-2 pr-2"
                      style={{
                        fontSize: "10pt",
                        backgroundColor: "#c0e9e3",
                        color: "#00b4a1",
                        borderRadius: "10% / 50%",
                        display: "inline-block",
                      }}>
                      {name.sources?.filter(
                        (source: any) => source.type === "watchlist_result"
                      )[0]
                        ? "Hits Found"
                        : "No Hits Found"}
                    </div>
                  </div>
                  {businessGuardData.names &&
                    index !== businessGuardData.names.length - 1 && (
                      <hr
                        key={index}
                        className="mt-1 mb-1"
                        style={{ borderTop: "1px solid #e3e3e3" }}
                      />
                    )}
                </>
              ))}
          </div>
        )}
      </div>

      {/* Secratary of State Filings Watchlist Card */}

      <div>
        <div
          className={`text-white bg-tertiary p-3 ${
            sosCollapsed ? `mb-4` : `mb-0`
          }`}
          style={{
            borderRadius: sosCollapsed ? "8px" : "8px 8px 0 0",
          }}
          onClick={toggleSosCollapse}>
          <div
            className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
            style={{}}>
            <div>Secretary of State Filings</div>
            <div
              style={{
                transform: sosCollapsed ? "scaleY(-1)" : "none",
              }}
              className={` ${sosCollapsed ? "collapsed" : ""}`}>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
                transform="matrix(1, 0, 0, -1, 0, 0)">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g id="Arrow / Caret_Down_MD">
                    {" "}
                    <path
                      id="Vector"
                      d="M16 10L12 14L8 10"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
        {!sosCollapsed && (
          <div
            className="bg-white mb-4 border p-4 text-center"
            style={{
              borderColor: "#ededed",
              borderRadius: "0 0 8px 8px",
            }}>
            <div style={{ fontSize: "12pt", color: "#010101" }}>
              Found in 52 Secretary of State Searches
            </div>
            <div className="d-flex justify-content-around mt-4">
              <div>
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    backgroundColor: "#c0e9e3",
                    color: "#00b4a1",
                    fontSize: "16pt",
                  }}
                  className="d-flex rounded-circle justify-content-center align-items-center mx-auto">
                  {activeCount}
                </div>
                <div
                  style={{
                    color: "#010101",
                    fontSize: "16pt",
                    marginTop: "10px",
                  }}>
                  Active
                </div>
              </div>
              <div>
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    backgroundColor: "#fdd5d6",
                    color: "#eb2835",
                    fontSize: "16pt",
                  }}
                  className="d-flex rounded-circle justify-content-center align-items-center mx-auto">
                  {inactiveCount}
                </div>
                <div
                  style={{
                    color: "#010101",
                    fontSize: "16pt",
                    marginTop: "10px",
                  }}>
                  Inactive
                </div>
              </div>
              <div>
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    backgroundColor: "#f6f6f6",
                    color: "#949da5",
                    fontSize: "16pt",
                  }}
                  className="d-flex rounded-circle justify-content-center align-items-center mx-auto">
                  {unknownCount}
                </div>
                <div
                  style={{
                    color: "#010101",
                    fontSize: "16pt",
                    marginTop: "10px",
                  }}>
                  Unknown
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              {businessGuardData?.registrations &&
                businessGuardData.registrations.map(
                  (registration: Record<string, any>, index: number) => (
                    <div
                      key={index}
                      className="mr-2 pl-2 pr-2"
                      style={{
                        fontSize: "10pt",
                        backgroundColor: "#eceff1",
                        color: "#949da5",
                        borderRadius: "10% / 50%",
                        display: "inline-block",
                      }}>
                      {getFullStateName(registration.state)} -{" "}
                      {registration.status}
                    </div>
                  )
                )}
            </div>
          </div>
        )}
      </div>

      {/* Secretary of State Filings by State Cards */}

      {businessGuardData?.registrations &&
        businessGuardData.registrations.map(
          (registration: Record<string, any>, index: number) => (
            <div>
              <div
                className={`text-white bg-tertiary p-3 ${
                  stateCollapsed[index] ? `mb-4` : `mb-0`
                }`}
                style={{
                  borderRadius: stateCollapsed[index] ? "8px" : "8px 8px 0 0",
                }}
                onClick={() => toggleStateCollapse(index)}>
                <div
                  className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
                  style={{}}>
                  <div>{getFullStateName(registration.state)}</div>
                  <div
                    style={{
                      transform: stateCollapsed[index] ? "scaleY(-1)" : "none",
                    }}
                    className={` ${stateCollapsed[index] ? "collapsed" : ""}`}>
                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff"
                      transform="matrix(1, 0, 0, -1, 0, 0)">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g id="Arrow / Caret_Down_MD">
                          {" "}
                          <path
                            id="Vector"
                            d="M16 10L12 14L8 10"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              {!stateCollapsed[index] && (
                <div
                  className="bg-white mb-4 border p-4"
                  style={{
                    borderColor: "#ededed",
                    borderRadius: "0 0 8px 8px",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between;",
                    }}>
                    <div className="flex-grow-1">
                      <div
                        className="text-uppercase"
                        style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                        Business Name
                      </div>
                      <div className="h4 mb-0" style={{ color: "#010101" }}>
                        {registration.name}
                      </div>
                      <div style={{ fontSize: "10pt" }}>
                        Filing ID: {registration.fileNumber}
                      </div>
                    </div>

                    <div className="flex-grow-1 d-flex align-items-end justify-content-end">
                      <div className="d-flex ">
                        <div className="d-flex flex-column align-items-start pr-2">
                          <div
                            className="text-uppercase  "
                            style={{
                              fontSize: "10pt",
                              color: "#a2b0bc",
                            }}>
                            jurisdiction
                          </div>
                          <div
                            className="pl-2 pr-2 mt-1"
                            style={{
                              backgroundColor: "#c0e9e3",
                              color: "#00b4a1",
                              borderRadius: "10% / 50%",
                              fontSize: "10pt",
                            }}>
                            {registration.jurisdiction}
                          </div>
                        </div>

                        <div className="d-flex flex-column align-items-start  pr-2">
                          <div
                            className="text-uppercase "
                            style={{
                              fontSize: "10pt",
                              color: "#a2b0bc",
                            }}>
                            status
                          </div>
                          <div
                            className="pl-2 pr-2 mt-1"
                            style={{
                              backgroundColor: "#c0e9e3",
                              color: "#00b4a1",
                              borderRadius: "20% / 50%",
                              fontSize: "10pt",
                            }}>
                            {registration.status}
                          </div>
                        </div>

                        <div className="d-flex flex-column align-items-start">
                          <div
                            className="text-uppercase "
                            style={{
                              fontSize: "10pt",
                              color: "#a2b0bc",
                            }}>
                            sub status
                          </div>
                          <div
                            className="pl-2 pr-2 mt-1"
                            style={{
                              backgroundColor: "#c0e9e3",
                              color: "#00b4a1",
                              borderRadius: "10% / 50%",
                              fontSize: "10pt",
                            }}>
                            {registration.subStatus || "_"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr
                    className="mt-3 mb-3"
                    style={{ borderTop: "1px solid #e3e3e3" }}
                  />

                  {registration.addresses.map((address: string) => (
                    <div>
                      <div
                        className="text-uppercase  pl-3"
                        style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                        Address
                      </div>
                      <div className=" h4 pl-3" style={{ color: "#010101" }}>
                        {address}
                      </div>
                    </div>
                  ))}
                  {registration.officers[0] && (
                    <div>
                      <div
                        className="text-uppercase pl-3"
                        style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                        Officers
                      </div>
                      {registration.officers.map(
                        (officer: Record<string, any>, index: number) => (
                          <div key={index}>
                            <div
                              className="  pl-3"
                              style={{
                                fontSize: "14pt",
                                color: "#010101",
                              }}>
                              {officer.name}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}

                  <div className="d-flex">
                    <div className="d-flex flex-column">
                      <div
                        className="text-uppercase  pl-3"
                        style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                        Filed Date
                      </div>
                      <div className="  h4 pl-3" style={{ color: "#010101" }}>
                        {registration.registrationDate}
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <div
                        className="text-uppercase  pl-3"
                        style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                        Entity Type
                      </div>
                      <div className=" h4 pl-3" style={{ color: "#010101" }}>
                        {registration.entityType}
                      </div>
                    </div>
                  </div>
                  <a
                    href={registration.source}
                    target="_blank"
                    className=" pl-3"
                    style={{ fontSize: "10pt", color: "#a2b0bc" }}>
                    {registration.source}
                  </a>
                </div>
              )}
            </div>
          )
        )}
    </div>
  );
};

export default MiddeskDisplay;
