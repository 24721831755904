import React from "react";
import { SortOptions } from "../../models/applications/applicationInterfaces";
import { getPageArray } from "../../utils/pagintation";
import { Pagination } from "react-bootstrap";
import "./pagination.scss";

export const limit = 10;

interface Props {
  sortOptions: SortOptions;
  totalRecords?: number;
  loading?: boolean;
  hideTotalRecords?: boolean;
  fetch: (sortOptions: SortOptions) => any;
}

const PaginationComponent: React.FC<Props> = ({
  totalRecords = 0,
  sortOptions,
  loading,
  hideTotalRecords,
  fetch,
}) => {
  const getPageLimit = () => {
    if (totalRecords / 10 < 6) {
      return Math.ceil(totalRecords / 10);
    }
    return 6;
  };

  const pageLimit = getPageLimit();
  const initialMiddle = Math.floor(pageLimit / 2);
  const showBefore = Math.ceil(pageLimit / 2);
  const showAfter = Math.floor(pageLimit / 2);

  const offset = sortOptions.offset || 0;
  const fromRecord = sortOptions.offset || (totalRecords === 0 ? 0 : 1);
  const toRecord =
    offset + limit > totalRecords ? totalRecords : offset + limit;

  const currentPage = (offset || 0) / limit + 1;
  const totalPages = Math.ceil(totalRecords / limit);

  const canNavigateBack = currentPage > 1;
  const canNavigateForward = currentPage < totalPages;

  const getPreviousPage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    fetch({
      offset: offset - limit,
    });
  };

  const getNextPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    fetch({
      offset: offset + limit,
    });
  };

  const getPageAtOffset = (offset: number) => {
    fetch({
      offset,
    });
  };

  const getMiddle = (currentPage: number) => {
    const maxMiddle = totalPages - initialMiddle;

    if (currentPage < initialMiddle) return initialMiddle;
    if (currentPage > maxMiddle) return maxMiddle;

    return currentPage;
  };

  const middle = getMiddle(currentPage);

  const pageArr = getPageArray(
    currentPage,
    totalPages,
    middle,
    showBefore,
    showAfter,
    pageLimit
  );

  return (
    <>
      {!hideTotalRecords && (
        <p>{`Showing ${fromRecord} to ${toRecord} of ${totalRecords} applications`}</p>
      )}
      <nav aria-label="Table page navigation">
        <Pagination>
          <Pagination.First
            onClick={() => getPageAtOffset(0)}
            disabled={!canNavigateBack || loading}
          />
          <Pagination.Prev
            disabled={!canNavigateBack || loading}
            aria-label="Previous"
            onClick={getPreviousPage}
          />
          {pageArr.map((pageNumber) => {
            const offset = (pageNumber - 1) * 10;
            return (
              <Pagination.Item
                active={currentPage == pageNumber}
                key={pageNumber}
                disabled={loading}
                onClick={() => {
                  getPageAtOffset(offset);
                }}
              >
                {pageNumber}
              </Pagination.Item>
            );
          })}
          <Pagination.Next
            disabled={!canNavigateForward || loading}
            aria-label="Next"
            onClick={getNextPage}
          />
          <Pagination.Last
            onClick={() => {
              getPageAtOffset((totalPages - 1) * limit);
            }}
            disabled={!canNavigateForward || loading}
          />
        </Pagination>
      </nav>
    </>
  );
};

export default PaginationComponent;
