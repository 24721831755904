import React, { FC, useState } from "react";
import { FinancialApplicationFraudResult } from "../../models/applications/applicationInterfaces";
import { capitalize } from "lodash";
import VerticalCaret from "../../components/ui/verticalCaret";
interface FraudResultReport {
  fraudResult?: FinancialApplicationFraudResult;
}

const FraudResultReport: FC<FraudResultReport> = ({ fraudResult }) => {
  const [collapsed, setCollapsed] = useState(false);

  //Risk or Info Background Color
  const getRiskBgColor = (riskLevel?: string) => {
    switch (riskLevel) {
      case "risk":
        return "#fdd5d6";
      default:
        return "#eceff1";
    }
  };
  const getRiskFontColor = (riskLevel?: string) => {
    switch (riskLevel) {
      case "risk":
        return "#eb2835";
      default:
        return "#949da5";
    }
  };

  if (!fraudResult) {
    return null;
  }

  return (
    <>
      {/* --- Expandable Report Header --- */}
      <div
        className={`text-white bg-tertiary px-3 py-1 ${
          collapsed ? `mb-3` : `mb-0`
        }`}
        style={{
          borderRadius: collapsed ? "8px" : "8px 8px 0 0",
          cursor: "pointer",
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <div
          className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
          style={{}}
        >
          <div>{capitalize(fraudResult.category)}</div>
          <VerticalCaret
            collapsed={collapsed}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
      </div>
      {/* --- Report Content --- */}
      {!collapsed && (
        <div
          className="bg-white mb-3 border px-2"
          style={{
            borderColor: "#ededed",
            borderRadius: "0 0 8px 8px",
          }}
        >
          <table className="w-100" style={{ border: "none" }}>
            <tbody>
              {fraudResult?.fraudReasons?.map((r) => {
                return (
                  <tr key={r.id}>
                    <td style={{ border: "none" }}>
                      <p className="mb-0 pl-1">
                        <span
                          style={{
                            color: getRiskBgColor(r.riskLevel),
                            marginRight: "8px",
                            fontSize: "20px",
                          }}
                        >
                          •
                        </span>
                        {r.message}
                      </p>
                    </td>
                    <td style={{ width: "60px", border: "none" }}>
                      <div
                        className="px-1 text-center align-content-center"
                        style={{
                          backgroundColor: getRiskBgColor(r.riskLevel),
                          color: getRiskFontColor(r.riskLevel),
                          borderRadius: "20% / 50%",
                          height: "20px",
                          fontSize: "14px",
                        }}
                      >
                        {capitalize(r.riskLevel)}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default FraudResultReport;
