import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams, Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../../components/PrivateRoute";
import { Activity } from "./activity";
import { Notes } from "./notes";
import { Fraud } from "./fraud";
import { OFAC } from "./ofac";
import { Messages } from "./messages";
import { WatchlistScreening } from "./watchlistScreening";
import { useDispatch, useSelector } from "react-redux";
import {
  selectApplicationDetail,
  getApplicationDetail,
  getApplicationActivity,
  getApplicationPdfs,
  getApplicationScreenshots,
  getApplicationTasks,
  getApplicationNotes,
  getApplicationMessages,
  getFraudEvaluation,
  getBusinessEvaluation,
  getFisGkycEvaluation,
  getAvailableDecisionStatuses,
  getDecisionStatus,
  getDecisionStatusLogs,
  getIPQSApplications,
  getIPQSFraudResults,
  getOnboardingApplicationResponses,
  getWatchlistOrders,
} from "../../models/applications/applicationDetailSlice";
import { ApplicantSidebar } from "../../components/sidebar/applicantSidebar";
import { AdminSidebar } from "../../components/sidebar/adminSidebar";
import { selectMyUser } from "../../models/users/myUserSlice";
import { ApplicationNavbar } from "./applicationNavbar";
import { TaskViewer } from "../../components/sidebar/taskViewer";
import { GlobalFlashMessage } from "../../components/flashMessage";
import { Spinner } from "../../components/spinner";
import { asyncTimeout } from "../../utils/helpers";
import { mixpanel } from "../../utils/mixpanel";
import { EnvelopePaperFill, CheckCircleFill } from "react-bootstrap-icons";
import { GlobalKYC } from "./gkyc";
import { Task } from "../../models/applications/applicationInterfaces";
import { BagCheckFill } from "react-bootstrap-icons";
import { RootState } from "../../app/store";
import { BusinessGuard } from "./businessGuard";
import { DecisionIntelligence } from "./decisionIntelligence";
import SmallSpinner from "../../components/smallSpinner";
import PdfViewer from "../../components/sidebar/taskViewer/pdfViewer";
import ScreenshotViewer from "../../components/sidebar/taskViewer/screenshotViewer";

export const selectOrg = (state: RootState) => state.organization;

export const ApplicationDetail: FC = () => {
  const { applicationId } = useParams<{
    applicationId: string;
  }>();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const {
    activity,
    decisionStatusLogs,
    application,
    availableDecisionStatuses,
    decisionStatus,
    tasks,
    pdfs,
    screenshots,
    notes,
    messages,
    fraudData,
    businessGuardData,
    fisGkycEvaluations,
    applicationsData,
    fraudResultsData,
    watchlistData,
    onboardingApplicationData,
    flowData,
  } = useSelector(selectApplicationDetail);
  const organization = useSelector(selectOrg);
  const user = useSelector(selectMyUser);
  const permissions = useMemo(() => application?.permissions, [application]);

  const [frameLoaded, setFrameLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  // State for managing the last selected note of child component "Notes" to improve the user experience when switching between tabs.
  const [activeNote, setActiveNote] = useState(0);

  // Custom sorting function
  const sortByDocumentRequired = (taskA: Task, taskB: Task) => {
    if (taskA.documentRequired === taskB.documentRequired) {
      return 0; // Leave elements in their current order if documentRequired is the same
    }

    // If taskA's documentRequired is false, move it to the end
    if (taskA.documentRequired === false) {
      return 1;
    }

    // If taskB's documentRequired is false, move it to the beginning
    if (taskB.documentRequired === false) {
      return -1;
    }

    // If documentRequired is different for taskA and taskB, return a negative value to keep the original order
    return 0;
  };

  // Sort the tasks array using the custom sorting function
  const sortedTasks = tasks.slice().sort(sortByDocumentRequired);

  // Define the application as "locked" if its decision status is locked, or if it is archived.
  const applicationLocked =
    (organization.meta?.enableDecisioning === true
      ? decisionStatus?.locked === true
      : application && ["approved", "denied"].includes(application.status)) ||
    application?.archived === true;
  // Define if the user viewing the application is allowed to edit it.
  let isMyApplication: boolean | undefined = undefined;
  if (user && application) {
    isMyApplication = user?.userId === application?.user.userId;
  }
  const canViewDocuments = useMemo(
    () =>
      isMyApplication === true ||
      !!permissions?.includes("view-application-document"),
    [permissions, isMyApplication]
  );

  // Stagger the loading of data to increase speed of initial load
  async function retrieveData() {
    await Promise.all([
      dispatch(getApplicationDetail(Number(applicationId))),
      dispatch(getOnboardingApplicationResponses(Number(applicationId))),
      dispatch(getApplicationActivity(Number(applicationId))),
      dispatch(getFraudEvaluation(applicationId)),
      dispatch(getFisGkycEvaluation(applicationId)),
      dispatch(getBusinessEvaluation(applicationId)),
      dispatch(getIPQSApplications(applicationId)),
      dispatch(getIPQSFraudResults(applicationId)),
      dispatch(getDecisionStatusLogs(Number(applicationId))),
      dispatch(getApplicationTasks(Number(applicationId))),
      dispatch(getApplicationPdfs(Number(applicationId))),
      dispatch(getApplicationScreenshots(Number(applicationId))),
      dispatch(getWatchlistOrders(applicationId)),
    ]);
    setFrameLoaded(true);
    await Promise.all([
      dispatch(getDecisionStatus(Number(applicationId))),
      dispatch(getApplicationMessages(applicationId)),
      dispatch(getApplicationNotes({ id: applicationId })),
      dispatch(getAvailableDecisionStatuses(Number(applicationId))),
    ]);
    setDataLoaded(true);
  }
  useEffect(() => {
    if (isMyApplication) {
      mixpanel.track("Application Detail - Application View", {
        "User Id": user?.userId,
        "Company Name": organization.name,
        "Host Name": window.location.hostname,
        Page: window.location.href,
      });
    }
  }, [isMyApplication]);

  useEffect(() => {
    setFrameLoaded(false);
    setDataLoaded(false);
    retrieveData();
  }, [applicationId]);

  return (
    <div id="application-detail" className="row no-gutters">
      {/* CONTENT -------------------------------------------------------------------- */}
      <Spinner show={!frameLoaded} />
      {application &&
        activity &&
        decisionStatusLogs &&
        tasks &&
        isMyApplication !== undefined &&
        onboardingApplicationData &&
        flowData &&
        frameLoaded && (
          <>
            <div
              className="col-md-12 col-lg-9 order-md-1 order-lg-2 "
              style={{ borderRadius: "16px", background: "#f6f6f6" }}
            >
              {/* Tabs - "Items Needed From You" (etc) */}
              <ApplicationNavbar
                isMyApplication={isMyApplication}
                tasks={tasks}
                gkycAvailable={
                  fisGkycEvaluations && fisGkycEvaluations?.records?.length > 0
                    ? true
                    : false
                }
              />
              <GlobalFlashMessage />
              {/* Main Content */}
              <div
                className="p-5 bg-white"
                style={{
                  borderRadius: "16px 16px 0px 0px",
                  minHeight: "80vh",
                }}
              >
                <Switch>
                  <PrivateRoute path={`${path}/documents`}>
                    {!dataLoaded ? (
                      <SmallSpinner className="text-primary" />
                    ) : (
                      // Documents Dashboard
                      <div className="documents-container p-5 mb-3 mb-3 shadow-left">
                        {/* Guard clause to prevent unauthorized access to the page */}
                        {canViewDocuments === false && (
                          <div className="d-flex align-items-center mb-5 text-white bg-primary p-3 rounded">
                            <BagCheckFill size={32} className="mr-3" />
                            <p className="mb-0 font-weight-bold">
                              You do not have permission to view this page.
                            </p>
                          </div>
                        )}
                        {/* If the application is locked, display a help message informing them of the state. */}
                        {applicationLocked && (
                          <div className="d-flex align-items-center mb-5 text-white bg-primary p-3 rounded">
                            <BagCheckFill size={32} className="mr-3" />
                            <p className="mb-0 font-weight-bold">
                              This application is locked and cannot be modified.
                            </p>
                          </div>
                        )}
                        {tasks.length === 0 &&
                        pdfs.length === 0 &&
                        screenshots.length === 0 &&
                        canViewDocuments ? (
                          <>
                            <h1
                              className="text-tertiary h2 mb-4"
                              style={{ fontWeight: 750 }}
                            >
                              <EnvelopePaperFill className="mr-2" />
                              Documents
                            </h1>
                            <div className="d-flex py-2">
                              <CheckCircleFill
                                className="mr-2"
                                style={{ color: "green" }}
                              />
                              Congratulations! This application requires no
                              additional documents
                              {application.status === "ready_for_partner_review"
                                ? " and is being reviewed."
                                : "."}
                            </div>
                          </>
                        ) : (
                          [
                            ...sortedTasks.map((task) => (
                              <div className="mb-5" key={task.id}>
                                <TaskViewer
                                  task={task}
                                  canUpload={
                                    !applicationLocked &&
                                    (isMyApplication === true ||
                                      !!permissions?.includes(
                                        "create-application-document"
                                      ))
                                  }
                                  canDelete={
                                    !applicationLocked &&
                                    (isMyApplication === true ||
                                      !!permissions?.includes(
                                        "delete-application-document"
                                      ))
                                  }
                                />
                              </div>
                            )),
                            <div className="mb-5">
                              <PdfViewer pdfs={pdfs} />
                            </div>,
                            <div className="mb-5">
                              <ScreenshotViewer screenshots={screenshots} />
                            </div>,
                          ]
                        )}
                      </div>
                    )}
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/activity`}>
                    <Activity
                      activity={activity}
                      decisionStatusLogs={decisionStatusLogs}
                      user={user}
                    />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/notes`}>
                    {!dataLoaded ? (
                      <SmallSpinner className="text-primary" />
                    ) : (
                      <Notes
                        applicant={application?.user}
                        notes={notes}
                        activeNote={activeNote}
                        setActiveNote={setActiveNote}
                      />
                    )}
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/messages`}>
                    {!dataLoaded ? (
                      <SmallSpinner className="text-primary" />
                    ) : (
                      <Messages
                        applicant={application?.user}
                        messages={messages}
                        isMyApplication={isMyApplication}
                      />
                    )}
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/fraud`}>
                    <Fraud fraudData={fraudData} />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/decision`}>
                    <DecisionIntelligence
                      effectivData={fraudData}
                      decisionStatusLogs={decisionStatusLogs}
                      applicationsData={applicationsData}
                      fraudResultsData={fraudResultsData}
                    />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/business`}>
                    <BusinessGuard
                      businessGuardData={businessGuardData}
                      applicationsData={applicationsData}
                      fraudResultsData={fraudResultsData}
                    />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/gkyc`}>
                    <GlobalKYC fisGkycEvaluations={fisGkycEvaluations} />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/watchlist`}>
                    <WatchlistScreening watchlistData={watchlistData} />
                  </PrivateRoute>
                  {/* Deprecated */}
                  <PrivateRoute path={`${path}/ofac`}>
                    <OFAC isAdmin={user?.isAdminPortalUser || false} />
                  </PrivateRoute>
                </Switch>
              </div>
            </div>

            <div className="col-md-12 col-lg-3 order-md-2 order-lg-1">
              {isMyApplication ? (
                <ApplicantSidebar
                  application={application}
                  decisionStatus={decisionStatus}
                  onboardingApplicationData={onboardingApplicationData}
                  flowData={flowData}
                />
              ) : (
                <AdminSidebar
                  availableDecisionStatuses={availableDecisionStatuses}
                  decisionStatus={dataLoaded ? decisionStatus : undefined}
                  tasks={tasks}
                  retrieveData={retrieveData}
                  decisionsLocked={applicationLocked}
                  onboardingApplicationData={onboardingApplicationData}
                  flowData={flowData}
                  financialApplication={applicationsData}
                />
              )}
            </div>
          </>
        )}
    </div>
  );
};
