import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../app/store";

export type MessageType ='success'|'danger'|'info';

export interface SystemMessage {
    message?: string;
    type?: MessageType
}

const initialState: SystemMessage = {
    
}

export const systemMessageSlice = createSlice({
    name: "systemMessage",
    initialState,
    reducers: {
        setMessage(state, action: PayloadAction<{message: string, type: MessageType}>) {
            return action.payload;
        },
        clearMessage(state, action: PayloadAction<void>) {
            return {}
        }
    }
})


export const selectSystemMessage = (state: RootState) => state.systemMessage;

export default systemMessageSlice.reducer;