import parsePhoneNumber from "libphonenumber-js";
import { type } from "os";

export function splitCamelCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(" ")
    .join(" ");
}

export function capitalizeFirstLetter(str: string) {
  if (typeof str !== "string") return str;
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => (word ? word[0].toUpperCase() + word.slice(1) : "")) // Check if word is not empty before capitalizing
    .join(" ");
}

export const convertCamel = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * Returns the phone number in the national format
 *  example of returned value: +1555-555-5555 => (555) 555-5555
 */
export const formatAsNationalPhoneNum = (phoneNumber?: string) => {
  if (!phoneNumber) return "";
  const parsedNumber = parsePhoneNumber(phoneNumber);

  if (parsedNumber) {
    return parsedNumber.formatNational();
  }

  return phoneNumber;
};
