import React, { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Message } from "../../models/applications/applicationInterfaces";
import { User } from "../../models/users/userInterfaces";

import { useDispatch, useSelector } from "react-redux";
import { Send, ChatDotsFill } from "react-bootstrap-icons";
import {
  getApplicationActivity,
  getApplicationMessages,
  selectApplicationDetail,
} from "../../models/applications/applicationDetailSlice";
import api from "../../utils/api";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { MessageRecord } from "./messageRecord";
import { is } from "date-fns/locale";
interface MessagesProps {
  applicant: User;
  messages?: Message[];
  isMyApplication?: boolean;
}

interface MessageInputs {
  message: string;
}

export const Messages: FC<MessagesProps> = ({
  applicant,
  messages,
  isMyApplication,
}) => {
  const dispatch = useDispatch();
  const application = useSelector(selectApplicationDetail);
  const appDetails = useMemo(() => application.application, [application]);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );

  const { register, errors, handleSubmit, formState, setValue } =
    useForm<MessageInputs>({
      mode: "onChange",
    });

  async function sendMessage({ message }: MessageInputs) {
    try {
      await api.post(`/submissions/${appDetails?.id}/comments`, {
        message,
      });
      setValue("message", "");
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Message Has Been Sent!",
          type: "success",
        })
      );
      // Refresh messages and activity state for application
      if (appDetails) {
        dispatch(getApplicationMessages(appDetails.id));
        dispatch(getApplicationActivity(appDetails.id));
      }
    } catch (e) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Error - Message Failed. Please try again later.",
          type: "danger",
        })
      );
    }
  }

  // Guard against applicants seeing admin view or administrats who can't send messages.
  if (
    isMyApplication === true ||
    !permissions?.includes("create-application-comment")
  ) {
    return <p>You don't have permission to view this page.</p>;
  }

  return (
    <div className="documents-container p-5 mb-3 shadow-left">
      <h1 className="text-tertiary h2 mb-0" style={{ fontWeight: 750 }}>
        <ChatDotsFill className="mr-2" />
        Messages
      </h1>
      {/* Message form */}
      <div className="message-container border-bottom">
        <form onSubmit={handleSubmit(sendMessage)}>
          <div className="form-group">
            <small className="font-weight-bold mb-1 text-primary">
              Send Message to {applicant.firstName} {applicant.lastName}
            </small>
            <textarea
              name="message"
              rows={6}
              style={{
                resize: "none",
              }}
              className="form-control p-3"
              ref={register({ required: true, maxLength: 4000 })}
              placeholder="What do you want to write?"
            />
          </div>
          <div className="d-flex justify-content-end form-group">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={formState.isSubmitting || !formState.isValid}
            >
              <Send className="mr-2" />
              Send
            </button>
          </div>
        </form>
      </div>
      {/* Display list of messages between administrators and user */}
      <div className="mt-2 d-flex flex-column">
        {messages && messages.length > 0 ? (
          messages
            .slice()
            .reverse()
            .map((message: Message) => {
              return (
                <MessageRecord
                  message={message}
                  fromAdmin={applicant.userId !== message.user.id}
                  className="mb-2"
                />
              );
            })
        ) : (
          // If no messages, display this instead.
          <p className="mt-2">You have not exchanged any messages yet.</p>
        )}
      </div>
    </div>
  );
};
