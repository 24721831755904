import React from "react";

const SmallSpinner = ({
  color,
  className,
  style,
}: {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <div className={className} style={style}>
      <div
        className="d-block spinner-border spinner-border-sm"
        style={{ color: color }}
        role="status"
      />
    </div>
  );
};

export default SmallSpinner;
