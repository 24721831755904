import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CompassFill, ArrowLeftCircleFill } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { mixpanel } from "../utils/mixpanel";

const Error404 = () => {
  const history = useHistory();

  // Track page view
  useEffect(() => {
    mixpanel.init("fef4ba7ec4cd83588a240b7427c000d7");
    mixpanel.track("Page View - 404", {
      "Host Name": window.location.hostname,
      Page: window.location.href,
    });
  }, []);

  return (
    <>
      {/* Metadata for Page */}
      <Helmet>
        <title>Oops! 404</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          background:
            "linear-gradient(180deg, rgba(255,255,255,0) 0%,  rgb(71, 177, 161) 10%, rgb(71, 177, 161) 80%, rgba(255,255,255,0) 100%)",
        }}
      >
        <img
          src="https://res.cloudinary.com/cotribute/image/upload/v1655147027/stock-images/Raw/Business%20Loan/smiling-man-woman-cash-register.jpg"
          alt="Smiling man and woman at cash register"
          style={{
            maxHeight: "40%",
            width: "80%",
            objectFit: "cover",
            border: "solid 2px rgb(71, 177, 161)",
            borderRadius: "30px",
            marginTop: "3%",
          }}
        />
        <div style={{ textAlign: "center", width: "95%" }}>
          <h1
            style={{
              fontSize: "50px",
              fontWeight: "bolder",
              color: "white",
              lineHeight: "45px",
              marginTop: "40px",
            }}
          >
            <CompassFill style={{ fontSize: "50px" }} /> Oops! We couldn't find
            that page.
          </h1>
          <h2 style={{ color: "white", marginTop: "20px" }}>
            Please check the url you entered and try again.
          </h2>
          <button
            style={{
              background: "none",
              border: "none",
              color: "white",
              textDecoration: "underline",
              marginTop: "10px",
            }}
            onClick={() => history.goBack()}
          >
            <ArrowLeftCircleFill style={{ marginRight: "4px" }} /> Go Back
          </button>
          <h1
            style={{
              fontSize: "45px",
              fontWeight: "bolder",
              color: "white",
              marginTop: "20px",
            }}
          >
            ERROR 404
          </h1>
        </div>
      </div>
    </>
  );
};

export default Error404;
