import React, { useEffect } from "react";
import "./App.scss";
import "./styles/root.scss";
import "./styles/dashboard.scss";
import Error404 from "./pages/404";

import { hot } from "react-hot-loader";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import OrganizationPage from "./pages/organization";
import { mixpanel } from "./utils/mixpanel";

function App() {
  return (
    <Router>
      <BootApp />
    </Router>
  );
}

const BootApp = () => {
  useEffect(() => {
    mixpanel.init("fef4ba7ec4cd83588a240b7427c000d7");
  }, []);
  return (
    <Switch>
      <Route path={"/organization/:id"}>
        <OrganizationPage />
      </Route>
      <Route path={"404"}>
        <Error404 />
      </Route>
      {/* For all non-specified routes, append 404 to URL and display error page. */}
      <Route>
        <Redirect
          to={{
            pathname: `404`,
          }}
        />
        <Error404 />
      </Route>
    </Switch>
  );
};

export default hot(module)(App);
