import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectApplicationStatus } from "../models/applicationStatusSlice";
export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated } = useSelector(selectApplicationStatus);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        let org = "";
        const matches = location.pathname.match(/organization\/(.*)\//);

        if (matches) org = matches[1];

        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/organization/${org.split("/")[0]}/login`,
            }}
          />
        );
      }}
    />
  );
};
