import React, { FC, useState } from "react";
import { orderBy } from "lodash";
import moment from "moment";
import { DecisionIntelligenceProps } from "./decisionIntelligence";

const ExecutedRules: FC<
  Pick<DecisionIntelligenceProps, "decisionStatusLogs">
> = ({ decisionStatusLogs }) => {
  const [latestStatusLog] = orderBy(
    decisionStatusLogs?.filter((log) => log.availableDecisionRules?.length) ||
      [],
    (log) => moment.utc(log.createdAt).unix(),
    "desc"
  );
  // State for managing whether list is expanded or not
  const [expandPrevious, setExpandPrevious] = useState(false);
  const [expandAdditional, setExpandAdditional] = useState(false);

  if (!latestStatusLog) {
    return null;
  }

  const availableDecisionRules = orderBy(
    latestStatusLog.availableDecisionRules,
    (rule) => rule.executionOrder
  );

  const firstMatch = availableDecisionRules.findIndex(
    (rule) => rule.matched === true
  );

  const previousChecks =
    firstMatch === -1
      ? availableDecisionRules
      : availableDecisionRules.slice(0, firstMatch);
  const additionalChecks =
    firstMatch === -1 || firstMatch + 1 === availableDecisionRules.length
      ? []
      : availableDecisionRules.slice(firstMatch + 1);
  const hit = availableDecisionRules[firstMatch];

  const getDecisionColor = (decision: string | undefined) => {
    switch (decision) {
      case "approved":
        return "#00b4a1";
      case "review":
        return "#e2832c";
      case "denied":
        return "#eb2835";
      default:
        return "#949da5";
    }
  };
  const getDecisionDisplay = (decision: string | undefined) => {
    switch (decision) {
      case "approved":
        return "Approved";
      case "review":
        return "Review";
      case "denied":
        return "Denied";
      default:
        return "No Decision";
    }
  };

  if (!hit) {
    // we should never get here
    return null;
  }

  return (
    <div
      className="mb-4 px-3 py-4 font-weight-bold"
      style={{ backgroundColor: "#f3f8f9" }}
    >
      <div className="row">
        {/* Column 1 - Status Summary */}
        <div className="col-md-2 pr-0">
          <div className="font-weight-bold" style={{ fontSize: "10pt" }}>
            STATUS
          </div>
          <div style={{ color: "#a2b0bc", fontSize: "10pt" }}>
            {latestStatusLog.newDecisionStatus?.title.toUpperCase()}
          </div>
          <div
            className="h3 mt-2"
            style={{
              color: getDecisionColor(
                latestStatusLog.newDecisionStatus?.decision
              ),
            }}
          >
            {getDecisionDisplay(latestStatusLog.newDecisionStatus?.decision)}
          </div>
          {(expandPrevious || expandAdditional) && (
            <div className="h3 mt-2" style={{ fontSize: "14px" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setExpandPrevious(false);
                  setExpandAdditional(false);
                }}
              >
                <div
                  className="text-primary"
                  style={{ textDecoration: "underline" }}
                >
                  Collapse
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Column 2 - Details */}
        <div className="col-md-9" style={{ fontSize: "14px" }}>
          <ul className="decision-details">
            {expandPrevious === false && !!previousChecks.length && (
              <li key="previous">
                <div className="row">
                  <div className="p-0 col-sm-7 text-capitalize">
                    {previousChecks.length} Completed Check
                    {previousChecks.length > 1 ? "s" : ""}
                  </div>
                  <div className="p-0 col-sm-5 text-align-right text-capitalize text-right">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setExpandPrevious(true)}
                    >
                      <div
                        className="text-primary"
                        style={{ textDecoration: "underline" }}
                      >
                        Expand
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )}
            {expandPrevious === true &&
              previousChecks.map((rule) => (
                <li key={rule.uuid}>
                  <div className="row">
                    <div className="p-0 col-sm-7 text-capitalize">
                      {rule.title}
                    </div>
                    <div className="p-0 col-sm-3 text-capitalize pl-2">
                      {rule.decisionStatus.title}
                    </div>
                    <div className="p-0 col-sm-2 text-align-right text-capitalize text-right">
                      {rule.decisionStatus.decision}
                    </div>
                  </div>
                </li>
              ))}
            <li
              className={`hit ${hit.decisionStatus.decision?.toLowerCase()}`}
              style={{
                color: getDecisionColor(hit.decisionStatus?.decision),
              }}
              key={hit.uuid}
            >
              <div className="row">
                <div className="p-0 col-sm-7 text-capitalize">{hit.title}</div>
                <div className="p-0 col-sm-3 text-capitalize pl-2">
                  {hit.decisionStatus.title}
                </div>
                <div className="p-0 col-sm-2 text-align-right text-capitalize text-right">
                  {hit.decisionStatus.decision}
                </div>
              </div>
            </li>
            {expandAdditional === false && !!additionalChecks.length && (
              <li
                key="additional"
                className="skipped"
                style={{ color: "#cfcfcf" }}
              >
                <div className="row">
                  <div className="p-0 col-sm-7 text-capitalize">
                    {additionalChecks.length} Additional Check
                    {additionalChecks.length > 1 ? "s" : ""}
                  </div>
                  <div className="p-0 col-sm-5 text-align-right text-capitalize text-right">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setExpandAdditional(true)}
                    >
                      <div
                        className="text-primary"
                        style={{ textDecoration: "underline" }}
                      >
                        Expand
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )}
            {expandAdditional === true &&
              additionalChecks.map((rule) => (
                <li
                  key={rule.uuid}
                  className={
                    rule.matched
                      ? `hit ${rule.decisionStatus.decision?.toLowerCase()}`
                      : "skipped"
                  }
                  style={
                    rule.matched
                      ? {
                          color: getDecisionColor(
                            rule.decisionStatus?.decision
                          ),
                        }
                      : { color: "#cfcfcf" }
                  }
                >
                  <div className="row">
                    <div className="p-0 col-sm-7 text-capitalize">
                      {rule.title}
                    </div>
                    <div className="p-0 col-sm-3 text-capitalize pl-2">
                      {rule.decisionStatus.title}
                    </div>
                    <div className="p-0 col-sm-2 text-align-right text-capitalize text-right">
                      {rule.decisionStatus.decision}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ExecutedRules;
