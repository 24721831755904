import React, { FC, useMemo, useState } from "react";
import { ShieldFillPlus } from "react-bootstrap-icons";
import {
  BusinessEvaluationResponse,
  FinancialApplication,
  FinancialApplicationFraudResult,
} from "../../models/applications/applicationInterfaces";
import MiddeskDisplay from "./middeskDisplay";
import IpqsDisplay from "./ipqsDisplay";
import { useSelector } from "react-redux";
import { selectApplicationDetail } from "../../models/applications/applicationDetailSlice";

interface BusinessGuardProps {
  businessGuardData?: BusinessEvaluationResponse;
  applicationsData?: FinancialApplication;
  fraudResultsData?: FinancialApplicationFraudResult[];
}

export const BusinessGuard: FC<BusinessGuardProps> = ({
  businessGuardData,
  applicationsData,
  fraudResultsData,
}) => {
  // Handle state for each tab (applicant, coapplicant)
  const [activeTab, setActiveTab] = useState("applicant");
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const application = useSelector(selectApplicationDetail);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );

  // Logic for displaying tabs
  const hasFraudData = fraudResultsData?.some(
    (result) => result.scope === "applicant"
  );
  const hasCoApplicantFraudData = fraudResultsData?.some(
    (result) => result.scope === "co-applicant"
  );
  // ------------------------------------------------ RENDER ------------------------------------------------
  // Guard against users seeing admin view.
  if (!permissions?.includes("view-application-business-guard-report")) {
    return <p>You don't have permission to view this page.</p>;
  }

  // If no business data, alert that no business data is available.
  if (!businessGuardData) {
    return (
      <>
        <h1 className="text-tertiary h2 mb-4" style={{ fontWeight: 750 }}>
          <ShieldFillPlus className="mr-2" />
          BusinessGuard+ Report
        </h1>
        <p className="mt-2">
          No business data was provided for this application.
        </p>
      </>
    );
  }
  // If there is IPQS for applicant and coapplicant
  else if (hasFraudData && hasCoApplicantFraudData) {
    return (
      <>
        <div>
          {/* Tabs for applicant and coapplicant */}
          <div style={{ borderBottom: "1px solid #e3e3e3" }}>
            <button
              className="mr-3"
              style={{
                borderBottom:
                  activeTab === "applicant" ? "2px solid #010101" : "none",
                background: "transparent",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                outline: "none",
              }}
              onClick={() => handleTabClick("applicant")}
            >
              <div className="text-center">
                <div className="h5 mb-0" style={{ color: "#010101" }}>
                  {applicationsData?.applicant?.firstName}{" "}
                  {applicationsData?.applicant?.lastName}
                </div>
                <div style={{ color: "#a2b0bc", fontSize: "10pt" }}>
                  Applicant
                </div>
              </div>
            </button>{" "}
            <button
              style={{
                borderBottom:
                  activeTab === "coapplicant" ? "2px solid #010101" : "none",
                background: "transparent",
                border: "none",
                outline: "none",
              }}
              onClick={() => handleTabClick("coapplicant")}
            >
              <div className="h5 mb-0" style={{ color: "#010101" }}>
                {applicationsData?.coApplicant?.firstName}{" "}
                {applicationsData?.coApplicant?.lastName}
              </div>
              <div style={{ color: "#a2b0bc", fontSize: "10pt" }}>
                Account Owner
              </div>
            </button>
          </div>
          {activeTab === "applicant" && (
            <div>
              <IpqsDisplay
                fraudResultsData={fraudResultsData}
                applicantType="applicant"
              />
              <MiddeskDisplay businessGuardData={businessGuardData} />
            </div>
          )}
          {activeTab === "coapplicant" && (
            <div>
              <div>
                <IpqsDisplay
                  fraudResultsData={fraudResultsData}
                  applicantType="coapplicant"
                />
                <MiddeskDisplay businessGuardData={businessGuardData} />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  // If there is IPQS for just the applicant
  else if (hasFraudData && !hasCoApplicantFraudData) {
    return (
      <>
        <div>
          <IpqsDisplay
            fraudResultsData={fraudResultsData}
            applicantType="applicant"
          />
          <MiddeskDisplay businessGuardData={businessGuardData} />
        </div>
      </>
    );
  }

  // Normal render (both demo and otherwise) just show Middesk Data
  return (
    <>
      <MiddeskDisplay businessGuardData={businessGuardData} />
    </>
  );
};
