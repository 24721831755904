import React, { FC, useState } from "react";
import {
  ActivityItem,
  NotificationType,
} from "../../models/applications/applicationInterfaces";
import { formatDate } from "../../utils/helpers";
import { selectMyUser } from "../../models/users/myUserSlice";
import { useSelector } from "react-redux";

interface Props {
  activity: ActivityItem;
}

interface AdditionalContent {
  description: string;
  value: string;
}

type NotificationMeta = {
  title: string;
  cta?: string;
  ctaHandler?: Function;
  subTitle?: string;
  message?: string;
  additionalContent: AdditionalContent[];
};

export const ActivityFeedItem: FC<Props> = ({ activity }) => {
  const [showFullMessage, setShowFullMessage] = useState(false);
  const payload = activity.payload;
  const fromUser = activity.fromUser;
  const fromUserName = `${fromUser?.firstName.trim() || ""} ${
    fromUser?.lastName.trim() || ""
  }`.trim();
  const user = useSelector(selectMyUser);

  const notificationTypeToTitle: { [key: string]: () => string } = {
    application_received: () => "Application Received",
    new_benevolence_submission_to_applicant: () =>
      "Confirmation Notice Sent (Email / SMS)",
    new_completed_benevolence_submission_to_applicant: () =>
      "Confirmation Notice Sent (Email / SMS)",
    new_benevolence_submission_to_assigned_benevolence_partner: () =>
      `Admin Assigned`,
    benevolence_submission_tasks_incomplete: () =>
      "Incomplete Application Notice Sent (Email / SMS)",
    benevolence_submission_tasks_incomplete_3_day_reminder: () =>
      "Incomplete Application Notice Sent (Email / SMS)",
    benevolence_submission_tasks_incomplete_7_day_reminder: () =>
      "Incomplete Application Notice Sent (Email / SMS)",
    benevolence_submission_tasks_incomplete_14_day_reminder: () =>
      "Incomplete Application Notice Sent (Email / SMS)",
    benevolence_submission_task_completed_by_applicant: () =>
      `Task Completed - ${(payload as any).task.title}`,
    benevolence_submission_all_tasks_completed_by_applicant: () =>
      "benevolence_submission_all_tasks_completed_by_applicant",
    benevolence_submission_denied_notice_sent: () =>
      "Denial Notice Sent (Email / SMS)",
    benevolence_submission_approved: () => "Application Approved",
    benevolence_submission_denied: () => "Application Denied",
    benevolence_submission_ready_for_client_review_notice_sent: () =>
      "Ready for Client Review Notice Send (Email / SMS)",
    benevolence_submission_ready_for_client_review: () =>
      "Status Updated to Ready for Client Review",
    new_comment_to_benevolence_applicant: () =>
      "Admin Message Sent (Email / SMS)",
    submission_export: () => "submission_export",
    new_comment_to_assigned_benevolence_partner_admin: () =>
      "Applicant Message Sent (Email / SMS)",
    submission_decision_status_updated: () => "Decision Updated",
    submission_decision_status_auto_updated: () => "Decision Auto Updated",
    submission_archived_status_updated: () =>
      `Application ${payload.archived ? "Archived" : "Unarchived"}`,
    submission_document_uploaded: () => `Document Uploaded`,
    submission_document_deleted: () => `Document Deleted`,
    submission_core_banking_processing_failure: () =>
      `Core Banking Processing Failure`,
    submission_core_banking_processing_complete: () =>
      `Core Banking Processing Complete`,
  };

  function getNotificationMeta(): NotificationMeta {
    const result: NotificationMeta = {
      title: notificationTypeToTitle[activity.payload.notificationType](),
      additionalContent: [],
    };

    // When payload is a message, display CTA "show full message" if too long, and "collapse" option once open.
    if (activity.payload.message) {
      const message = activity.payload.message;
      const maxMessageLength = 160;
      const msgGreaterThanMax = message.length > maxMessageLength;
      result.message = showFullMessage
        ? message
        : message.substr(0, maxMessageLength) +
          (msgGreaterThanMax ? "..." : "");
      if (msgGreaterThanMax && !showFullMessage) {
        result.cta = "View Full Message";
        result.ctaHandler = () => setShowFullMessage(true);
      }
      if (msgGreaterThanMax && showFullMessage) {
        result.cta = "Collapse Message";
        result.ctaHandler = () => setShowFullMessage(false);
      }
    }
    if (activity.payload.denialMessage) {
      result.additionalContent = [
        {
          description: "Denial Reason:",
          value: activity.payload.denialMessage,
        },
      ];
    }
    if (activity.payload.campaignInformation) {
      result.additionalContent = activity.payload.campaignInformation;
    }
    if (activity.payload.newDecisionStatus) {
      if (activity.payload.oldDecisionStatus) {
        result.title = `Decision updated from ${activity.payload.oldDecisionStatus} to ${activity.payload.newDecisionStatus}`;
      } else {
        result.title = `Decision updated to ${activity.payload.newDecisionStatus}`;
      }
      if (
        activity.payload.notificationType ===
          NotificationType.submission_decision_status_auto_updated &&
        activity.payload.decisionRuleTitle
      ) {
        result.subTitle = `Auto-decisioned by rule "${activity.payload.decisionRuleTitle}"`;
      } else if (
        activity.payload.notificationType ===
          NotificationType.submission_decision_status_updated &&
        activity.fromUser
      ) {
        result.subTitle = `Updated by ${`${activity.fromUser.firstName || ""} ${
          activity.fromUser.lastName
        }`.trim()}`;
      }
    }
    if (payload.newAdmin) {
      result.subTitle = `${
        fromUserName || "An admin"
      } transferred the application from ${payload.oldAdmin?.firstName} ${
        payload.oldAdmin?.lastName
      } to ${payload.newAdmin?.firstName || ""} ${
        payload.newAdmin?.lastName || ""
      }`;
    }
    if (
      payload.notificationType ===
        NotificationType.benevolence_submission_approved &&
      user?.isAdminPortalUser &&
      activity.fromUser
    ) {
      result.subTitle = `Updated by ${fromUserName}`;
    }
    if (
      payload.notificationType ===
        NotificationType.benevolence_submission_denied &&
      user?.isAdminPortalUser &&
      activity.fromUser
    ) {
      result.subTitle = `Updated by ${fromUserName}`;
    }
    if (
      payload.notificationType ===
        NotificationType.submission_archived_status_updated &&
      user?.isAdminPortalUser &&
      activity.fromUser
    ) {
      result.subTitle = `Updated by ${fromUserName}`;
    }
    return result;
  }
  const { title, subTitle, cta, ctaHandler, message, additionalContent } =
    getNotificationMeta();
  return (
    <div className="row align-items-center mb-2">
      <div className="d-none d-md-block">
        <div className="d-flex justify-content-center align-items-center mx-3">
          <span className="oi oi-target activity-circle-icon"></span>
        </div>
      </div>
      <div className="col pl-0">
        <div className="activity-feed-item shadow-left">
          <div className="d-flex p-1">
            {/* Icon */}
            <ion-icon size="large" name={activity.payload.icon} />
            <div className="content-container col pr-0">
              {/* Title and Subtitle */}
              <div className="d-flex justify-content-between">
                <p className="font-weight-bold mb-0">
                  {title}
                  <br />
                  {subTitle && (
                    <span className="font-weight-normal text-muted">
                      {subTitle}
                    </span>
                  )}
                </p>
                {/* Timestamp */}
                <small className="text-muted col-4 pr-0 text-right">
                  {formatDate(activity.timestamp)}
                </small>
              </div>
              {/* Additional Content (varies based on activityItem type) */}
              {additionalContent.map((item) => {
                return (
                  // --- Description
                  <div className="form-group">
                    <label className="font-weight-bold mb-0 mt-2">
                      {item.description}
                    </label>
                    <p className="text-muted">{item.value}</p>
                  </div>
                );
              })}
              {/* --- Message */}
              {message && (
                <div className="mt-1">
                  <small className="text-muted">
                    {fromUserName && (
                      <span className="font-weight-bold mr-1">
                        {fromUserName}:
                      </span>
                    )}{" "}
                    {message}
                  </small>
                </div>
              )}
              {/* --- CTA Link (Example: View Full Message) */}
              {cta && ctaHandler && (
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    ctaHandler();
                  }}
                >
                  <small className="d-block mt-2">{cta}</small>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
