const win = window as any;

class Mixpanel {
    track(title: string, options: {[key: string]: any}) {
        return win.mixpanel.track(title, options)
    }
    init(key: string, options?: any ) {
        return win.mixpanel.init(key, options)
    }
    identify(userId: number) {
        return win.mixpanel.identify(userId)
    }
}

export const mixpanel = new Mixpanel()

