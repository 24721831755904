import React, { FC, useMemo } from "react";
import { Task } from "../../models/applications/applicationInterfaces";
import { SecondaryNavbar } from "../../components/secondaryNavBar";
import { mobileCheck } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { selectOrg } from "../../models/organization/organizationSlice";
import { selectApplicationDetail } from "../../models/applications/applicationDetailSlice";

interface Props {
  isMyApplication: boolean;
  tasks: Task[];
  gkycAvailable: boolean;
}

export const ApplicationNavbar: FC<Props> = ({
  isMyApplication,
  tasks,
  gkycAvailable,
}) => {
  const isMobile = mobileCheck();

  const application = useSelector(selectApplicationDetail);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );
  const org = useSelector(selectOrg);
  const { fraudData } = useSelector(selectApplicationDetail);
  const { businessGuardData } = useSelector(selectApplicationDetail);
  const { watchlistData } = useSelector(selectApplicationDetail);

  // Check if any action is needed for tasks
  let actionNeeded = tasks.some(
    (task) => task.status !== "complete" && task.documentRequired
  );

  // Define navigation items based on permissions and org settings
  const navItems = useMemo(() => {
    // If the application owner (normal user) views, then show documents first.
    // If an admin views, show activity first.
    if (isMyApplication) {
      // User View - Show documents tab
      return [
        {
          title:
            isMobile || !actionNeeded
              ? "Documents"
              : "Documents (Action Needed)",
          path: "documents",
        },
        { title: "Activity", path: "activity" },
      ];
    } else {
      // Admin View - Render tabs based on permissions
      const items = [];
      // ACTIVITY TAB - Always show
      items.push({ title: "Activity", path: "activity" });
      // DOCUMENTS TAB
      if (!!permissions?.includes("view-application-document")) {
        items.push({
          title:
            isMobile || !actionNeeded
              ? "Documents"
              : "Documents (Action Needed)",
          path: "documents",
        });
      }
      // DECISION INTELLIGENCE TAB
      if (
        !!permissions?.includes(
          "view-application-decision-intelligence-report"
        ) &&
        org.meta?.enableDecisionIntelligence &&
        !businessGuardData
      ) {
        items.push({ title: "Decision Intelligence", path: "decision" });
      } else if (
        fraudData?.records?.length &&
        !!permissions?.includes("view-application-fraud-guard-report")
      ) {
        // FRAUD GUARD+ TAB - Can be displayed instead of Decision Intelligence
        items.push({ title: "FraudGuard+", path: "fraud" });
      }
      // WATCHLIST TAB
      if (
        watchlistData?.length &&
        !!permissions?.includes("view-application-watchlist-report")
      ) {
        items.push({ title: "Watchlist Screening", path: "watchlist" });
      }
      if (
        // BUSINESS GUARD+ TAB
        !!businessGuardData &&
        !!permissions?.includes("view-application-business-guard-report")
      ) {
        items.push({ title: "BusinessGuard+", path: "business" });
      }
      if (
        !!permissions?.includes("view-application-fis-gkyc-report") &&
        gkycAvailable
      ) {
        // FIS Global KYC TAB
        items.push({ title: "FIS Global KYC", path: "gkyc" });
      }
      // OFAC TAB
      if (org.meta?.acquireFraudGuardDemoMode) {
        items.push({ title: "OFAC", path: "ofac" });
      }
      // MESSAGES TAB - Never show for applicants. Show for admins if they have permission to send messages.
      if (
        !isMyApplication &&
        !!permissions?.includes("create-application-comment")
      ) {
        items.push({ title: "Messages", path: "messages" });
      }
      // NOTES TAB
      if (!!permissions?.includes("view-application-note")) {
        items.push({ title: "Notes", path: "notes" });
      }
      return items;
    }
  }, [
    isMyApplication,
    permissions,
    actionNeeded,
    org.meta,
    businessGuardData,
    gkycAvailable,
    tasks,
  ]);

  // Render the SecondaryNavbar component with navigation items
  return <SecondaryNavbar navItems={navItems} />;
};
