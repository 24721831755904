import { Reducer, Action, AnyAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { stat } from "fs";
import { RootState } from "../app/store";
import { login } from "./users/myUserSlice";

const requestTypes = ["fulfilled", "pending", "rejected"];

interface RequestState {
  isLoading: boolean;
  error: string | null;
}
interface AsyncRequest {
  [domain: string]: RequestState;
}

const errorKeys = {
  invalidRole: "invalid role",
  invalidEmailPass: "Request failed with status code 403",
};

const domainErrorTranslationMap: Record<string, Record<string, string>> = {
  [login.typePrefix]: {
    [errorKeys.invalidRole]:
      "Invalid user access. Please talk to your administrator for more permissions.",
    [errorKeys.invalidEmailPass]:
      "Invalid Email or Password. Please Try Again.",
  },
};

const asyncRequestStatusReducer: Reducer = (
  state: AsyncRequest = {},
  action: AnyAction
) => {
  const isRequest = requestTypes.some((t) => action.type.includes(t));
  if (isRequest) {
    const ending = action.type.split("/").pop();
    const domain = action.type.replace(`/${ending}`, "");

    let error: string = action?.error?.message || "";
    if (error && domainErrorTranslationMap?.[domain]?.[error]) {
      error = domainErrorTranslationMap[domain][error];
    }

    const domainState = {
      isLoading: ending === "pending" ? true : false,
      error: ending === "rejected" ? error : null,
    };
    const newState: AsyncRequest = {
      ...state,
      [domain]: domainState,
    };
    return newState;
  }
  return state;
};
export default asyncRequestStatusReducer;

export const selectRequestStatus = (domain: string) => (state: RootState) => {
  if (domain.toUpperCase() === "ALL") {
  }
  return state.asyncRequestStatus[domain] || { isLoading: false, error: null };
};

export const useRequestStatus = function (
  domain: string
): [boolean, string | null] {
  const result = useSelector(selectRequestStatus(domain));
  return [result.isLoading, result.error];
};

export const useSelectAllErrors = function (): { error: string }[] {
  return useSelector((state: RootState) => {
    return Object.values(state.asyncRequestStatus).map((val: any) => val.error);
  });
};
