// This is an updated version of the sidebarApplicationDetail.tsx file in the original codebase, now called applicationDetailSidebarOld.tsx
// The original file displayed application data based on submission data in question/answer format.
// This file displays application data using the onboarding application from Acquire - modeled after Review.tsx in Dreambigger.
// It displays data based on the new response data in step/response format and follows the configuration rules of the review step

// *********************************************************************************************************************
// TO USE THIS FILE, AN ORG MUST SET THE CONFIGURATION "displayOnboardingApplicationResponses": true IN THEIR ORGANIZATION META
// *********************************************************************************************************************

import React, { FC, useMemo } from "react";
import { ApplicationDetail } from "../../models/applications/applicationInterfaces";
import { useSelector } from "react-redux";
import { selectMyUser } from "../../models/users/myUserSlice";
import ApplicationStepSummary from "./applicationStepSummary/applicationStepSummary";
import {
  ResponseStep,
  OnboardingApplication,
  Flow,
} from "../../models/onboardingApplication/onboardingApplicationInterfaces";
import { searchForResponse } from "../../utils/acquire";
import { formatDate } from "../../utils/helpers";
import { splitCamelCase } from "../../utils/formatting";

interface Props {
  application: ApplicationDetail;
  onboardingApplicationData: OnboardingApplication;
  flowData: Flow;
}

export const SidebarApplicationDetail: FC<Props> = ({
  application,
  onboardingApplicationData,
  flowData,
}) => {
  const user = useSelector(selectMyUser);

  const steps = flowData.skins[0].steps;

  // Return the first review step in the onboarding application config and get its assets
  const summaryConfigAssets = useMemo(() => {
    for (const step of steps) {
      if (step.type === "review") {
        return step.assets as {
          keyFilterList: string[];
        };
      }
    }
  }, [application]);

  return (
    <div className="px-4">
      <h6 className="h3 mb-0 text-center text-lg-left mt-2">
        Application Details
      </h6>
      <div className="response-step-field mt-2">
        <span className="meta text-uppercase">Application Number</span>
        <br />
        <span># {application.id}</span>
      </div>
      <div className="response-step-field mt-2">
        <span className="meta text-uppercase">Application ID</span>
        <br />
        <span># {onboardingApplicationData.id}</span>
      </div>
      {Object.entries(onboardingApplicationData.coreBankingAttributes || {})
        .filter(([key]) => !key.toLowerCase().includes("serial"))
        .map(([key, value]) => (
          <div key={key} className="response-step-field mt-2">
            <span className="meta text-uppercase">{splitCamelCase(key)}</span>
            <br />
            <span>{value?.toString() || "-"}</span>
          </div>
        ))}
      {onboardingApplicationData.ip && (
        <div className="response-step-field mt-2">
          <span className="meta text-uppercase">Applicant's IP Address</span>
          <br />
          <span>{onboardingApplicationData.ip}</span>
        </div>
      )}
      {application.created_at && (
        <div className="response-step-field mt-2">
          <span className="meta text-uppercase">Application Received</span>
          <br />
          <span>{formatDate(application.created_at)}</span>
        </div>
      )}
      {steps.map((step, i: any) => {
        let response: ResponseStep = searchForResponse(
          onboardingApplicationData.responses,
          step.slug
        );
        return (
          <ApplicationStepSummary
            key={step.slug}
            step={step}
            response={response}
            i={i}
            keyFilterList={summaryConfigAssets?.keyFilterList || []}
          />
        );
      })}
    </div>
  );
};
