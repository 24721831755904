import React, { FC, useMemo, useState } from "react";
import { Fraud } from "./fraud";
import {
  DecisionStatusLog,
  FinancialApplication,
  FinancialApplicationFraudResult,
} from "../../models/applications/applicationInterfaces";
import IpqsDisplay from "./ipqsDisplay";

import "./decisionIntelligence.scss";
import { useSelector } from "react-redux";
import { selectApplicationDetail } from "../../models/applications/applicationDetailSlice";
import FraudResultReport from "./fraudRiskResult";
import ApplicantSelector from "../../components/ui/applicantSelector";
import ExecutedRules from "./executedRules";

export const PRIMARY_APPLICANT_SCOPES = ["applicant", "application"];
export const CO_APPLICANT_SCOPES = ["co-applicant", "coapplicant"];
export const DISPLAY_ORDER = ["fraud", "device", "email", "phone", "address"];

export interface DecisionIntelligenceProps {
  effectivData: any;
  decisionStatusLogs?: DecisionStatusLog[];
  applicationsData?: FinancialApplication;
  fraudResultsData?: FinancialApplicationFraudResult[];
}

export const DecisionIntelligence: FC<DecisionIntelligenceProps> = ({
  effectivData,
  decisionStatusLogs,
  applicationsData,
  fraudResultsData,
}) => {
  // Logic for displaying tabs
  const [activeTab, setActiveTab] = useState("applicant");
  const application = useSelector(selectApplicationDetail);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );
  const financialApplication = applicationsData;

  const hasFraudData = fraudResultsData && fraudResultsData?.length > 0;
  const hasLegacyEffectivData =
    effectivData && effectivData?.records?.length > 0;

  if (!permissions?.includes("view-application-decision-intelligence-report")) {
    return <p>You don't have permission to view this page.</p>;
  }

  return (
    <>
      <ExecutedRules decisionStatusLogs={decisionStatusLogs} />
      {hasFraudData && (
        <>
          <ApplicantSelector
            activeTab={activeTab}
            onTabClick={setActiveTab}
            applicantName={`${
              financialApplication?.applicant?.firstName || ""
            } ${financialApplication?.applicant?.lastName || ""}`}
            coApplicantName={
              financialApplication?.coApplicant &&
              `${financialApplication?.coApplicant?.firstName || ""} ${
                financialApplication?.coApplicant?.lastName || ""
              }`
            }
          />
          <IpqsDisplay
            fraudResultsData={fraudResultsData}
            applicantType={activeTab}
            enablePopoverIcons={hasLegacyEffectivData}
          />
        </>
      )}
      {/* If legacy effectiv data is present, display those results. Otherwise, display updated fraud results */}
      {hasLegacyEffectivData ? (
        <Fraud fraudData={effectivData} />
      ) : (
        fraudResultsData
          ?.filter((r) => {
            if (!r.scope) return;
            if (activeTab === "applicant") {
              return PRIMARY_APPLICANT_SCOPES.includes(r.scope);
            }
            return CO_APPLICANT_SCOPES.includes(r.scope);
          })
          .sort((a, b) => {
            const indexA = a.category
              ? DISPLAY_ORDER.indexOf(a.category.toLowerCase())
              : -1;
            const indexB = b.category
              ? DISPLAY_ORDER.indexOf(b.category.toLowerCase())
              : -1;
            return indexA - indexB;
          })
          .map((fraudResult: FinancialApplicationFraudResult) => {
            return (
              <FraudResultReport
                key={fraudResult.id}
                fraudResult={fraudResult}
              />
            );
          })
      )}
    </>
  );
};
