import React, { FC } from "react";

interface Props {
  title: string;
  sort?: "desc" | "asc";
  onClick: (direction: "desc" | "asc") => any;
}

export const TableColHeader: FC<Props> = ({ title, sort, onClick }) => {
  return (
    <th
      style={{ cursor: "pointer", fontSize: 14 }}
      scope="col"
      onClick={() => onClick(sort ? (sort === "asc" ? "desc" : "asc") : "desc")}
    >
      <div className="d-flex align-items-center">
        <span>{title}</span>
        <div className="d-flex flex-column ml-1">
          {sort === "desc" && <ion-icon name="caret-down-outline"></ion-icon>}
          {sort === "asc" && <ion-icon name="caret-up-outline"></ion-icon>}
        </div>
      </div>
    </th>
  );
};
