import React, { FC, useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Task } from "../../../models/applications/applicationInterfaces";
import "./taskViewer.scss";
import api from "../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTask,
  selectApplicationDetail,
  refreshApplication,
} from "../../../models/applications/applicationDetailSlice";
import { TaskEditModal } from "./taskEditModal";
import { selectMyUser } from "../../../models/users/myUserSlice";
import { File } from "../../file";
import { systemMessageSlice } from "../../../models/systemMessageSlice";
import { asyncTimeout, handleFileRedirect } from "../../../utils/helpers";
import { mixpanel } from "../../../utils/mixpanel";
import { LockFill } from "react-bootstrap-icons";

interface Props {
  task: Task;
  canUpload: boolean;
  canDelete: boolean;
}
export const TaskViewer: FC<Props> = ({ task, canUpload, canDelete }) => {
  const [isEditing, setEditing] = useState(false);
  const [pendingFile, setPendingFile] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const myUser = useSelector(selectMyUser);
  const { application } = useSelector(selectApplicationDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEditing && pendingFile) {
      // clear the pending file when edit is complete
      setPendingFile(null);
    }
  }, [isEditing]);

  async function editTask(data: any) {
    const res: any = await dispatch(
      updateTask({
        id: task.id,
        questions: Object.keys(data).map((k, i) => ({
          prompt: k,
          response: data[k],
          required: task.questions[i].required,
        })),
      })
    );
    if (res.error) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Error - Unable to Update Questions. Please try again.",
          type: "danger",
        })
      );
    } else {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Questions Updated Successfully!",
          type: "success",
        })
      );
    }
    if (pendingFile) {
      addArticle(pendingFile);
    }
    setEditing(false);
  }

  async function deleteArticle(id: number) {
    try {
      await api.delete(
        `/submission-tasks/${task.id.toString()}/articles/${id.toString()}`
      );
      await dispatch(refreshApplication(application?.id));

      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Document Deleted Successfully!",
          type: "success",
        })
      );
    } catch (e) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Error - Unable to delete document. Please try again.",
          type: "danger",
        })
      );
    }
  }

  async function downloadDocument(id: number) {
    try {
      const res: any = await api.post(
        `/submission-tasks/${task.id.toString()}/articles/${id.toString()}/signed-url`
      );
      handleFileRedirect(res.data.url);
    } catch (e) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Error - Unable to access document. Please try again.",
          type: "danger",
        })
      );
    }
  }
  async function addArticle(file: any) {
    mixpanel.track("Task - Start Upload", {
      "User Id": myUser?.userId,
      "Company Name": application?.organization.name,
      Task: task.id,
      "Task Title": task.title,
      "Host Name": window.location.hostname,
      Page: window.location.href,
    });
    setIsUploading(true);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("title", file.name);
    await asyncTimeout(10000);
    try {
      await api.post(
        `/submission-tasks/${task.id.toString()}/articles`,
        formData
      );
      await dispatch(refreshApplication(application?.id));
      mixpanel.track("Task - Upload Complete", {
        "User Id": myUser?.userId,
        "Company Name": application?.organization.name,
        Task: task.id,
        "Task Title": task.title,
        "Host Name": window.location.hostname,
        Page: window.location.href,
      });
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Document Uploaded Successfully!",
          type: "success",
        })
      );
    } catch (e) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Error - File Upload failed. Please try again.",
          type: "danger",
        })
      );
    }
    setIsUploading(false);
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!canUpload) return;
    const isFirstDoc = !task.articles.length;
    if (isFirstDoc) {
      // set the file as pending to be stored after the model edit
      setPendingFile(acceptedFiles[0]);
      setEditing(true);
    } else {
      addArticle(acceptedFiles[0]);
    }

    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const isComplete = task.status === "complete";
  const isOptional =
    task.allow_upload === false || task.documentRequired === false;
  return (
    <>
      <div className="row">
        <div className="col-sm-12 mb-4">
          <div className="d-flex align-items-center">
            {/* Completion Icon */}
            <div
              className={`task-count ${
                isComplete || isOptional ? "complete" : ""
              }`}
            >
              {isOptional && (
                <span
                  className={`oi ${
                    task.allow_upload === false ? "oi-file" : "oi-plus"
                  }`}
                ></span>
              )}
              {isComplete && !isOptional && (
                <span className="oi oi-check"></span>
              )}
            </div>
            {/* Task Title */}
            <p className="text-tertiary h2 mb-0" style={{ fontWeight: 750 }}>
              {task.title}
            </p>
          </div>
        </div>
        {/* File and Description Block */}
        <div className="col-sm-12 col-md-4">
          {/* List Uploaded Files (After Upload is Complete) */}
          {((task.allow_upload !== false && !!task.articles.length) ||
            isUploading) && (
            <div>
              {task.articles.map((article) => {
                return (
                  <div key={article.id} className="mb-1">
                    <File
                      article={article}
                      onDelete={() => deleteArticle(article.id)}
                      onDownload={() => downloadDocument(article.id)}
                      canDelete={canDelete}
                    />
                  </div>
                );
              })}
              {isUploading && (
                <div className="file-container d-flex justify-content-between align-items-center mb-3 px-3">
                  <span>File Uploading</span>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {/* Button for allowing additional files to be added */}
              {canUpload && !isUploading && (
                <div {...getRootProps()}>
                  <input {...getInputProps()} multiple={false} />
                  <button className="btn btn-primary btn-block mt-3">
                    <span className="oi oi-plus"></span> Add Document
                  </button>
                </div>
              )}
            </div>
          )}
          {/* "Upload File" Field - Unlocked */}
          {task.allow_upload !== false &&
            !task.articles.length &&
            !isUploading && (
              <>
                {canUpload ? (
                  <div {...getRootProps()} className="task-document-container">
                    <input {...getInputProps()} multiple={false} />

                    <div className="document-drop-container d-flex flex-column align-items-center">
                      <img
                        src="https://res.cloudinary.com/cotribute/image/upload/v1542234237/DragnDrop_vzopgl.png"
                        width={120}
                      />
                      <small>
                        <span className="font-weight-bold">
                          Drag {"&"} Drop
                        </span>{" "}
                        File Here or{" "}
                      </small>
                      <button className="btn btn-primary" disabled={!canUpload}>
                        {" "}
                        Browse Files
                      </button>
                    </div>
                  </div>
                ) : (
                  // "Upload File" Field - Locked
                  <div className="task-document-container-disabled document-drop-container d-flex flex-column align-items-center bg-light">
                    <div className="bg-primary p-3 rounded-circle my-3">
                      <LockFill size={40} className="text-white" />
                    </div>
                    <small>
                      <p className="font-weight-bold text-center mb-0">
                        File Locked
                      </p>
                      <p className="text-center">
                        You do not have permission to edit.
                      </p>
                    </small>
                  </div>
                )}
              </>
            )}
          {/* Download File Link - Used for supporting documents provided by the document template */}
          {task.link && (
            <div className="text-center" style={{ marginTop: 8 }}>
              <a href={task.link}>Download File</a>
            </div>
          )}
        </div>
        {/* Task Description */}
        <div className="col-sm-12 col-md-8 mt-3 mt-sm-0">
          {(!isComplete || task.allow_upload === false) && (
            <>
              <p className="font-weight-bold h5 mb-0 text-textGray">
                {task.tooltipTitle}
              </p>
              <p className="text-textGray f-3">{task.tooltipDescription}</p>
            </>
          )}
          {/* Add an Optional Note */}
          {isComplete &&
            task.questions &&
            task.questions.map((question, i) => {
              return (
                <div key={i}>
                  <div className="d-flex">
                    <p className="font-weight-bold mb-0 text-tertiary mt-3 mt-sm-0">
                      {question.prompt}{" "}
                    </p>
                    {canUpload && (
                      <a
                        href="javascript:void(0);"
                        className="font-weight-bold ml-1 mt-3 mt-sm-0"
                        style={{
                          borderLeft: "1px solid teal",
                          borderRight: "1px solid teal",
                          padding: "0 4px",
                          textDecoration: "underline",
                        }}
                        onClick={() => setEditing(true)}
                      >
                        EDIT
                      </a>
                    )}
                  </div>
                  <p>{question.response || "N/A"}</p>
                </div>
              );
            })}
        </div>
      </div>
      {isEditing && (
        <TaskEditModal
          onClose={() => setEditing(false)}
          onSubmit={editTask}
          task={task}
        />
      )}
    </>
  );
};
