import React, { FC, useMemo } from "react";
import { FraudGuardItem } from "../../components/FraudGuardItem";
import { ShieldFillPlus } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { selectOrg } from "../../models/organization/organizationSlice";
import { demoFraudData } from "./demoFraudData";
import { selectApplicationDetail } from "../../models/applications/applicationDetailSlice";

interface FraudProps {
  fraudData: any;
}

export interface FraudReason {
  uuid: string;
  reasonCode: string;
  category: string;
  riskLevel: string;
  possibleRiskFactors: any[];
}

export interface FraudRecord {
  person: {
    ssn: string;
    email: string;
    phone: string;
    birthday: string;
    firstName: string;
    lastName: string;
  };
  reason: FraudReason[];
}

export const Fraud: FC<FraudProps> = ({ fraudData }) => {
  // Check if this tab should be displayed in demo mode.
  const org = useSelector(selectOrg);
  const isDemo = org?.meta?.acquireFraudGuardDemoMode === true;
  // Override provided fraud data with dummy demo data if org is set to demo mode
  const fData = isDemo ? demoFraudData : fraudData;
  const application = useSelector(selectApplicationDetail);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );

  // ------------------------------------------------ RENDER ------------------------------------------------
  // Protect against users without permissions seeing admin view.
  if (!permissions?.includes("view-application-fraud-guard-report")) {
    return <p>You don't have permission to view fraud results.</p>;
  }

  // If non-demo config and no fraud data, alert that no fraud data is available.
  if (!isDemo && !fraudData?.records?.length) {
    return (
      <>
        <h1 className="text-tertiary h2 mb-4" style={{ fontWeight: 750 }}>
          <ShieldFillPlus className="mr-2" />
          FraudGuard+ Report
        </h1>
        <p className="mt-2">
          This report has not been configured for this application.
        </p>
      </>
    );
  }

  // Normal render (both demo and otherwise)
  return (
    <div className="documents-container p-5 mb-3 shadow-left">
      {/* Header */}
      <h1 className="text-tertiary h2 mb-4" style={{ fontWeight: 750 }}>
        <ShieldFillPlus className="mr-2" />
        FraudGuard+ Report
      </h1>
      {/* Risk Report */}
      {fData?.records
        ?.slice()
        .reverse()
        .map((record: FraudRecord) => {
          // Ensure that the data has a valid name before rendering
          if (!record?.person?.firstName && !record?.person?.lastName) return;
          return (
            <div
              className="mb-4"
              key={record?.person?.firstName + record?.person?.lastName}
            >
              <div className="bg-tertiary w-100 text-white p-3 rounded-lg mb-4">
                <b>
                  {record?.person?.firstName} {record?.person?.lastName}
                </b>
              </div>
              {record?.reason?.length === 0 && (
                <div className="d-flex py-2">
                  No fraud results were returned for this application.
                </div>
              )}
              {record?.reason?.map((reason: FraudReason, index: number) => {
                return (
                  <FraudGuardItem
                    key={reason.category}
                    title={reason.category}
                    riskLevel={reason.riskLevel}
                    data={reason.possibleRiskFactors}
                    className="mb-2"
                  />
                );
              })}
            </div>
          );
        })}
    </div>
  );
};
