import React, { FC, HTMLAttributes } from "react";

interface Props {
  show: boolean;
  fullscreen?: boolean;
  lockScroll?: boolean;
}

export const Spinner: FC<Props> = ({
  show,
  fullscreen = false,
  lockScroll = false,
}) => {
  if (!show) {
    if (lockScroll) {
      document.body.style.overflow = "";
    }
    return null;
  }

  if (lockScroll) {
    document.body.style.overflow = "hidden";
  }

  const styles: React.CSSProperties = fullscreen
    ? {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      }
    : {};
  return (
    <div className="table-loading" style={styles}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
