import { Placement } from "popper.js";
import React from "react";
import { OverlayTrigger } from "react-bootstrap";

interface OverlayIconProps {
  show: boolean;
  onClick: () => void;
  popover: any;
  placement?: Placement;
}

const OverlayIcon = ({
  show,
  onClick,
  popover,
  placement = "right",
}: OverlayIconProps) => {
  return (
    <OverlayTrigger
      trigger={["click"]}
      show={show}
      onToggle={onClick}
      placement={placement}
      overlay={popover}
    >
      <svg
        className="ml-1 popover-trigger-svg"
        style={{ cursor: "pointer" }}
        height="12px"
        width="12px"
        fill="#000000"
        onClick={onClick}
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 199.943 199.943"
        xmlSpace="preserve"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <g>
            <g>
              <path
                style={{ fill: "#010002" }}
                d="M99.972,0.004C44.85,0.004,0,44.847,0,99.968c0,55.125,44.847,99.972,99.972,99.972 s99.972-44.847,99.972-99.972C199.943,44.847,155.093,0.004,99.972,0.004z M99.972,190.957c-50.168,0-90.996-40.813-90.996-90.989 c0-50.172,40.828-90.992,90.996-90.992c50.175,0,91.003,40.817,91.003,90.992S150.147,190.957,99.972,190.957z"
              ></path>
              <path
                style={{ fill: "#010002" }}
                d="M99.324,67.354c-3.708,0-6.725,3.01-6.725,6.728v75.979c0,3.722,3.017,6.739,6.725,6.739 c3.722,0,6.739-3.017,6.739-6.739V74.082C106.063,70.364,103.042,67.354,99.324,67.354z"
              ></path>
              <circle
                style={{ fill: "#010002" }}
                cx="99.746"
                cy="48.697"
                r="8.178"
              ></circle>
            </g>
          </g>
        </g>
      </svg>
    </OverlayTrigger>
  );
};

export default OverlayIcon;
