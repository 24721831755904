import { IMG_BASE_URL } from "../constants";
import moment from "moment";

export const generateImageUrl = (data: {
  imageVersion: string;
  imageId: string;
}): string => {
  return `${IMG_BASE_URL}/${data.imageVersion}/${data.imageId}`;
};

export function asyncTimeout(delay: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(delay);
    }, delay);
  });
}

export const statusToTitle: { [key: string]: string } = {
  items_needed: "Items Needed from Applicant",
  approved: "Approved",
  ready_for_partner_review: "Ready for Partner Review",
  denied: "Denied",
};

export const benevolenceStatusToDocumentStatus: { [key: string]: string } = {
  items_needed: "Awaiting Documents",
  approved: "Accepted",
  ready_for_partner_review: "Review",
  denied: "Rejected",
};

export const applicationStatusForApplicant: { [key: string]: string } = {
  items_needed: "Items Needed from You",
  approved: "You Are Approved!",
  ready_for_partner_review: "In Review",
  review: "In Review",
  denied: "Unable to Approve",
};

export const articleTypeToIcon: { [key: string]: string } = {
  IMAGE:
    "https://res.cloudinary.com/cotribute/image/upload/v1540497790/File_Type_Unknown_uarhqt.png",
  POWERPOINT:
    "https://res.cloudinary.com/cotribute/image/upload/,q_60/v1540497791/PPT_tgfbgp.jpg",
  "WORD DOC":
    "https://res.cloudinary.com/cotribute/image/upload/v1540502624/Word_dvwrfi.png",
  PDF: "https://res.cloudinary.com/cotribute/image/upload/v1541797879/PDF_nb7fnj.png",
  MOVIE:
    "https://res.cloudinary.com/cotribute/image/upload/v1531499339/csg-icon_oqjofc.png",
  ARTICLE:
    "https://res.cloudinary.com/cotribute/image/upload/v1540502624/Unknown_File_Type_jj56tx.png",
  VIMEO:
    "https://res.cloudinary.com/cotribute/image/upload/v1540502624/Unknown_File_Type_jj56tx.png",
  OTHER:
    "https://res.cloudinary.com/cotribute/image/upload/v1540502624/Unknown_File_Type_jj56tx.png",
  YOUTUBE:
    "https://res.cloudinary.com/cotribute/image/upload/v1540497792/Youtube_zw89b5.png",
  KEYNOTE:
    "https://res.cloudinary.com/cotribute/image/upload/v1540502623/Keynote_qhseeo.png",
};

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatMoney(
  amount: number | string,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    const cents = Math.abs((amount as any) - (i as any))
      .toFixed(decimalCount)
      .slice(2);

    return (
      "$" +
      (negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount && cents !== "00" ? decimal + cents : ".00"))
    );
  } catch (e) {
    console.error(e);
  }
}

export function formatDate(date: string) {
  if (!date) return null;
  const momentDate = moment(date);
  const formattedDate = momentDate.format("M/D/YYYY");
  const browserTimezone = moment().format("Z"); // Get browser's timezone offset

  const formattedTimestamp = moment
    .utc(momentDate)
    .utcOffset(browserTimezone)
    .format("h:mm:ss A"); // Format timestamp in browser's timezone

  return `${formattedDate} | ${formattedTimestamp}`;
}

export function mobileCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
}

/**
 * Generate a API Url with query params appended to the end of the url.
 */
export const generateApiUrlWithQueryParams = (
  baseUrl: string,
  queryParams?: string
) => `${baseUrl}${queryParams ? `?${queryParams}` : ""}`;

/**
 * Get the full name of a state from the abbreviation.
 */
type StateAbbreviations = {
  [abbreviation: string]: string;
};
export const getFullStateName = (abbreviatedState: string) => {
  const stateAbbreviations: StateAbbreviations = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };

  return stateAbbreviations[abbreviatedState] || "Unknown";
};

export const handleFileRedirect = (url: string) => {
  // If the browser is Safari, we need to redirect the user to the file because Safari blocks pop-ups
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    window.location.href = url;
  } else {
    // For other browsers, simply open the file in a new tab
    window.open(url, "_blank", "noopener,noreferrer");
  }
};
