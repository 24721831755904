import React, { FC, useMemo } from "react";
import { User } from "../../models/users/userInterfaces";
import {
  ActivityItem,
  NotificationType,
  DecisionStatusLog,
} from "../../models/applications/applicationInterfaces";
import { orderBy } from "lodash";
import { ActivityFeedItem } from "./activityItem";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectApplicationDetail } from "../../models/applications/applicationDetailSlice";

interface Props {
  activity: ActivityItem[];
  user?: User;
  decisionStatusLogs: DecisionStatusLog[];
}
export const Activity: FC<Props> = ({ activity, user, decisionStatusLogs }) => {
  const application = useSelector(selectApplicationDetail);
  const appDetails = useMemo(() => application.application, [application]);
  let isMyApplication: boolean | undefined = undefined;
  if (user && appDetails) {
    isMyApplication = user?.userId === appDetails?.user?.userId;
  }

  if (!appDetails?.created_at) return null;

  // Permissions Note: Any user with any permissions can view the activity feed.

  return (
    <div id="activity-container">
      <div className="activity-feed">
        {orderBy(
          [
            ...activity,
            ...mapDecisionStatusLogsToActivityItems(decisionStatusLogs),
          ],
          (activity) => moment.utc(activity.timestamp).unix(),
          "desc"
        ).map((item) => (
          <ActivityFeedItem activity={item} key={item.id} />
        ))}
        {/* The first item (bottom of the list) is always "application received" */}
        <ActivityFeedItem
          activity={{
            id: 0,
            timestamp: appDetails.created_at,
            payload: {
              icon: "document-text-outline",
              notificationType: NotificationType.application_received,
            },
          }}
        />
      </div>
    </div>
  );
};

const mapDecisionStatusLogsToActivityItems = (
  decisionStatusLogs?: DecisionStatusLog[]
): ActivityItem[] => {
  if (!decisionStatusLogs) {
    return [];
  }

  return decisionStatusLogs.map((decisionStatusLog, index) => ({
    id: index,
    timestamp: decisionStatusLog.createdAt,
    fromUser: decisionStatusLog.user,
    payload: {
      notificationType:
        decisionStatusLog.decisionRule === undefined
          ? NotificationType.submission_decision_status_updated
          : NotificationType.submission_decision_status_auto_updated,
      icon: "flag-outline",
      oldDecisionStatus: decisionStatusLog.oldDecisionStatus?.title,
      newDecisionStatus: decisionStatusLog.newDecisionStatus?.title,
      decisionRuleTitle: decisionStatusLog.decisionRule?.title,
      message: decisionStatusLog.comment,
    },
  }));
};
