import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  ApplicationDetail,
  DecisionStatus,
} from "../../models/applications/applicationInterfaces";
import { useForm } from "react-hook-form";
import api from "../../utils/api";
import { SidebarApplicationDetail } from "./sidebarApplicationDetail";
import { applicationStatusForApplicant } from "../../utils/helpers";
import { getApplicationActivity } from "../../models/applications/applicationDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import { SidebarApplicationDetailOld } from "./sidebarApplicationDetailOld";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { mixpanel } from "../../utils/mixpanel";
import { selectMyUser } from "../../models/users/myUserSlice";
import { selectOrg } from "../../models/organization/organizationSlice";
import { Send } from "react-bootstrap-icons";
import {
  Flow,
  OnboardingApplication,
} from "../../models/onboardingApplication/onboardingApplicationInterfaces";

interface Props {
  application: ApplicationDetail;
  decisionStatus?: DecisionStatus;
  onboardingApplicationData: OnboardingApplication;
  flowData: Flow;
}

interface MessageInputs {
  message: string;
}

export const ApplicantSidebar: FC<Props> = ({
  application,
  decisionStatus,
  onboardingApplicationData,
  flowData,
}) => {
  const { register, formState, handleSubmit, setValue } =
    useForm<MessageInputs>({ mode: "onChange" });
  const dispatch = useDispatch();
  const myUser = useSelector(selectMyUser);
  const [currentStatus, setCurrentStatus] = useState(application.status);
  useEffect(() => {
    if (
      currentStatus === "items_needed" &&
      application.status === "ready_for_partner_review"
    ) {
      (window as any).Appcues.show("-MADkV1eddEJ5BheiNpE");
    }
    setCurrentStatus(application.status);
  }, [application.status]);
  const organization = useSelector(selectOrg);
  const status = organization?.meta?.enableDecisioning
    ? decisionStatus?.decision
    : application.status;
  const displayOnboardingApplicationResponses =
    organization?.meta?.displayOnboardingApplicationResponses;

  async function sendMessage({ message }: MessageInputs) {
    try {
      await api.post(`/submissions/${application.id}/comments`, {
        message,
      });
      mixpanel.track("Message Sent", {
        "User Id": myUser?.userId,
        "Company Name": application.organization.name,
        "Host Name": window.location.hostname,
        Page: window.location.href,
        "Message Text": message,
      });
      setValue("message", "");
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Message Has Been Sent!",
          type: "success",
        })
      );
    } catch (e) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Error - Message Failed. Please try again later.",
          type: "danger",
        })
      );
    }
    setTimeout(() => {
      dispatch(getApplicationActivity(application.id));
    }, 8000);
  }

  return (
    <div className="detail-sidebar d-flex flex-column">
      {/* Metadata for Page */}
      <Helmet>
        <title>My Application</title>
      </Helmet>
      <div className="sidebar-section">
        {/* Application Status */}
        <div className="d-flex flex-column bg-primary mt-4 px-3 py-2">
          <span className="text-uppercase h5">
            Status: {status === "approved" ? "complete" : "incomplete"}
          </span>
          <span className={"h2"} style={{ fontWeight: 800 }}>
            {status && applicationStatusForApplicant[status]}
          </span>
        </div>
        {/* Chatbox - Need Help? */}
        <div className="p-4 d-flex flex-column">
          <span className="h5 font-weight-bold mb-0">Need Help?</span>
          <span>
            <span className="font-weight-bold">Send a message to </span>
            {application.organization.name}
          </span>
          <form onSubmit={handleSubmit(sendMessage)}>
            <textarea
              ref={register({ required: true, max: 4000 })}
              cols={30}
              rows={5}
              style={{ resize: "none" }}
              className="form-control"
              name="message"
              placeholder="Type your message here"
            />
            <div className="d-flex justify-content-end mt-1">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={formState.isSubmitting || !formState.isValid}
              >
                <Send className="mr-2" />
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="sidebar-section">
        {/* Display application information either in the same format stored in the Acquire Review Step (Onboarding Application Responses), 
              or in the old method (submission question/answer). */}
        {displayOnboardingApplicationResponses ? (
          <SidebarApplicationDetail
            application={application}
            onboardingApplicationData={onboardingApplicationData}
            flowData={flowData}
          />
        ) : (
          <SidebarApplicationDetailOld
            application={application}
            onboardingApplicationData={onboardingApplicationData}
          />
        )}{" "}
      </div>
    </div>
  );
};
