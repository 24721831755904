import React from "react";
import { useRouteMatch, Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/PrivateRoute";
import { useSelector } from "react-redux";
import { selectOrg } from "../../models/organization/organizationSlice";
import { selectMyUser } from "../../models/users/myUserSlice";
import Unauthorized from "../Unauthorized";

export const AnalyticsRouter = () => {
  let { path } = useRouteMatch();
  const organization = useSelector(selectOrg);
  const user = useSelector(selectMyUser);

  // Guard against non-admins viewing this page.
  if (!user?.isAdminPortalUser) return <Unauthorized userId={user!.userId} />;

  return (
    <Switch>
      <PrivateRoute exact path={`${path}`}>
        <div className="bg-secondary" style={{ height: "93vh" }}>
          <iframe
            src={
              organization?.meta?.sigmaUrl ||
              "https://app.sigmacomputing.com/embed/23MJgugdCmC629mt6j8hx7"
            }
            frameBorder="0"
            width="100%"
            height="100%"
          />
        </div>
      </PrivateRoute>
    </Switch>
  );
};
