import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { getTokens, setTokens } from "../utils/localStorage";
import {
  getDecisionStatuses,
  getOrganizationBySlug,
} from "./organization/organizationSlice";
import { loginByRefreshToken } from "./users/myUserSlice";
import { asyncTimeout } from "../utils/helpers";

interface ApplicationStatusState {
  isInitialized: boolean;
  isAuthenticated: boolean;
}

const initialState: ApplicationStatusState = {
  isInitialized: false,
  isAuthenticated: false,
};

async function getRefreshToken(): Promise<string | null> {
  const locationSearch = new URLSearchParams(window.location.search);
  const hashToken = locationSearch.get("hash");

  if (hashToken) {
    setTokens({ auth: hashToken, refresh: hashToken });
    window.location.href = window.location.href.replace(
      `hash=${hashToken}`,
      ""
    );
    await asyncTimeout(300);
    return null;
  }

  const token = getTokens();
  if (token) {
    return token.refresh;
  }
  return null;
}

export const initializeApp = createAsyncThunk(
  "applicationStatusState/initializeApp",
  async (id: string, thunkApi): Promise<ApplicationStatusState> => {
    await thunkApi.dispatch(getOrganizationBySlug(id));

    const refreshToken = await getRefreshToken();
    if (!refreshToken) return { isAuthenticated: false, isInitialized: true };

    const res: any = await thunkApi.dispatch(
      loginByRefreshToken({ refreshToken })
    );

    if (res.error) {
      return { isAuthenticated: false, isInitialized: true };
    }

    await thunkApi.dispatch(getDecisionStatuses(id));
    return { isAuthenticated: true, isInitialized: true };
  }
);

export const applicationStatusSlice = createSlice({
  name: "applicationStatus",
  initialState,
  reducers: {
    setAuthorization(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: {
    [initializeApp.fulfilled.type]: (
      state,
      { payload }: PayloadAction<ApplicationStatusState>
    ) => {
      state.isInitialized = payload.isInitialized;
      state.isAuthenticated = payload.isAuthenticated;
    },
  },
});

export const selectApplicationStatus = (state: RootState) =>
  state.applicationStatus;

export default applicationStatusSlice.reducer;
