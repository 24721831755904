import { useDispatch } from "react-redux";
import { logout } from "../../models/users/myUserSlice";

// Render an empty page and log out use user.
const LogoutRedirectPage = () => {
  const dispatch = useDispatch();
  dispatch(logout());

  return null;
};

export default LogoutRedirectPage;
