import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useForm } from "react-hook-form";

import "./login.scss";
import { Link } from "react-router-dom";

interface Inputs {
  email: string;
}

const ForgotPasswordPage: React.FC = () => {
  const { register, errors, handleSubmit } = useForm<Inputs>();
  return (
    <div className="container">
      <div className="text-center" id="login-page">
        <h1 className="mb-4">Forgot Your Password?</h1>
        <p>We'll email you instructions on how to reset it.</p>
        <div className="login-container">
          <form onSubmit={handleSubmit((data) => {})}>
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text oi oi-person"></span>
                </div>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  ref={register({ required: true })}
                  placeholder="Email"
                />
              </div>
            </div>

            <input
              type="submit"
              className="btn btn-primary btn-block"
              value="SEND RESET EMAIL"
            />

            <div className="mt-2 mb-4">
              <Link to="/login">Go back to login</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
