import React, { FC, useState } from "react";
import { FinancialApplicationWatchlistReport } from "../../models/applications/applicationInterfaces";
import { WatchlistItem } from "../../components/watchlistItem";
import { CheckCircleFill } from "react-bootstrap-icons";

export interface WatchlistDisplayProps {
  type: FinancialApplicationWatchlistReport["type"];
  hits: FinancialApplicationWatchlistReport["hits"];
}

export const WATCHLIST_DICT = {
  ofac: "OFAC Results",
  pep: "PEP Results",
  ["negative-media"]: "Negative Media Screening Results",
};

export const WatchlistDisplay: FC<WatchlistDisplayProps> = ({ type, hits }) => {
  const [collapsed, setCollapsed] = useState(false);

  const categoryTitle =
    WATCHLIST_DICT[type as keyof typeof WATCHLIST_DICT] || type;

  const sortedResults = hits?.slice().sort((a, b) => {
    if (a.scorePercent === undefined && b.scorePercent === undefined) {
      return 0; // Both scores are undefined, keep their order
    }
    if (a.scorePercent === undefined) {
      return 1; // a's score is undefined, put b before a
    }
    if (b.scorePercent === undefined) {
      return -1; // b's score is undefined, put a before b
    }
    return b.scorePercent - a.scorePercent; // Both scores are defined, sort normally
  });

  return (
    <>
      <div
        className={`text-white bg-tertiary p-3 ${collapsed ? `mb-4` : `mb-0`}`}
        style={{
          borderRadius: collapsed ? "8px" : "8px 8px 0 0",
          cursor: "pointer",
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <div
          className="h5 mb-0 flex row justify-content-between pl-3 pr-3 align-items-center"
          style={{}}
        >
          <div>{categoryTitle}</div>
          {/* Expand/Collapse Caret  */}
          <div
            style={{
              transform: collapsed ? "scaleY(-1)" : "none",
            }}
            className={` ${collapsed ? "collapsed" : ""}`}
          >
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#ffffff"
              transform="matrix(1, 0, 0, -1, 0, 0)"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g id="Arrow / Caret_Down_MD">
                  {" "}
                  <path
                    id="Vector"
                    d="M16 10L12 14L8 10"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                </g>{" "}
              </g>
            </svg>
          </div>
        </div>
      </div>
      {!collapsed && (
        <div
          className="bg-white mb-4 border p-4"
          style={{
            borderColor: "#ededed",
            borderRadius: "0 0 8px 8px",
          }}
        >
          {!sortedResults?.length && (
            <>
              <CheckCircleFill className="text-success ml-3 mr-2" /> No hits
              found.
            </>
          )}
          {sortedResults?.map((result) => {
            return (
              <WatchlistItem
                key={result.id}
                description={result.description}
                scorePercent={result.scorePercent}
                data={result.data}
                className="mb-2"
              />
            );
          })}
        </div>
      )}
    </>
  );
};
