import { useRouteMatch, Switch } from "react-router-dom";
import React from "react";
import { PrivateRoute } from "../../components/PrivateRoute";
import { ApplicationDashboard } from "./dashboard";
import { ApplicationDetail } from "./detail";

export const ApplicationRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={`${path}`}>
        <ApplicationDashboard />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:applicationId`}>
        <ApplicationDetail />
      </PrivateRoute>
    </Switch>
  );
};
