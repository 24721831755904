import React, { FC, ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";

interface Props {
  onClose: () => any;
  cta: ReactElement;
  title: string;
  show: boolean;
}
export const ConfirmationModal: FC<Props> = ({
  onClose,
  cta,
  title,
  children,
  show,
}) => {
  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        {cta}
      </Modal.Footer>
    </Modal>
  );
};
