import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRequestStatus } from '../models/asyncRequestStatusReducer';
import { selectApplicationStatus } from '../models/applicationStatusSlice';
import  { selectSystemMessage, systemMessageSlice } from '../models/systemMessageSlice';

interface Props {
    message?: string;
    type: "danger" | 'success' | "info"
    onClear: () => any;
}
export const FlashMessage: FC<Props> = ({message, type, onClear}) => {
    const [alert, setAlert] = useState(undefined as undefined | string)
    let timeout: any;
    useEffect(() => {
        if(timeout) {
            clearTimeout(timeout);
            onClear()
        }
        setAlert(message);
        timeout = setTimeout(() => {
            setAlert(undefined);
            onClear()
        }, 7000)
    }, [message])

    useEffect(() => {
        return () => {
            if(timeout) {
                clearTimeout(timeout)
                onClear()
            }
        }
    }, [])

    if(!alert) return <></>
    return <div className={`alert alert-${type} d-flex justify-content-between align-items-center`} role="alert">
    {message}
    <a href="javascript:void(0);" style={{color: "inherit", fontSize: 12}} onClick={() => setAlert(undefined)}><span className="oi oi-x"></span></a>
  </div>
}

FlashMessage.defaultProps = {
    onClear: () => {}
}


export const GlobalFlashMessage: FC = () => {
    const systemMessage = useSelector(selectSystemMessage);
    const dispatch = useDispatch();
    return (
        <FlashMessage message={systemMessage.message} type={systemMessage.type || 'info'} onClear={() => dispatch(systemMessageSlice.actions.clearMessage())}></FlashMessage>    
    )
} 