import "./login.scss";
import api from "../../utils/api";
import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { mixpanel } from "../../utils/mixpanel";
import { useSelector } from "react-redux";
import { selectMyUser } from "../../models/users/myUserSlice";

const ResetPasswordPage = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Track page view
  useEffect(() => {
    mixpanel.init("fef4ba7ec4cd83588a240b7427c000d7");
    mixpanel.track("Page View - Reset Password", {
      "Host Name": window.location.hostname,
      Page: window.location.href,
    });
  }, []);

  const user = useSelector(selectMyUser);
  const email = user?.email;

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSuccess(false);

    // Validate new password format
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setError(
        "New password must contain at least 8 characters with at least one uppercase letter, one lowercase letter, one special character, and one number"
      );
      return;
    }

    try {
      // Encode current and new passcodes in the format expected: "password|splitEmail" (email without the @email.com)
      const encodedCurrentPassword = btoa(
        `${currentPassword}|${email?.split("@"[0])}`
      );
      const encodedNewPassword = btoa(`${newPassword}|${email?.split("@"[0])}`);
      // Call endpoint to reset password
      await api.post("/auth/reset-password", {
        email,
        currentPassword: encodedCurrentPassword,
        newPassword: encodedNewPassword,
      });
      // Reset form and clear any errors
      setCurrentPassword("");
      setNewPassword("");
      setError("");
      setSuccess(true);
    } catch (error) {
      // Display error message if there is a problem with the request
      console.error(error);
      setNewPassword("");
      setError("There was an error resetting your password. Please try again.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <div className="p-5">
        {/* Display errors if there are any */}
        {error && <Alert variant="danger">{error}</Alert>}
        {/* Display success message if password was successfully changed */}
        {success && (
          <Alert variant="success">Password successfully changed!</Alert>
        )}
        {/* Title */}
        <h1 className="text-primary font-weight-bold">Reset Your Password</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formCurrentPassword">
            <Form.Label className="font-weight-bold">
              Current password
            </Form.Label>
            <Form.Control
              type="password"
              className="w-100 w-lg-auto col-md-5"
              placeholder="Current password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formNewPassword">
            <Form.Label className="font-weight-bold">New password</Form.Label>
            <Form.Control
              type="password"
              className="w-100 w-lg-auto col-md-5"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
};

export default ResetPasswordPage;
