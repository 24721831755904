import React, { FC } from "react";
import "./avatar.scss";

interface Props {
  imgUrl?: string | null;
  firstName: string;
  lastName: string;
}

const Avatar: FC<Props> = ({ imgUrl, firstName, lastName }) => {
  return (
    <div className="avatar-container">
      {imgUrl && <img src={imgUrl} className="avatar-image" />}
      {!imgUrl && (
        <div className="initials">
          <span>
            {firstName.charAt(0)}
            {lastName.charAt(0)}
          </span>
        </div>
      )}
    </div>
  );
};

export default Avatar;
