import React from "react";
import "./header.scss";
import Avatar from "../avatar";
import Navigation from "../navigation";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../models/users/myUserSlice";
import { MyUser } from "../../models/users/userInterfaces";
import { useRouteMatch, Link } from "react-router-dom";

interface Props {
  user?: MyUser | null;
  bannerImg: string;
}

const Header: React.FC<Props> = ({ user, bannerImg }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  return (
    <nav
      className="d-flex justify-content-between bg-light px-3"
      style={{
        minHeight: 80,
        height: "7vh",
        borderBottom: "1px solid #ededed",
      }}>
      {/* <a className="navbar-brand" href="#"> */}
      <img
        src={bannerImg}
        height="40px"
        className="d-inline-block align-self-center"
        alt=""
      />
      {/* </a> */}
      {user && (
        <div className="ui">
          <Navigation />
          <div
            className={`dropdown user-avatar px-2 ${
              !user.isAdminPortalUser && "bg-white"
            }`}>
            <div
              className="dropdown-toggle"
              id="avatar-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ cursor: "pointer" }}>
              <Avatar
                firstName={user.firstName}
                lastName={user.lastName}
                imgUrl={user.imgUrl}
              />
            </div>
            <div
              className="border-highlight dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  dispatch(logout());
                }}>
                Log Out
              </a>
              {user.isAdminPortalUser && (
                <Link className="dropdown-item" to={`${url}/reset-password`}>
                  Reset Password
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;
