import React, { useCallback, useEffect, useState } from "react";

import "./application.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplications,
  selectApplications,
} from "../../models/applications/applicationsSlice";
import { useRequestStatus } from "../../models/asyncRequestStatusReducer";
import { useRouteMatch, useHistory } from "react-router-dom";
import { selectOrg } from "../../models/organization/organizationSlice";
import {
  statusToTitle,
  formatMoney,
  formatDate,
  asyncTimeout,
  benevolenceStatusToDocumentStatus,
} from "../../utils/helpers";
import {
  Application,
  SortOptions,
} from "../../models/applications/applicationInterfaces";
import { TableColHeader } from "../../components/tableColHeader";
import { selectMyUser } from "../../models/users/myUserSlice";
import { GlobalFlashMessage } from "../../components/flashMessage";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { Spinner } from "../../components/spinner";
import Pagination from "../../components/pagination/pagination";
import Unauthorized from "../Unauthorized";
import { capitalizeFirstLetter } from "../../utils/formatting";
import { size } from "lodash";

export const ApplicationDashboard = () => {
  const [activeFilter, setActiveFilter] = useState<SortOptions>({
    assignedTo: undefined,
    status: undefined,
    decisionStatus: undefined,
    includeArchived: false,
    offset: 0,
  });

  // Reload dashboard when filter changes
  useEffect(() => {
    fetchApplications(activeFilter);
  }, [activeFilter]);

  const dispatch = useDispatch();
  let { path } = useRouteMatch();
  let history = useHistory();
  const organization = useSelector(selectOrg);
  const user = useSelector(selectMyUser);

  const headerCols = [
    { title: "Application #", value: "id" },
    {
      title: "Assigned To",
      value: "assigned_to",
    },
    { title: "First Name", value: "first_name" },
    { title: "Last Name", value: "last_name" },
    {
      title: `${"Workflow"}`,
      value: "category",
    },
    { title: "Request Amount", value: "amount_requested" },
    { title: "Received", value: "created_at" },
    { title: "Last Activity", value: "last_activity" },
    { title: "Status", value: "status" },
    { title: "Status", value: "decision_status" },
  ];

  const fetchApplications = useCallback((sortOptions?: SortOptions) => {
    return dispatch(getApplications(sortOptions || {}));
  }, []);

  async function fetchDataOnLoad() {
    await fetchApplications({});
    await asyncTimeout(300);
  }
  useEffect(() => {
    fetchDataOnLoad();
  }, []);

  const [isLoading] = useRequestStatus(getApplications.typePrefix);
  const { submissions, totalRecords, options } =
    useSelector(selectApplications);
  const myUser = useSelector(selectMyUser);
  const [search, setSearch] = useState(options.search);
  let filter = "";

  if (options.assignedTo) filter = "assignedToMe";
  if (options.status) filter = options.status;
  if (options.decisionStatus) filter = options.decisionStatus;
  function hasRecentActivity(sub: Application) {
    const lastViewed = sub.lastViewed ? new Date(sub.lastActivity) : null;
    const lastActivity = sub.lastActivity ? new Date(sub.lastActivity) : null;
    if (!lastActivity) return false;
    if (!lastViewed) return true;
    if (lastActivity.toUTCString() > lastViewed.toUTCString()) return true;
    return false;
  }

  const getAmountRequested = (sub: any) => {
    // If an amount requested is not provided, return dashes
    if (!sub) return "--------";
    const formattedRequest = formatMoney(sub.amountRequested);
    // If the amount requested is zero, return dashes
    if (formattedRequest === "$0.00") return "--------";
    // Else return the formatted amount
    return formattedRequest;
  };
  if (!user) {
    return null;
  }

  return (
    <div id="application-dashboard" className="container-fluid">
      {/* "View" Dropdown - filters dashboard */}
      <div className="application-table-control-container row justify-content-between">
        <div className="col-4">
          <div className="form-group row control-filter align-items-center">
            <label className="form-check-label px-3">View</label>
            <select
              className="custom-select col"
              style={{ cursor: "pointer" }}
              value={filter}
              onChange={(e) => {
                const value = e.target.value;
                if (!value) {
                  setActiveFilter((prevFilter) => {
                    return {
                      ...prevFilter,
                      assignedTo: undefined,
                      status: undefined,
                      decisionStatus: undefined,
                      offset: 0,
                    };
                  });
                  return;
                }
                if (value === "assignedToMe") {
                  setActiveFilter((prevFilter) => {
                    return {
                      ...prevFilter,
                      assignedTo: myUser?.userId,
                      status: undefined,
                      decisionStatus: undefined,
                      offset: 0,
                    };
                  });
                  return;
                }

                setActiveFilter((prevFilter) => {
                  return {
                    ...prevFilter,
                    assignedTo: undefined,
                    ...(organization.meta?.enableDecisioning === true
                      ? { status: undefined, decisionStatus: value }
                      : {
                          status: value,
                          decisionStatus: undefined,
                        }),
                    offset: 0,
                  };
                });
              }}
            >
              <option value="">All</option>
              <option value="assignedToMe">Assigned to Me</option>
              {!organization.meta?.enableDecisioning &&
                Object.keys(statusToTitle).map((status) => {
                  return (
                    <option value={status} key={status}>
                      {statusToTitle[status]}
                    </option>
                  );
                })}
              {organization.meta?.enableDecisioning === true &&
                (organization.decisionStatuses || []).map((status) => {
                  return (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  );
                })}
            </select>
            <div className="ml-4 form-check">
              <input
                className={`form-check-input`}
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={activeFilter.includeArchived}
                onChange={() =>
                  setActiveFilter((prevFilter) => ({
                    ...prevFilter,
                    includeArchived: !prevFilter.includeArchived,
                  }))
                }
              />
              <label className="form-check-label">Include Archived</label>
            </div>
          </div>
        </div>
        {/* Search Bar */}
        <div className="col-4">
          <div className="d-flex justify-content-end">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  fetchApplications({ search: e.target.value, offset: 0 });
                }}
              />
            </div>
            {/* {!myUser?.roles.benevolenceCompanyAdmin && (
              <button
                className="btn btn-primary"
                onClick={async () => {
                  try {
                    await api.post(
                      `/reports/organizations/${organization.id}/submissions/download`
                    );
                    dispatch(
                      systemMessageSlice.actions.setMessage({
                        message: ` Please Check ${myUser?.email} in the Next 15 minutes For your Exported File!`,
                        type: "success",
                      })
                    );
                  } catch (e) {
                    dispatch(
                      systemMessageSlice.actions.setMessage({
                        message:
                          "Error - Applications Export Failed. Please try again later.",
                        type: "danger",
                      })
                    );
                  }
                }}
              >
                Export CSV
              </button>
            )} */}
          </div>
        </div>
      </div>
      <GlobalFlashMessage />
      <div
        className="application-table"
        style={{ position: "relative", minHeight: 600 }}
      >
        <Spinner show={isLoading && !submissions.length} />

        {/* Dashboard Table */}
        {submissions.length > 0 ? (
          <>
            <table className="table table-borderless table-striped table-hover">
              <thead className="thead-dark">
                <tr>
                  <th scope="col"></th>
                  {headerCols
                    .filter(
                      ({ value: v }) =>
                        v !==
                        (organization.meta?.enableDecisioning === true
                          ? "status"
                          : "decision_status")
                    )
                    .map((h) => (
                      <TableColHeader
                        title={h.title}
                        key={h.value}
                        sort={
                          options.sort === h.value
                            ? options.direction
                            : undefined
                        }
                        onClick={(direction: "asc" | "desc") => {
                          fetchApplications({
                            sort: h.value,
                            direction: direction,
                          });
                        }}
                      />
                    ))}
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {submissions.map((sub) => {
                  return (
                    <tr
                      key={sub.id}
                      onClick={() => {
                        history.push(`applications/${sub.id}/activity`);
                      }}
                    >
                      <td className="new-cta">
                        {hasRecentActivity(sub) && (
                          <span className="oi oi-media-record"></span>
                        )}
                      </td>
                      <td>{sub.id}</td>
                      <td>
                        {sub.benevolenceAdmin.firstName +
                          " " +
                          sub.benevolenceAdmin.lastName}
                      </td>
                      <td>{capitalizeFirstLetter(sub.user.firstName)}</td>
                      <td>{capitalizeFirstLetter(sub.user.lastName)}</td>
                      <td>{capitalizeFirstLetter(sub.category)}</td>
                      <td>{getAmountRequested(sub)}</td>
                      {/* TODO: Fix backend so that this can be createdAt */}
                      <td>{formatDate(sub.created_at!) || ""}</td>
                      <td>{formatDate(sub.lastActivity) || "N/A"}</td>
                      {/* For organizations with decisioning turned on (default), use the decision status table to reflect submission status */}
                      {organization.meta?.enableDecisioning === true ? (
                        <>
                          <td
                            onClick={() => {
                              history.push(`applications/${sub.id}/activity`);
                            }}
                          >
                            <div>{sub.decisionStatus?.title || "-"}</div>
                            {sub.archived && (
                              <div
                                className="text-primary text-uppercase"
                                style={{ fontSize: "8px" }}
                              >
                                Archived
                              </div>
                            )}
                          </td>
                        </>
                      ) : (
                        /* For legacy clients, use the document status table to reflect submission status */
                        <td
                          onClick={() => {
                            history.push(`applications/${sub.id}/activity`);
                          }}
                        >
                          {sub.archived && (
                            <div
                              className="text-primary text-uppercase"
                              style={{ fontSize: "8px" }}
                            >
                              Archived
                            </div>
                          )}

                          <div>{statusToTitle[sub.status]}</div>
                        </td>
                      )}{" "}
                      <td className="cta-arrow">
                        <span className="oi oi-chevron-right"></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Footer */}
            <div className="d-flex justify-content-between px-5 pt-3 align-items-center">
              <Pagination
                sortOptions={options}
                loading={isLoading}
                totalRecords={totalRecords}
                fetch={fetchApplications}
              />
            </div>
          </>
        ) : (
          !isLoading && (
            <p className="d-block text-center mt-5 h5 font-italic text-muted">
              No entries found
            </p>
          )
        )}
      </div>
    </div>
  );
};
