import React, { FC } from "react";

interface CaretProps {
  collapsed: boolean;
  onClick: () => void;
}

const VerticalCaret: FC<CaretProps> = ({ collapsed, onClick }) => {
  return (
    <div
      style={{
        transform: collapsed ? "scaleY(-1)" : "none",
        cursor: "pointer",
      }}
      className={`text-white bg-tertiary`}
      onClick={onClick}
    >
      <svg width="30px" height="30px" viewBox="0 0 24 24">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <g id="Arrow / Caret_Down_MD">
            {" "}
            <path
              id="Vector"
              d="M16 10L12 14L8 10"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default VerticalCaret;
