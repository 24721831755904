import React, { FC, useMemo } from "react";
import { ShieldFillCheck } from "react-bootstrap-icons";
import "./gkyc.scss";
import { useSelector } from "react-redux";
import { selectApplicationDetail } from "../../models/applications/applicationDetailSlice";
import { HtmlRenderer } from "../../components/htmlRenderer";

interface FraudProps {
  fisGkycEvaluations: any;
}

export interface FraudRecord {
  uuid: string;
  person?: {
    firstName: string;
    lastName: string;
  };
  data?: {
    businessDetails?: {
      busInq?: {
        busNme?: string;
      };
    };
  };
  htmlSummary: string;
  productEvaluations: Array<{
    product?: string;
    details?: {
      txtReport?: string;
    };
  }>;
}

export const GlobalKYC: FC<FraudProps> = ({ fisGkycEvaluations }) => {
  const application = useSelector(selectApplicationDetail);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );
  // Guard against users seeing admin view.
  if (!permissions?.includes("view-application-fis-gkyc-report")) {
    return <p>You don't have permission to view this page.</p>;
  }

  // If non-demo config and no fraud data, alert that no fraud data is available.
  if (!fisGkycEvaluations?.records?.length) {
    return (
      <>
        <h1 className="text-tertiary h2 mb-4" style={{ fontWeight: 750 }}>
          <ShieldFillCheck className="mr-2" />
          Global KYC Report
        </h1>
        <p className="mt-2">
          No FIS Global KYC data was provided for this application.
        </p>
      </>
    );
  }

  // Normal render (both demo and otherwise)
  return (
    <div className="documents-container p-5 mb-3">
      {/* Header */}
      <h1 className="text-tertiary h2 mb-4" style={{ fontWeight: 750 }}>
        <ShieldFillCheck className="mr-2" />
        FIS Global KYC Report
      </h1>
      {/* Risk Report */}
      {fisGkycEvaluations &&
        fisGkycEvaluations?.records?.map((record: FraudRecord) => {
          const txtReports: Array<string> = [];
          (record?.productEvaluations || []).forEach((productEvaluation) => {
            if (!productEvaluation?.details?.txtReport) {
              return;
            }
            if (/BizChex/i.test(productEvaluation?.product || "")) {
              txtReports.push(productEvaluation.details.txtReport);
            }
          });

          return (
            <div className="mb-4" key={record?.uuid}>
              <div className="bg-tertiary w-100 text-white p-3 rounded-lg mb-4">
                <b>
                  {record?.person
                    ? `${record.person.firstName} ${record.person.lastName}`
                    : record?.data?.businessDetails?.busInq?.busNme}
                </b>
              </div>
              <p dangerouslySetInnerHTML={{ __html: record?.htmlSummary }} />
              {
                // eslint-disable-next-line react/no-danger
                txtReports.map((txtReport) => (
                  <div style={{ paddingTop: "30px" }}>
                    <HtmlRenderer htmlString={txtReport} />
                  </div>
                ))
              }
            </div>
          );
        })}
    </div>
  );
};
