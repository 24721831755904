import React, { CSSProperties, FC, useState, useMemo } from "react";
import "./fraudGuard.scss";
import {
  CaretDownFill,
  CaretUpFill,
  CircleFill,
  PersonFill,
  PcDisplay,
  RouterFill,
  EnvelopeFill,
  TelephoneFill,
  Speedometer,
  HouseFill,
  FlagFill,
  Flag,
  PersonBadgeFill,
  CheckCircleFill,
  CaretUp,
  CaretDown,
} from "react-bootstrap-icons";

export interface FraudGuardItemProps {
  title: string;
  riskLevel: "no-risk" | "low" | "medium" | "high" | string;
  data: string[];
  className?: string;
}

export const FraudGuardItem: FC<FraudGuardItemProps> = ({
  title,
  riskLevel,
  data,
  className,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  function splitCamelCase(str: string) {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(" ")
      .join(" ");
  }

  function capitalizeFirstLetter(str: string) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  }

  const accentColor = useMemo(() => {
    switch (riskLevel) {
      case "no-risk":
        return "#949da5";
      case "low":
        return "#41be1f";
      case "medium":
        return "#e2832c";
      case "high":
        return "#eb2835";
      default:
        return "#949da5";
    }
  }, [riskLevel]);

  const bgColor = useMemo(() => {
    switch (riskLevel) {
      case "no-risk":
        return "#eceff1";
      case "low":
        return "#cde6c5";
      case "medium":
        return "#f4ccad";
      case "high":
        return "#fdd5d6";
      default:
        return "#eceff1";
    }
  }, [riskLevel]);

  const riskLevelDisplay = useMemo(() => {
    switch (riskLevel) {
      case "no-risk":
        return "No Risk";
      case "low":
        return "Low";
      case "medium":
        return "Medium";
      case "high":
        return "High";
      default:
        return "None";
    }
  }, [riskLevel]);

  const iconSyling = {
    color: "#f6f6f6",
    backgroundColor: accentColor,
    height: "30px",
    width: "30px",
    padding: "5px",
    borderRadius: "50%",
  };

  const renderIcon = (title: string, accentColor: string) => {
    switch (true) {
      case title.toLowerCase().includes("idv"):
        return <PersonFill style={iconSyling} />;
      case title.toLowerCase().includes("ip"):
        return <RouterFill style={iconSyling} />;
      case title.toLowerCase().includes("device"):
        return <PcDisplay style={iconSyling} />;
      case title.toLowerCase().includes("email"):
        return <EnvelopeFill style={iconSyling} />;
      case title.toLowerCase().includes("phone"):
        return <TelephoneFill style={iconSyling} />;
      case title.toLowerCase().includes("address"):
        return <HouseFill style={iconSyling} />;
      case title.toLowerCase().includes("identity"):
        return <PersonFill style={iconSyling} />;
      case title.toLowerCase().includes("ofac"):
        return <FlagFill style={iconSyling} />;
      case title.toLowerCase().includes("nonofacsanctions"):
        return <Flag style={iconSyling} />;
      case title.toLowerCase().includes("ssn"):
        return <PersonBadgeFill style={iconSyling} />;
      case title.toLowerCase().includes("velocity"):
        return <Speedometer style={iconSyling} />;
      default:
        return <CircleFill style={iconSyling} />;
    }
  };

  // If missing necessary data, render nothing.
  if (!title || !riskLevel) {
    return <></>;
  }

  // RENDER ---------------------------------
  return (
    <div
      className={`${className} fg_item`}
      style={{
        cursor: "pointer",
      }}
      onClick={() => setShowDetails(!showDetails)}>
      <div className="d-flex">
        {/* Icon with accentColor border */}
        <div className="px-2">{renderIcon(title, accentColor)}</div>

        {/* Title and Subtitle */}
        <div className="d-flex flex-column w-100 align-self-center">
          <div className="d-flex ">
            <p className=" mb-0 font-weight-bold" style={{ fontSize: "14px" }}>
              {title}
            </p>
            <div
              className="pr-1 pl-1 ml-3 align-self-center font-weight-bold"
              style={{
                border: `solid 1px ${accentColor}`,
                borderRadius: "20% / 50%",
                backgroundColor: bgColor,
                color: accentColor,
                fontSize: "12px",
              }}>
              {/* A "low risk" in our current system actually means that the item is verified and not a risk factor. */}
              <>{riskLevelDisplay}</>
            </div>
          </div>
        </div>
        {/* Expand/Collapse Caret */}
        <div className="align-self-center">
          {/* If there are any risk factors, allow a subpanel to be toggled */}
          {showDetails ? (
            <CaretUp onClick={() => setShowDetails(false)} />
          ) : (
            <CaretDown onClick={() => setShowDetails(true)} />
          )}
        </div>
      </div>
      {/* Expandable details view */}
      {showDetails && (
        <div
          className="d-flex flex-column ml-4 mt-4"
          style={{ borderTop: "solid lightgray 1px" }}>
          <small>
            <p className="mb-0 mt-2">
              <b>How was this score generated?</b>
            </p>
          </small>
          <small>
            <p className="">
              These scores can be triggered by any of the events
              outlined below:
            </p>{" "}
          </small>
          {data.length === 0 && (
            <small>
              There are no events to be concerned about for this score.
            </small>
          )}
          <ul>
            {data.map((item) => {
              return (
                <small>
                  <li className="m-0">{item}</li>
                </small>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
