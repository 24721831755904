import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { ConfirmationModal } from "../../components/confirmationModal";
import { useSelector, useDispatch } from "react-redux";
import {
  selectApplicationDetail,
  changeDecisionStatus,
  denyApplication,
} from "../../models/applications/applicationDetailSlice";
import { systemMessageSlice } from "../../models/systemMessageSlice";
interface Props {
  show: boolean;
  onClose: () => any;
  newStatusUuid?: string | null;
}
export const DenyModal: FC<Props> = ({ show, onClose, newStatusUuid }) => {
  const { register, handleSubmit, formState } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const { application } = useSelector(selectApplicationDetail);

  // Denies the appliation using the new decision status (if present) or the legacy document status (if not present)
  async function deny(data: any) {
    // Deny using decision status - Identified by the presence of newStatusUuid
    if (newStatusUuid) {
      const res: any = await dispatch(
        changeDecisionStatus({
          newDecisionStatusUuid: newStatusUuid,
          denialReason: data.message,
        })
      );
      if (res.error) {
        dispatch(
          systemMessageSlice.actions.setMessage({
            message: "Error - Change Decision Failed. Please try again.",
            type: "danger",
          })
        );
      } else {
        dispatch(
          systemMessageSlice.actions.setMessage({
            message: "Decision Changed Successfully!",
            type: "success",
          })
        );
      }
    } else {
      // Deny using document status (legacy) - Identified by the lack of newStatusUuid
      const res: any = await dispatch(
        denyApplication({
          id: application?.id as number,
          message: data.message,
        })
      );
      if (res.error) {
        dispatch(
          systemMessageSlice.actions.setMessage({
            message:
              "Error - Application Status Update Failed. Please try again later.",
            type: "danger",
          })
        );
      } else {
        dispatch(
          systemMessageSlice.actions.setMessage({
            message: "Application Status Has Been Updated!",
            type: "success",
          })
        );
      }
    }
    // Close the modal
    onClose();
  }

  return show ? (
    <ConfirmationModal
      show={show}
      title="Confirm Application Denial"
      onClose={onClose}
      cta={
        <button
          disabled={formState.isSubmitted || !formState.isValid}
          onClick={handleSubmit(deny)}
          className="btn btn-primary"
        >
          Confirm Denial
        </button>
      }
    >
      <div className="form-group">
        <label className="font-weight-bold">
          What is the reason for rejection? {newStatusUuid && "(Optional)"}
        </label>
        <textarea
          cols={5}
          className="form-control"
          ref={register({ required: !newStatusUuid })} // Legacy clients are required to provide a reason, but this is optional going forward. If no message is provided, no resque will be enqued.
          name="message"
          placeholder={`${
            newStatusUuid ? "Optionally provide" : "Provide"
          } a reason for rejection`}
        />
      </div>
    </ConfirmationModal>
  ) : (
    <></>
  );
};
