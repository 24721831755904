import React, { FC, useState } from "react";
import { FinancialApplicationFraudResult } from "../../models/applications/applicationInterfaces";
import { Popover } from "react-bootstrap";
import OverlayIcon from "../../components/ui/overlayIcon";
interface IpqsProps {
  fraudResultsData?: FinancialApplicationFraudResult[];
  applicantType?: string; // applicant or coapplicant
  enablePopoverIcons?: boolean;
}
export const IpqsDisplay: FC<IpqsProps> = ({
  fraudResultsData,
  applicantType,
  enablePopoverIcons = true,
}) => {
  //Risk Level Color
  const getRiskLevelColor = (riskLevel?: string) => {
    switch (riskLevel) {
      case "low":
        return "#41be1f";
      case "medium":
        return "#e2832c";
      case "high":
        return "#eb2835";
      case "none":
        return "#a2b0bc";
      default:
        return "#a2b0bc";
    }
  };
  //Risk Level Capitalization
  const getRiskLevelDisplay = (riskLevel?: string) => {
    switch (riskLevel) {
      case "low":
        return "Low";
      case "medium":
        return "Medium";
      case "high":
        return "High";
      case "none":
        return "None";
      default:
        return "_";
    }
  };
  //Risk Score Color
  const getRiskScoreColor = (riskScore?: number) => {
    if (riskScore !== undefined && !isNaN(riskScore)) {
      if (riskScore >= 0 && riskScore <= 40) {
        return "#41be1f";
      } else if (riskScore >= 41 && riskScore <= 84) {
        return "#e2832c";
      } else if (riskScore >= 85 && riskScore <= 100) {
        return "#eb2835";
      }
    }
    return "#a2b0bc"; // Default color if riskScore is undefined or not a number
  };
  //Risk or Info Background Color
  const getRiskBgColor = (riskLevel?: string) => {
    switch (riskLevel) {
      case "risk":
        return "#fdd5d6";
      default:
        return "#eceff1";
    }
  };
  const getRiskFontColor = (riskLevel?: string) => {
    switch (riskLevel) {
      case "risk":
        return "#eb2835";
      default:
        return "#949da5";
    }
  };

  // Popover Displays
  const [showEmailPopover, setShowEmailPopover] = useState(false);
  const [showPhonePopover, setShowPhonePopover] = useState(false);
  const [showAddressPopover, setShowAddressPopover] = useState(false);
  const [showIdPopover, setShowIdPopover] = useState(false);
  const [showFraudPopover, setShowFraudPopover] = useState(false);

  const fraudPopover = (
    <Popover id="fraud-risk">
      <Popover.Content>
        <div>
          <div className="h6">Fraud Risk</div>
          <div>This was triggered by the following:</div>
          {applicantType === "applicant" ? (
            <div>
              {fraudResultsData &&
                fraudResultsData
                  .filter(
                    (result) =>
                      result.category === "fraud" &&
                      result.scope === "applicant"
                  )
                  .map((result) =>
                    result?.fraudReasons?.map((fraudReason, index) => (
                      <div
                        key={index}
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div
                          className="pr-1 pl-1 mr-2"
                          style={{
                            backgroundColor: getRiskBgColor(
                              fraudReason.riskLevel
                            ),
                            color: getRiskFontColor(fraudReason.riskLevel),
                            borderRadius: "20% / 50%",
                            height: "20px",
                          }}
                        >
                          {fraudReason.riskLevel}
                        </div>
                        <div style={{ width: "75%" }}>
                          {fraudReason.message}
                        </div>
                      </div>
                    ))
                  )}
            </div>
          ) : (
            <div>
              {fraudResultsData &&
                fraudResultsData
                  .filter(
                    (result) =>
                      result.category === "fraud" &&
                      result.scope === "co-applicant"
                  )
                  .map((result) =>
                    result?.fraudReasons?.map((fraudReason, index) => (
                      <div
                        key={index}
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div
                          className="pr-1 pl-1 mr-2"
                          style={{
                            backgroundColor: getRiskBgColor(
                              fraudReason.riskLevel
                            ),
                            color: getRiskFontColor(fraudReason.riskLevel),
                            borderRadius: "20% / 50%",
                            height: "20px",
                          }}
                        >
                          {fraudReason.riskLevel}
                        </div>
                        <div style={{ width: "75%" }}>
                          {fraudReason.message}
                        </div>
                      </div>
                    ))
                  )}
            </div>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  const devicePopover = (
    <Popover id="phone-risk">
      <Popover.Content>
        <div>
          <div className="h6">Device Risk</div>
          <div>This was triggered by the following:</div>
          <div>
            {fraudResultsData &&
              fraudResultsData
                .filter(
                  (result) =>
                    result.category === "device" &&
                    result.scope === "application"
                )
                .map((result) =>
                  result?.fraudReasons?.map((fraudReason, index) => (
                    <div
                      key={index}
                      className="pl-2"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div
                        className="pr-1 pl-1 mr-2"
                        style={{
                          backgroundColor: getRiskBgColor(
                            fraudReason.riskLevel
                          ),
                          color: getRiskFontColor(fraudReason.riskLevel),
                          borderRadius: "20% / 50%",
                          height: "20px",
                        }}
                      >
                        {fraudReason.riskLevel}
                      </div>
                      <div style={{ width: "75%" }}>{fraudReason.message}</div>
                    </div>
                  ))
                )}
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  const emailPopover = (
    <Popover id="address-risk">
      <Popover.Content>
        <div>
          <div className="h6">Email Risk</div>
          <div>This was triggered by the following:</div>
          {applicantType === "applicant" ? (
            <div>
              {fraudResultsData &&
                fraudResultsData
                  .filter(
                    (result) =>
                      result.category === "email" &&
                      result.scope === "applicant"
                  )
                  .map((result) =>
                    result?.fraudReasons?.map((fraudReason, index) => (
                      <div
                        key={index}
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div
                          className="pr-1 pl-1 mr-2"
                          style={{
                            backgroundColor: getRiskBgColor(
                              fraudReason.riskLevel
                            ),
                            color: getRiskFontColor(fraudReason.riskLevel),
                            borderRadius: "20% / 50%",
                            height: "20px",
                          }}
                        >
                          {fraudReason.riskLevel}
                        </div>
                        <div style={{ width: "75%" }}>
                          {fraudReason.message}
                        </div>
                      </div>
                    ))
                  )}
            </div>
          ) : (
            <div>
              {fraudResultsData &&
                fraudResultsData
                  .filter(
                    (result) =>
                      result.category === "email" &&
                      result.scope === "co-applicant"
                  )
                  .map((result) =>
                    result?.fraudReasons?.map((fraudReason, index) => (
                      <div
                        key={index}
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div
                          className="pr-1 pl-1 mr-2"
                          style={{
                            backgroundColor: getRiskBgColor(
                              fraudReason.riskLevel
                            ),
                            color: getRiskFontColor(fraudReason.riskLevel),
                            borderRadius: "20% / 50%",
                            height: "20px",
                          }}
                        >
                          {fraudReason.riskLevel}
                        </div>
                        <div style={{ width: "75%" }}>
                          {fraudReason.message}
                        </div>
                      </div>
                    ))
                  )}
            </div>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  const phonePopover = (
    <Popover id="id-risk">
      <Popover.Content>
        <div>
          <div className="h6">Phone Risk</div>
          <div>This was triggered by the following:</div>
          {applicantType === "applicant" ? (
            <div>
              {fraudResultsData &&
                fraudResultsData
                  .filter(
                    (result) =>
                      result.category === "phone" &&
                      result.scope === "applicant"
                  )
                  .map((result) =>
                    result?.fraudReasons?.map((fraudReason, index) => (
                      <div
                        key={index}
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div
                          className="pr-1 pl-1 mr-2"
                          style={{
                            backgroundColor: getRiskBgColor(
                              fraudReason.riskLevel
                            ),
                            color: getRiskFontColor(fraudReason.riskLevel),
                            borderRadius: "20% / 50%",
                            height: "20px",
                          }}
                        >
                          {fraudReason.riskLevel}
                        </div>
                        <div style={{ width: "75%" }}>
                          {fraudReason.message}
                        </div>
                      </div>
                    ))
                  )}
            </div>
          ) : (
            <div>
              {fraudResultsData &&
                fraudResultsData
                  .filter(
                    (result) =>
                      result.category === "phone" &&
                      result.scope === "co-applicant"
                  )
                  .map((result) =>
                    result?.fraudReasons?.map((fraudReason, index) => (
                      <div
                        key={index}
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div
                          className="pr-1 pl-1 mr-2"
                          style={{
                            backgroundColor: getRiskBgColor(
                              fraudReason.riskLevel
                            ),
                            color: getRiskFontColor(fraudReason.riskLevel),
                            borderRadius: "20% / 50%",
                            height: "20px",
                          }}
                        >
                          {fraudReason.riskLevel}
                        </div>
                        <div style={{ width: "75%" }}>
                          {fraudReason.message}
                        </div>
                      </div>
                    ))
                  )}
            </div>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  const addressPopover = (
    <Popover id="fraud-risk">
      <Popover.Content>
        <div>
          <div className="h6">Address Risk</div>
          <div>This was triggered by the following:</div>
          {applicantType === "applicant" ? (
            <div>
              {fraudResultsData &&
                fraudResultsData
                  .filter(
                    (result) =>
                      result.category === "address" &&
                      result.scope === "applicant"
                  )
                  .map((result) =>
                    result?.fraudReasons?.map((fraudReason, index) => (
                      <div
                        key={index}
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div
                          className="pr-1 pl-1 mr-2"
                          style={{
                            backgroundColor: getRiskBgColor(
                              fraudReason.riskLevel
                            ),
                            color: getRiskFontColor(fraudReason.riskLevel),
                            borderRadius: "20% / 50%",
                            height: "20px",
                          }}
                        >
                          {fraudReason.riskLevel}
                        </div>
                        <div style={{ width: "75%" }}>
                          {fraudReason.message}
                        </div>
                      </div>
                    ))
                  )}
            </div>
          ) : (
            <div>
              {fraudResultsData &&
                fraudResultsData
                  .filter(
                    (result) =>
                      result.category === "address" &&
                      result.scope === "co-applicant"
                  )
                  .map((result) =>
                    result?.fraudReasons?.map((fraudReason, index) => (
                      <div
                        key={index}
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div
                          className="pr-1 pl-1 mr-2"
                          style={{
                            backgroundColor: getRiskBgColor(
                              fraudReason.riskLevel
                            ),
                            color: getRiskFontColor(fraudReason.riskLevel),
                            borderRadius: "20% / 50%",
                            height: "20px",
                          }}
                        >
                          {fraudReason.riskLevel}
                        </div>
                        <div style={{ width: "75%" }}>
                          {fraudReason.message}
                        </div>
                      </div>
                    ))
                  )}
            </div>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="flex row justify-content-between p-3">
      {/* Fraud Risk Card */}
      <div
        className="border bg-light text-dark p-3 mb-3"
        style={{ width: "24%", borderColor: "#ededed", borderRadius: "8px" }}
      >
        <div className="h5">Fraud</div>
        <div
          className="text-uppercase "
          style={{ fontSize: "8pt", color: "#a2b0bc" }}
        >
          risk level
        </div>
        <div className="flex row pl-3">
          <div
            style={{
              color: getRiskLevelColor(
                applicantType === "applicant"
                  ? fraudResultsData?.find(
                      (result) =>
                        result.category === "fraud" &&
                        result.scope === "applicant"
                    )?.riskLevel
                  : fraudResultsData?.find(
                      (result) =>
                        result.category === "fraud" &&
                        result.scope === "co-applicant"
                    )?.riskLevel
              ),
            }}
          >
            {getRiskLevelDisplay(
              applicantType === "applicant"
                ? fraudResultsData?.find(
                    (result) =>
                      result.category === "fraud" &&
                      result.scope === "applicant"
                  )?.riskLevel
                : fraudResultsData?.find(
                    (result) =>
                      result.category === "fraud" &&
                      result.scope === "co-applicant"
                  )?.riskLevel
            )}
          </div>
          {enablePopoverIcons && (
            <OverlayIcon
              show={showEmailPopover}
              onClick={() => setShowEmailPopover(!showEmailPopover)}
              popover={fraudPopover}
            />
          )}
        </div>
        <div
          className="text-uppercase "
          style={{ fontSize: "8pt", color: "#a2b0bc" }}
        >
          risk score
        </div>
        <div
          style={{
            color: getRiskScoreColor(
              applicantType === "applicant"
                ? fraudResultsData?.find(
                    (result) =>
                      result.category === "fraud" &&
                      result.scope === "applicant"
                  )?.riskScore
                : fraudResultsData?.find(
                    (result) =>
                      result.category === "fraud" &&
                      result.scope === "co-applicant"
                  )?.riskScore
            ),
          }}
        >
          {applicantType === "applicant"
            ? fraudResultsData?.find(
                (result) =>
                  result.category === "fraud" && result.scope === "applicant"
              )?.riskScore
            : fraudResultsData?.find(
                (result) =>
                  result.category === "fraud" && result.scope === "co-applicant"
              )?.riskScore}
        </div>
      </div>
      {/* Device Risk Card */}
      <div
        className="border bg-light text-dark p-3 mb-3"
        style={{ width: "24%", borderColor: "#ededed", borderRadius: "8px" }}
      >
        <div className="h5 ">Device</div>
        <div
          className="text-uppercase "
          style={{ fontSize: "8pt", color: "#a2b0bc" }}
        >
          risk level
        </div>
        <div className="flex row pl-3">
          <div
            style={{
              color: getRiskLevelColor(
                fraudResultsData &&
                  fraudResultsData.find(
                    (result) =>
                      result.category === "device" &&
                      result.scope === "application"
                  )?.riskLevel
              ),
            }}
          >
            {getRiskLevelDisplay(
              fraudResultsData &&
                fraudResultsData.find(
                  (result) =>
                    result.category === "device" &&
                    result.scope === "application"
                )?.riskLevel
            )}
          </div>
          {enablePopoverIcons && (
            <OverlayIcon
              show={showPhonePopover}
              onClick={() => setShowPhonePopover(!showPhonePopover)}
              popover={devicePopover}
            />
          )}
        </div>
        <div
          className="text-uppercase "
          style={{ fontSize: "8pt", color: "#a2b0bc" }}
        >
          risk score
        </div>
        <div
          style={{
            color: getRiskScoreColor(
              fraudResultsData &&
                fraudResultsData.find(
                  (result) =>
                    result.category === "device" &&
                    result.scope === "application"
                )?.riskScore
            ),
          }}
        >
          {fraudResultsData &&
            fraudResultsData.find(
              (result) =>
                result.category === "device" && result.scope === "application"
            )?.riskScore}
        </div>
      </div>
      {/* Email Risk Card */}
      <div
        className="border bg-light text-dark p-3 mb-3"
        style={{ width: "24%", borderColor: "#ededed", borderRadius: "8px" }}
      >
        <div className="h5">Email</div>
        <div
          className="text-uppercase "
          style={{ fontSize: "8pt", color: "#a2b0bc" }}
        >
          risk level
        </div>

        <div className="flex row pl-3">
          <div
            style={{
              color: getRiskLevelColor(
                applicantType === "applicant"
                  ? fraudResultsData?.find(
                      (result) =>
                        result.category === "email" &&
                        result.scope === "applicant"
                    )?.riskLevel
                  : fraudResultsData?.find(
                      (result) =>
                        result.category === "email" &&
                        result.scope === "co-applicant"
                    )?.riskLevel
              ),
            }}
          >
            {getRiskLevelDisplay(
              applicantType === "applicant"
                ? fraudResultsData?.find(
                    (result) =>
                      result.category === "email" &&
                      result.scope === "applicant"
                  )?.riskLevel
                : fraudResultsData?.find(
                    (result) =>
                      result.category === "email" &&
                      result.scope === "co-applicant"
                  )?.riskLevel
            )}
          </div>
          {enablePopoverIcons && (
            <OverlayIcon
              show={showAddressPopover}
              onClick={() => setShowAddressPopover(!showAddressPopover)}
              popover={emailPopover}
            />
          )}
        </div>
        <div
          className="text-uppercase "
          style={{ fontSize: "8pt", color: "#a2b0bc" }}
        >
          risk score
        </div>

        <div
          style={{
            color: getRiskScoreColor(
              applicantType === "applicant"
                ? fraudResultsData?.find(
                    (result) =>
                      result.category === "email" &&
                      result.scope === "applicant"
                  )?.riskScore
                : fraudResultsData?.find(
                    (result) =>
                      result.category === "email" &&
                      result.scope === "co-applicant"
                  )?.riskScore
            ),
          }}
        >
          {applicantType === "applicant"
            ? fraudResultsData?.find(
                (result) =>
                  result.category === "email" && result.scope === "applicant"
              )?.riskScore
            : fraudResultsData?.find(
                (result) =>
                  result.category === "email" && result.scope === "co-applicant"
              )?.riskScore}
        </div>
      </div>
      {/* Phone/Address Risk Card */}
      <div
        className="border bg-light text-dark p-3 mb-3"
        style={{ width: "24%", borderColor: "#ededed", borderRadius: "8px" }}
      >
        <div className="h5 mb-0">Phone</div>
        <div className="flex row pl-3 justify-content-between mr-2 align-items-center">
          <div
            className="text-uppercase "
            style={{ fontSize: "8pt", color: "#a2b0bc" }}
          >
            risk level
          </div>
          <div className="flex row pl-3">
            <div
              style={{
                color: getRiskLevelColor(
                  applicantType === "applicant"
                    ? fraudResultsData?.find(
                        (result) =>
                          result.category === "phone" &&
                          result.scope === "applicant"
                      )?.riskLevel
                    : fraudResultsData?.find(
                        (result) =>
                          result.category === "phone" &&
                          result.scope === "co-applicant"
                      )?.riskLevel
                ),
              }}
            >
              {getRiskLevelDisplay(
                applicantType === "applicant"
                  ? fraudResultsData?.find(
                      (result) =>
                        result.category === "phone" &&
                        result.scope === "applicant"
                    )?.riskLevel
                  : fraudResultsData?.find(
                      (result) =>
                        result.category === "phone" &&
                        result.scope === "co-applicant"
                    )?.riskLevel
              )}
            </div>
            {enablePopoverIcons && (
              <OverlayIcon
                show={showIdPopover}
                onClick={() => setShowIdPopover(!showIdPopover)}
                popover={phonePopover}
                placement="left"
              />
            )}
          </div>
        </div>
        <hr className="mt-2 mb-0" style={{ borderTop: "1px solid #ededed" }} />
        <div className="h5 mb-0 pt-2">Address</div>
        <div className="flex row pl-3 justify-content-between mr-2 align-items-center">
          <div
            className="text-uppercase "
            style={{ fontSize: "8pt", color: "#a2b0bc" }}
          >
            risk level
          </div>
          <div className="flex row pl-3">
            <div
              style={{
                color: getRiskLevelColor(
                  applicantType === "applicant"
                    ? fraudResultsData?.find(
                        (result) =>
                          result.category === "address" &&
                          result.scope === "applicant"
                      )?.riskLevel
                    : fraudResultsData?.find(
                        (result) =>
                          result.category === "address" &&
                          result.scope === "co-applicant"
                      )?.riskLevel
                ),
              }}
            >
              {getRiskLevelDisplay(
                applicantType === "applicant"
                  ? fraudResultsData?.find(
                      (result) =>
                        result.category === "address" &&
                        result.scope === "applicant"
                    )?.riskLevel
                  : fraudResultsData?.find(
                      (result) =>
                        result.category === "address" &&
                        result.scope === "co-applicant"
                    )?.riskLevel
              )}
            </div>
            {enablePopoverIcons && (
              <OverlayIcon
                show={showFraudPopover}
                onClick={() => setShowFraudPopover(!showFraudPopover)}
                popover={addressPopover}
                placement="left"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IpqsDisplay;
