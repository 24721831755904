import TagManager from "react-gtm-module";

/**
 * Google Tag Manager Hook
 *
 * Purpose:
 *  - Initialize a GTM Container.
 *  - Pass information to Google Tag Manager using the DataLayer.
 *
 * Reference: https://github.com/alinemorelli/react-gtm
 */
const useGTM = () => {
  return {
    // Adds google tag manager into the DOM using the GTM ID passed in.
    initialize(gtmId: string) {
      return TagManager.initialize({
        gtmId,
      });
    },

    // Push data to Google Tag Manager associated to the GTM event.
    push(dataLayer: Record<string, any>) {
      return TagManager.dataLayer({
        dataLayer,
      });
    },
  };
};

export default useGTM;
