export const getPageArray = (
  currentPage: number,
  totalPages: number,
  middle: number,
  showBefore: number,
  showAfter: number,
  pageLimit: number
) => {
  const pagesRemaining = totalPages - currentPage;
  let start;
  let end;

  if (showBefore > middle) {
    // Ex. (min)0-9
    start = 0;
    end = pageLimit;
  } else if (showAfter > pagesRemaining) {
    // Ex. 89-99(max)
    start = totalPages - pageLimit;
    end = totalPages;
  } else {
    // Ex. 5-14
    start = middle - showBefore;
    end = middle + showAfter;
  }

  const pageArr = [];
  for (let i = start; i < end; i++) {
    pageArr.push(i + 1);
  }

  return pageArr;
};
