import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import api from "../../utils/api";
import { IMG_BASE_URL } from "../../constants";
import {
  MyOrganization,
  OrganizationAPIResponse,
} from "./organizationInterfaces";
import { DecisionStatus } from "../applications/applicationInterfaces";
export interface OrganizationState {
  loading: boolean;
  id?: number;
  slug?: string;
  imgUrl?: string;
  name?: string;
  meta?: {
    sigmaUrl?: string; // This url can be found by opening a dashboard in sigma, clicking the center top title, and generating a public embed code.
    clientFooterLogo?: string;
    clientPrivacyPolicy?: string;
    acquireFraudGuardDemoMode?: boolean;
    gtmId?: string;
    enableDecisioning?: boolean;
    displayOnboardingApplicationResponses?: boolean;
    enableDecisionIntelligence?: boolean;
    settingsBaseUrl?: string;
  };
  decisionStatuses?: string[];
}

const initialState: OrganizationState = {
  loading: false,
};

export const getOrganizationBySlug = createAsyncThunk<MyOrganization, string>(
  "organization/getOrganizationBySlug",
  async (orgSlug: string) => {
    // Get initial organization data
    const {
      data: { logoId, logoVersion, id, slug, name, meta },
    } = await api.get<OrganizationAPIResponse>(`/organizations/${orgSlug}`);

    const result: MyOrganization = {
      slug,
      id,
      imgUrl: `${IMG_BASE_URL}/${logoVersion}/${logoId}`,
      name,
      meta,
    };
    return result;
  }
);

export const getDecisionStatuses = createAsyncThunk<string[], string>(
  "applications/getApplicationDecisionStatuses",
  async (orgSlug: string) => {
    const result = await api.get<Array<DecisionStatus>>(
      `/organizations/${orgSlug}/decision-statuses`
    );
    const statuses = result.data || [];

    return Array.from(new Set(statuses.map((s) => s.title))).sort();
  }
);

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: {
    [getOrganizationBySlug.pending.type]: (state) => {
      state.loading = true;
    },
    [getOrganizationBySlug.fulfilled.type]: (
      state,
      { payload }: PayloadAction<MyOrganization>
    ) => {
      return { ...payload, loading: false };
    },
    [getDecisionStatuses.fulfilled.type]: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.decisionStatuses = action.payload;
    },
  },
});

export const selectOrg = (state: RootState) => state.organization;

export default organizationSlice.reducer;
