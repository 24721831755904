import React, { FC, useState, useMemo } from "react";
import "./fraudGuard.scss";
import { CaretDown, CaretUp, BookFill } from "react-bootstrap-icons";
import { convertCamel } from "../utils/formatting";
import { FinancialApplicationWatchlistReport } from "../models/applications/applicationInterfaces";
import { truncate } from "lodash";

export interface WatchlistItemProps {
  description: NonNullable<
    FinancialApplicationWatchlistReport["hits"]
  >[number]["description"];
  scorePercent: NonNullable<
    FinancialApplicationWatchlistReport["hits"]
  >[number]["scorePercent"];
  data: NonNullable<
    FinancialApplicationWatchlistReport["hits"]
  >[number]["data"];
  className?: string;
}

export const WatchlistItem: FC<WatchlistItemProps> = ({
  description,
  scorePercent,
  data,
  className,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const detailKeys = Object.keys(data);

  const riskLabel = useMemo(() => {
    if (scorePercent > 70 && scorePercent < 85) {
      return "Medium";
    } else if (scorePercent >= 85) {
      return "High";
    }
    return "Low";
  }, [scorePercent]);

  const accentColor = useMemo(() => {
    switch (riskLabel) {
      case "Medium":
        return "#e2832c";
      case "High":
        return "#eb2835";
      default:
        return "#41be1f";
    }
  }, [riskLabel]);

  const bgColor = useMemo(() => {
    switch (riskLabel) {
      case "Low":
        return "#cde6c5";
      case "Medium":
        return "#f4ccad";
      case "High":
        return "#fdd5d6";
      default:
        return "#eceff1";
    }
  }, [riskLabel]);

  // RENDER ---------------------------------
  return (
    <div className={`${className} fg_item`}>
      <div
        className="d-flex"
        onClick={() => setShowDetails(!showDetails)}
        style={{
          cursor: "pointer",
        }}
      >
        {/* Icon with accentColor border */}
        <div
          className="mx-2 d-flex align-items-center justify-content-center"
          style={{
            color: "#f6f6f6",
            backgroundColor: accentColor,
            height: "30px",
            width: "30px",
            padding: "5px",
            borderRadius: "50%",
          }}
        >
          <small>
            {typeof scorePercent === "number" ? (
              scorePercent.toFixed(0)
            ) : (
              <BookFill />
            )}
          </small>
        </div>

        {/* Title and Subtitle */}
        <div className="d-flex flex-column w-100 align-self-center">
          <div className="d-flex ">
            <p className=" mb-0 font-weight-bold" style={{ fontSize: "14px" }}>
              {description}
            </p>
            <div
              className="pr-1 pl-1 ml-3 align-self-center font-weight-bold"
              style={{
                border: `solid 1px ${accentColor}`,
                borderRadius: "20% / 50%",
                backgroundColor: bgColor,
                color: accentColor,
                fontSize: "12px",
              }}
            >
              {/* A "low risk" in our current system actually means that the item is verified and not a risk factor. */}
              <>{scorePercent}%</>
            </div>
          </div>
        </div>
        {/* Expand/Collapse Caret */}
        <div className="align-self-center">
          {/* If there are any risk factors, allow a subpanel to be toggled */}
          {showDetails ? <CaretUp /> : <CaretDown />}
        </div>
      </div>
      {/* Expandable details view */}
      {showDetails && (
        <table
          className="mt-4 mx-4 p-4"
          style={{
            border: "none",
            fontSize: "14px",
          }}
        >
          <tbody>
            {detailKeys.map((key) => {
              return <Detail key={key} dataKey={key} data={data} />;
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

// Collapsable detail row - truncates after 1000 characters
const Detail = ({
  dataKey,
  data,
}: {
  dataKey: string;
  data: NonNullable<
    FinancialApplicationWatchlistReport["hits"]
  >[number]["data"];
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const title = convertCamel(dataKey);
  const value = data[dataKey]?.toString();
  const formattedValue = collapsed ? truncate(value, { length: 1000 }) : value;

  return (
    <tr
      style={{
        borderTop: "none",
        borderRight: "none",
        borderBottom: "1px solid lightgray",
        borderLeft: "none",
      }}
    >
      <td
        className="py-3"
        style={{
          verticalAlign: "top",
          border: "none",
          width: "100px",
        }}
      >
        <strong>{title}</strong>
      </td>
      <td
        className="pl-2 py-3"
        style={{
          lineHeight: "18px",
          border: "none",
        }}
      >
        {formattedValue || <span className="text-muted"> No data </span>}
        {value && value.length > 1000 && (
          <button
            className="mt-4 d-block text-primary bg-transparent border-0 p-0"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? "View Full Entry" : "Collapse Entry"}
          </button>
        )}
      </td>
    </tr>
  );
};
