import { User, UserAPIResponse } from "../users/userInterfaces";
import {
  Organization,
  OrganizationDetail,
} from "../organization/organizationInterfaces";

type metaQuestions = { [key: string]: { question: string; answer: string } };
export interface ApplicationDetail extends Application {
  organization: OrganizationDetail;
  lastSubmission: string | null;
  metadata: metaQuestions;
}

export interface DecisionStatus {
  uuid: string;
  slug: string;
  title: string;
  sortOrder: number;
  decision?: "approved" | "denied" | "review";
  locked: boolean;
}

export interface DecisionRule {
  uuid: string;
  title: string;
  executionOrder: number;
  matched?: boolean;
}

export interface DecisionStatusLog {
  uuid: string;
  user?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  oldDecisionStatus?: DecisionStatus;
  newDecisionStatus?: DecisionStatus;
  availableDecisionRules?: Array<
    DecisionRule & {
      decisionStatus: Pick<
        DecisionStatus,
        "uuid" | "slug" | "title" | "decision"
      >;
    }
  >;
  decisionRule?: DecisionRule;
  createdAt: string;
  comment?: string;
}

export interface Application {
  id: number;
  organization: Organization;
  benevolenceAdmin: {
    id: number;
    firstName: string;
    lastName: string;
  };
  user: User;
  category: string;
  phone: string;
  description: string;
  amountRequested: number;
  amountCampaign: number;
  amountApproved: number;
  amountMatch: number;
  urgentNeed: boolean;
  lastActivity: string;
  lastViewed: string;
  status: string;
  createdAt: string;
  created_at?: string;
  updatedAt: string;
  archived?: boolean;
  decisionStatus: DecisionStatus;
  permissions: string[];
}

export interface SortOptions {
  search?: string;
  status?: string;
  decisionStatus?: string;
  assignedTo?: number;
  offset?: number;
  sort?: string;
  direction?: "asc" | "desc";
  includeArchived?: boolean;
}

export interface NotesSortOptions {
  offset?: number;
  sort?: string;
  direction?: "asc" | "desc";
}
export interface Questions {
  prompt: string;
  required: boolean;
  response: string;
}

export interface Task {
  articles: Article[];
  createdAt: string;
  id: number;
  questions: Questions[];
  status: string;
  title: string;
  updatedAt: string;
  tooltipDescription: string;
  tooltipTitle: string;
  link: string;
  allow_upload: boolean;
  documentRequired?: boolean;
}

export interface Note {
  id?: number;
  subjectType?: string;
  subjectId?: number;
  title?: string;
  body?: string;
  user?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    imageId?: string;
    imageVersion?: string;
    meta?: {
      location?: string;
    };
  };
  createdAt?: string;
  updatedAt?: string;
}

export interface NotesAPIResponse {
  totalRecords: number;
  records: Note[];
}
export interface TasksAPIResponse {
  records: [
    {
      articles: ArticleAPIResponse[];
      createdAt: string;
      id: number;
      tooltipDescription: string;
      tooltipTitle: string;
      metadata: {
        questions: Questions[];
      };
      allow_upload: boolean;
      status: string;
      title: string;
      updatedAt: string;
      link: string;
    }
  ];
}
export interface ApplicationAPIResponse {
  id: number;
  organization: {
    id: number;
    name: string;
    imageVersion: string;
    imageId: string;
  };
  benevolenceAdmin: {
    id: number;
    firstName: string;
    lastName: string;
  };
  metadata: metaQuestions;
  user: UserAPIResponse;
  category: string;
  phone: string;
  lastSubmission: string | null;
  description: string;
  amountRequested: number;
  amountCampaign: number;
  amountApproved: number;
  amountMatch: number;
  urgentNeed: boolean;
  lastActivity: string;
  lastViewed: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  decisionStatus: DecisionStatus;
  permissions: string[];
}

export interface ApplicationsAPIResponse {
  records: ApplicationAPIResponse[];
  totalRecords: number;
}

export enum NotificationType {
  application_received = "application_received",
  new_benevolence_submission_to_applicant = "new_benevolence_submission_to_applicant",
  new_benevolence_submission_to_assigned_benevolence_partner = "new_benevolence_submission_to_assigned_benevolence_partner",
  benevolence_submission_tasks_incomplete = "benevolence_submission_tasks_incomplete",
  benevolence_submission_task_completed_by_applicant = "benevolence_submission_task_completed_by_applicant",
  benevolence_submission_all_tasks_completed_by_applicant = "benevolence_submission_all_tasks_completed_by_applicant",
  benevolence_submission_approved = "benevolence_submission_approved",
  benevolence_submission_denied = "benevolence_submission_denied",
  benevolence_submission_denied_notice_sent = "benevolence_submission_denied_notice_sent",
  benevolence_submission_ready_for_client_review = "benevolence_submission_ready_for_client_review",
  benevolence_submission_ready_for_client_review_notice_sent = "benevolence_submission_ready_for_client_review_notice_sent",

  new_comment_to_benevolence_applicant = "new_comment_to_benevolence_applicant",
  submission_export = "submission_export",
  new_comment_to_assigned_benevolence_partner_admin = "new_comment_to_assigned_benevolence_partner_admin",
  benevolence_submission_task_incomplete_day_3_reminder = "benevolence_submission_task_incomplete_day_3_reminder",
  benevolence_submission_task_incomplete_day_7_reminder = "benevolence_submission_task_incomplete_day_7_reminder",
  benevolence_submission_task_incomplete_day_14_reminder = "benevolence_submission_task_incomplete_day_14_reminder",
  submission_decision_status_updated = "submission_decision_status_updated",
  submission_decision_status_auto_updated = "submission_decision_status_auto_updated",
  submission_archived_status_updated = "submission_archived_status_updated",
  submission_core_banking_processing_failure = "submission_core_banking_processing_failure",
  submission_core_banking_processing_complete = "submission_core_banking_processing_complete",
}
export interface ApplicationActivityAPIResponse {
  records: [
    {
      id: number;
      timestamp: string;
      fromUser?: UserAPIResponse;
      type: string;
      payload: {
        icon: string;
        notification_type: NotificationType;
        submission_payment: {
          amount: number;
        };
        denial_message?: string;
        submission_id: number;
        message?: {
          description: string;
        };
        campaign_information?: [{ description: string; value: string }];
        old_decision_status?: string;
        new_decision_status?: string;
        decision_rule_title?: string;
      };
    }
  ];
}

export interface ActivityItem {
  id: number;
  timestamp: string;
  fromUser?: { id: number; firstName: string; lastName: string };
  type?: string;

  payload: {
    icon: string;
    paymentAmount?: number;
    notificationType: NotificationType;
    message?: string;
    denialMessage?: string;
    campaignInformation?: [{ description: string; value: string }];
    oldDecisionStatus?: string;
    newDecisionStatus?: string;
    newAdmin?: { id: number; firstName?: string; lastName?: string };
    oldAdmin?: { id: number; firstName?: string; lastName?: string };
    decisionRuleTitle?: string;
    archived?: boolean;
  };
}

export interface FinancialApplication {
  id: string;
  openingDeposit?: number | null;
  loanAmount?: number | null;
  desiredMonthlyPayment?: number | null;
  applicant: FinancialPerson;
  coApplicant?: FinancialPerson;
}

export interface FinancialPerson {
  id: string;
  ssnLast4: string;
  birthday?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  email?: string | null;
  emailVerifiedAt: string | null;
  cellPhone?: string | null;
  cellPhoneVerifiedAt: string | null;
  externalId?: string | null;
  contacts?: FinancialContact[];
  addresses?: FinancialAddress[];
  additionalIncomes?: FinancialPersonAdditionalIncome[];
  employers?: FinancialPersonEmployment[];
  rentalExpenses?: FinancialPersonRentalExpense[];
}

export interface FinancialContact {
  id: string;
  value: string;
  verified_at?: string;
  type: "email" | "phone";
  category: "home" | "work" | "mobile" | "primary";
}

export interface FinancialAddress {
  id: string;
  address: string;
  apartment?: string;
  city?: string;
  state?: string;
  zip?: string;
  category: "primary" | "mailing" | "billing";
  from?: string;
  to?: string;
}

export interface FinancialPersonAdditionalIncome {
  id: string;
  source: string;
  incomePeriod: "annual" | "monthly" | "semi_monthly" | "weekly" | "daily";
  income: number;
}

export interface FinancialPersonEmployment {
  id?: string;
  from?: string;
  to?: string;
  employer?: string;
  employmentStatus?:
    | "full_time"
    | "part_time"
    | "self_employed"
    | "unemployed"
    | "retired"
    | "student"
    | "other"
    | null;
  title?: string;
  incomePeriod:
    | "annual"
    | "monthly"
    | "semi_monthly"
    | "weekly"
    | "daily"
    | null;
  income?: number;
}

export interface FinancialPersonRentalExpense {
  id: string;
  monthlyRent?: number;
}

export interface FinancialApplicationFraudResult {
  id: string;
  scope?: string;
  category?: string;
  riskLevel?: string;
  riskScore?: number;
  fraudReasons?: FinancialApplicationFraudReason[];
}

export interface FinancialApplicationFraudReason {
  id: string;
  riskLevel?: string;
  code?: string | null;
  message?: string;
}

export interface Article {
  fileSize: number;
  id: number;
  type: string;
  title: string;
  updatedAt: string;
  url: string;
}

export interface Pdf {
  uuid: string;
  description?: string | null;
  s3Bucket: string;
  s3Key: string;
  createdAt: string;
  updatedAt: string;
}

export interface Screenshot {
  uuid: string;
  slug: string;
  s3Bucket: string;
  s3Key: string;
  createdAt: string;
  updatedAt: string;
}

export interface Message {
  body: string;
  createdAt: string;
  id: number;
  updatedAt: string;
  user: MessageUser;
}

export interface MessageUser {
  firstName: string;
  id: number;
  imageId: string;
  imageVersion: number;
  lastName: string;
}

export interface ArticleAPIResponse {
  fileSize: number;
  id: number;
  type: string;
  title: string;
  updatedAt: string;
  url: string;
}

export interface BusinessEvaluationResponse {
  updatedAt?: string;
  id?: string;
  status?: Status;
  names?: Name[];
  addresses?: Address[];
  tin?: string;
  verifiedTin?: boolean | null;
  formationState?: string;
  formationDate?: string;
  entityType?: string;
  legalBusinessName?: string | null;
  reviewTasks?: reviewTask[];
  people?: Person[];
  watchlistHitCount?: number;
  watchlistLists?: List[];
  watchlistPeople?: WatchlistPerson[];
  registrations?: Registration[];
}

export interface FinancialApplicationWatchlistOrder {
  id: string;
  scope: "application" | "applicant" | "co-applicant";
  subject: string;
  ofac?: FinancialApplicationWatchlistReport;
  pep?: FinancialApplicationWatchlistReport;
  "negative-media"?: FinancialApplicationWatchlistReport;
}

export interface FinancialApplicationWatchlistReport {
  id: string;
  type: "ofac" | "pep" | "negative-media";
  hits?: Array<{
    id: string;
    description: string;
    scorePercent: number;
    data: Record<string, string | string[] | number | null | "N/A">;
  }>;
}

export interface Name {
  name?: string;
  submitted?: boolean;
  sources?: Source[];
}

export interface Address {
  submitted?: boolean;
  fullAddress?: string;
  deliverable?: boolean;
  sources?: Source[];
}

export interface reviewTask {
  category?: string;
  message?: string;
  status?: string;
  label?: string;
  subLabel?: string;
}

export interface Person {
  name?: string;
  titles?: Title[];
  submitted?: boolean;
  sources?: Source[];
}

export interface Title {
  title?: string;
}

export interface List {
  title?: string;
  results?: Result[];
}

export interface Result {
  id?: string;
}

export interface WatchlistPerson {
  name?: string;
  sources?: Source[];
}

export interface Registration {
  state?: string;
  status?: string;
  subStatus?: string;
  fileNumber?: string;
  name?: string;
  juristiction?: string;
  addresses?: string[];
  officers?: Officer[];
  registrationDate?: string;
  entityType?: string;
  sourceLink?: string;
}

export interface Officer {
  name?: string;
}

export interface Source {
  id?: string;
  type?: string;
  metadata?: Record<string, any>;
}

type Status =
  | "open"
  | "pending"
  | "in_audit"
  | "in_review"
  | "approved"
  | "rejected";
