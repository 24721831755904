import { Token } from "./api";
import {REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY} from '../constants'

export function setTokens(token: Token) {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, token.refresh);
    window.localStorage.setItem(AUTH_TOKEN_KEY, token.auth);
}

export function clearTokens() {
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(AUTH_TOKEN_KEY);
}

export function getTokens(): Token | null {
    const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY)
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY)
    if(authToken && refreshToken) {
        return {
            refresh: refreshToken,
            auth:  authToken
        }
    }
    return null;  
}