import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { FraudGuardItem } from "../../components/FraudGuardItem";
import { ShieldFillExclamation } from "react-bootstrap-icons";

interface MessagesProps {
  isAdmin: boolean;
}

interface MessageInputs {
  message: string;
}

export const OFAC: FC<MessagesProps> = ({ isAdmin }) => {
  const dispatch = useDispatch();

  function getTotalRisk(riskLevel: string, object: any) {
    let matchingObjects = [];
    for (const key in object) {
      if (object[key].riskLevel === riskLevel) {
        matchingObjects.push({ [key]: object[key] });
      }
    }
    return matchingObjects.length;
  }

  const renderIndicator = (num: number) => {
    const squares = [];

    for (let i = 0; i < num; i++) {
      squares.push(
        <div
          key={i}
          className="bg-tertiary"
          style={{
            display: "inline-block",
            width: "20px",
            height: "20px",
            marginRight: "5px",
          }}
        ></div>
      );
    }
    return <td style={{ textAlign: "center" }}>{squares}</td>;
  };

  // Guard against users seeing admin view.
  if (!isAdmin) return <p>You don't have permission to view this page.</p>;

  return (
    <div className="documents-container p-5 mb-3">
      {/* Header */}
      <h1 className="text-tertiary h2 mb-4" style={{ fontWeight: 750 }}>
        <ShieldFillExclamation className="mr-2" />
        OFAC & Sanctions Screening
      </h1>
      {/* Risk Report */}
      {Object.entries({
        "Qatar National Counter Terrorism Committee International Sanctions": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Mexico Financial Intelligence Unit UN Sanctions for terrorism and non-proliferation":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "Hong Kong Special Administrative Region Sanctions issued under the UN Sanctions Ordinance":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "United Nations Consolidated": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Belgium Consolidated List of the National and European Sanctions": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Ukraine State Financial Monitoring Black List": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "New Zealand Police Designated Terrorists": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Malta Financial Services Authority (MFSA) National Sanctions": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Switzerland SECO List": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "EU External Action Service - Consolidated list of Sanctions": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "South Korea Financial Services Commission Transaction Ban Designations":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "OFAC SDN List": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Republic of Moldova National Terrorist List": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Philippines AMLC Sanctions Resolutions": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Egypt Anti Money Laundering And Terrorist Financing Unit Security Council Sanctions List":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "DFAT Australia Consolidated Sanctions List": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Japan National Public Service Commission International Terrorists List":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "United Kingdom HM Treasury Office of Financial Sanctions Implementation Consolidated List":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "Turkey Financial Crimes Investigation Board Terrorism Asset Freezes": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "France Tresor Direction Generale Liste Unique de Gels": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Hong Kong Special Administrative Region Sanctions Issued Under The Un Anti Terrorism Measures Ordinance":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "Belarus State Security Agency List of Organizations and Individuals Involved in Terrorist Activities":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "South Korea Ministry of Economy and Finance Transaction Ban Designations":
          {
            riskLevel: "high",
            possibleRiskFactors: [],
          },
        "Monaco Economic Sanctions": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Ministry of Finance Japan Economic Sanctions List": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
        "Iceland Sanctions List": {
          riskLevel: "high",
          possibleRiskFactors: [],
        },
      }).map((item: any) => {
        return (
          <FraudGuardItem
            title={item[0]}
            riskLevel={item[1].riskLevel}
            data={item[1].possibleRiskFactors}
            className="mb-1 rounded-lg"
          />
        );
      })}
    </div>
  );
};
