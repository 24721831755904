// *************************************************
// * INTERCEPTORS
// * This file runs mid-flight logic on all API calls made.
// *************************************************

import api, { getCurrentTokens } from "./api";
import { loginByRefreshToken, logout } from "../models/users/myUserSlice";
import { getDispatch } from "../app/store";
let inFlightRefreshRequest: Promise<any> | null = null;

api.interceptors.response.use(
  // Config represents the response from the api call. If there weren't any errors, do nothing.
  (config) => {
    return config;
  },
  // If there is an authorization error from an expired JWT, refresh it.
  async (error) => {
    if (error.response.status === 401) {
      // If the route called is "/auth/tokens/verify", we want to break out of the interceptor so that it doesn't get stuck in a loop.
      // This will only happen if the user's token is expired.
      const routeCalled = error.response.config.url;
      if (
        routeCalled === "/auth/tokens/verify" ||
        routeCalled === "/auth/reset-password"
      ) {
        // This rejection will trigger a new magic link to be generated in myUserSlice.tsx.
        return Promise.reject(error);
      }
      // ...Otherwise, try to refresh the JWT using logic in the login method.
      const dispatch = getDispatch();
      const { refreshToken } = getCurrentTokens();
      // Using inFlightRefreshRequest prevents an infinite loop.
      inFlightRefreshRequest =
        (inFlightRefreshRequest as Promise<any>) ||
        dispatch(loginByRefreshToken({ refreshToken }));
      const res = await inFlightRefreshRequest;
      inFlightRefreshRequest = null;
      // If the token couldn't be refreshed, return an error and log out.
      if (res.error) {
        dispatch(logout());
        return Promise.reject(error);
      }
      // Destructure result of getCurrentTokens(), saved to authToken.
      const { authToken } = getCurrentTokens();
      // Return the API call with the refreshed token as a header.
      error.response.config.headers["Authorization"] = `Bearer ${authToken}`;
      return api(error.response.config);
    }
    return Promise.reject(error);
  }
);
